import React, { useReducer } from 'react';
import { Grid, Typography, InputLabel, RadioGroup, FormControlLabel, Radio, Checkbox, FormHelperText } from '@material-ui/core';
import { reducer, initState } from '../../../Reducer/action';
import { SecondaryButton, TextBox, YesNoButton, SlideToggle } from '../../../../Core/FormInput/index';
import { useState, useCallback } from 'react';
import CommonGijgoGrid from '../../../../Core/Controls/GijgoGrid/index';
import moment from 'moment';
import SelectBox from './../../../../Core/FormInput/SelectBox';
import DataGridComp from '../../../../Core/Grid';
import { GridOverlay } from '@material-ui/data-grid';
import { getStatusDropdown, postVehicleStatus, getVehicleStatusID } from '../../../../Core/Service/communicator-service';
import { useEffect } from 'react';
import { useContext } from 'react';
import AppContext from '../../../../App/AppContext';
import VehicleStatusStepper from '../VehicleStatusStepper/vehicleStatusStepper';

const AddEditSiteVehicle = (props) => {
    const { showToast, showModal, userRoles } = useContext(AppContext);
    const isCustomer = userRoles.includes('customer');
    let isReadOnly = isCustomer;
    const { onClose } = props;
    const [state, setState] = useState({
        errors: {},
        partStatusList: [],
        vehicleStatusList: [],
        otherVehicleStatusList: [],
        partStatus: '',
        vehicleStatus: '',
        highPriority: false,
        stepperNumber: 0,
        stepperData: {}
    });

    const pullData = async () => {
        let getByIdRes = await getVehicleStatusID(props.headerRecordID);
        let stepperData = getByIdRes.data?.jobProgressDate ? getByIdRes.data?.jobProgressDate[0] : {};
        let data = getByIdRes.data.list[0];
        if (getByIdRes.success) {
            let CheckDate = data?.cancelledDate || data?.dateCollected;
            setState((st) => ({
                ...st,
                headerRecordID: data?.headerRecordID,
                internalNotes: data?.internalNotes,
                partETA: data?.partETA,
                partStatus: data?.partStatus,
                vehicleDeliveryDate: data?.vehicleDeliveryDate,
                vehicleStatus: data?.vehicleStatus,
                workDetails: data?.workDetails,
                highPriority: data?.highPriority,
                stepperNumber: data?.stepperNumber,
                currentStepperNumber: data?.stepperNumber - 1,
                statusDesc: data?.status,
                bookedDate: data?.bookedDate,
                cancelledDate: data?.cancelledDate,
                dateCollected: data?.dateCollected,
                stepperData: stepperData,
                isReadOnly: isReadOnly || CheckDate ? true : false
            }));
        }
    };

    useEffect(async () => {
        pullData();
    }, []);

    const stepperModalOnClose = (res) => {
        res && pullData();
        res && props.onClose(true);
    };

    useEffect(async () => {
        if (state.vehicleStatus) {
            let res = await getStatusDropdown(state.vehicleStatus);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    partStatusList: res?.data?.partStatus,
                    vehicleStatusList: res?.data?.vehicleStatus,
                    otherVehicleStatusList: res?.data?.vehicleStatus
                        .map((q) => {
                            if (q.smsText) {
                                q.smsText = q.smsText.replace('{reg}', `${props.reg}`);
                            }

                            return { ...q };
                        })
                        .filter((p) => p.id != state.vehicleStatus)
                }));
            }
        }
    }, [state.vehicleStatus]);

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            console.log(name, value, type, checked);
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    const validation = () => {
        let { workDetails, vehicleStatus, partStatus } = state;
        let isValid = true;
        let errors = {};

        if (!workDetails) {
            isValid = false;
            errors.workDetails = 'Work Details is required';
        }
        // if (!vehicleStatus) {
        //     isValid = false;
        //     errors.vehicleStatus = 'Vehicle Status is required';
        // }
        // if (!partStatus) {
        //     isValid = false;
        //     errors.partStatus = 'Part Status is required';
        // }
        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let data = {
                headerRecordID: props.headerRecordID,
                workDetails: state.workDetails,
                // internalNotes: state.internalNotes,
                // vehicleStatus: state.vehicleStatus,
                // vehicleDeliveryDate: state.vehicleDeliveryDate,
                // partStatus: state.partStatus,
                // partETA: state.partETA,
                highPriority: state.highPriority
            };
            let res = await postVehicleStatus(data);
            if (res.success) {
                showToast(`Vehicle Status Updated Successfully`);
                props.onClose(true);
            } else {
                showToast(`Vehicle Status Failed To Update`);
            }
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Grid item style={{ fontSize: 15, textAlign: 'left' }}>
                            WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>High Priority</Grid>
                                <Grid item>
                                    <SlideToggle name="highPriority" checked={state.highPriority} onChange={handleInput} disabled={state.isReadOnly} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <VehicleStatusStepper
                        currentStatus={state.currentStepperNumber}
                        vehicleStatusList={state.otherVehicleStatusList}
                        headerRecordID={props.headerRecordID || state.headerRecordID}
                        onClose={stepperModalOnClose}
                        stepperData={state.stepperData}
                        partStatusList={state.partStatusList}
                        statusDesc={state.statusDesc}
                        bookedDate={state.bookedDate}
                        cancelledDate={state.cancelledDate}
                        dateCollected={state.dateCollected}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel shrink error>
                        Work Details
                    </InputLabel>
                    <TextBox
                        placeholder=" Work Details"
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        variant="outlined"
                        name="workDetails"
                        value={state.workDetails}
                        onChange={handleInput}
                        disabled={state.isReadOnly}
                    />
                    <FormHelperText error>{state.errors.workDetails}</FormHelperText>
                </Grid>
                {/* <Grid item xs={6}>
                    <InputLabel shrink> Internal Notes</InputLabel>
                    <TextBox
                        placeholder="Internal Notes"
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        variant="outlined"
                        name="internalNotes"
                        value={state.internalNotes}
                        onChange={handleInput}
                        disabled={isReadOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel shrink error style={{ paddingBottom: 10 }}>
                        {' '}
                        Vehicle Status
                    </InputLabel>
                    <SelectBox List={state.vehicleStatusList} name="vehicleStatus" value={+state.vehicleStatus} onChange={handleInput} disabled={isReadOnly} />
                    <FormHelperText error>{state.errors.vehicleStatus}</FormHelperText>
                </Grid>

                <Grid item xs={6}>
                    <InputLabel shrink> Vehicle Delivery Date</InputLabel>
                    <TextBox
                        type="datetime-local"
                        name="vehicleDeliveryDate"
                        value={state.vehicleDeliveryDate}
                        variant="outlined"
                        fullWidth
                        onChange={handleInput}
                        disabled={isReadOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel shrink style={{ paddingBottom: 10 }}>
                        {' '}
                        Part Status
                    </InputLabel>
                    <SelectBox List={state.partStatusList} name="partStatus" value={+state.partStatus} onChange={handleInput} disabled={isReadOnly} />
                    
                </Grid>
                <Grid item xs={6}>
                    <InputLabel shrink> Part ETA</InputLabel>
                    <TextBox
                        type="datetime-local"
                        name="partETA"
                        value={state.partETA}
                        variant="outlined"
                        fullWidth
                        onChange={handleInput}
                        disabled={isReadOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel shrink>Phone</InputLabel>
                    <TextBox placeholder="Send Message by Phone" type="tel" variant="outlined" fullWidth onChange={handleInput} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel shrink> Message </InputLabel>
                    <TextBox type="text" placeholder="Message" variant="outlined" fullWidth onChange={handleInput} />
                </Grid>

                 */}
                <Grid item xs={6}>
                    <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmit} disabled={state.isReadOnly}>
                        Submit
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton className="Submitbtn" fullWidth onClick={() => onClose(false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddEditSiteVehicle;
