import { FormLabel, Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import AppContext from '../../../../App/AppContext';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { SecondaryButton, SelectBox, TextBox } from '../../../../Core/FormInput';
import { getBranchParametersById, getBranchParametersScreenDropdown, postBranchParameters } from '../../../../Core/Service/communicator-service';
import TableChartIcon from '@material-ui/icons/TableChart';
import { AppStorage } from '../../../../Core/Service/storage-service';

const crumbs = () => [
    { name: 'Home', path: '/' },
    { name: 'Communicator', active: true },
    { name: 'Branch Parameters', active: true }
];

const BranchParametersScreen = (props) => {
    const portalSetting = AppStorage.getPortalSetting();
    const { hideModal, showToast } = useContext(AppContext);

    const [state, setState] = useState({
        branchID: '',
        overdueEmail: '',
        partStatusEmail: '',
        workshopDiaryEmail: '',
        cashCustomerMessage: '',
        feedbackEmailAddress: '',
        technicalAssistanceEmail: '',
        branchWorkshopDiaryEmail: '',
        branchJobApprovedEmail: '',
        collectionStatusEmail: '',
        branchList: []
    });

    const pullBranchDropDown = async () => {
        let res = await getBranchParametersScreenDropdown();
        setState((st) => ({ ...st, branchList: res.data.list.table }));
    };

    const pullBranchById = async () => {
        let res = await getBranchParametersById(state.branchID);
        let data = res.data.list.table[0];
        setState((st) => ({
            ...st,
            overdueEmail: data.overdueEmail || '',
            partStatusEmail: data.partStatusEmail || '',
            workshopDiaryEmail: data.urgentActionEmailAddress || '',
            cashCustomerMessage: data.cashCustomerMessage || '',
            feedbackEmailAddress: data.feedbackEmailAddress || '',
            technicalAssistanceEmail: data.technicalAssistanceEmail || '',
            branchWorkshopDiaryEmail: data.workshopDiaryEmail || '',
            branchJobApprovedEmail: '',
            collectionStatusEmail: data.collectionStatusEmail || ''
        }));
    };

    useEffect(() => {
        pullBranchDropDown();
    }, []);

    useEffect(() => {
        if (state.branchID) {
            pullBranchById();
        } else {
            setState((st) => ({
                ...st,
                overdueEmail: '',
                partStatusEmail: '',
                workshopDiaryEmail: '',
                cashCustomerMessage: '',
                feedbackEmailAddress: '',
                technicalAssistanceEmail: '',
                branchWorkshopDiaryEmail: '',
                branchJobApprovedEmail: '',
                collectionStatusEmail: ''
            }));
        }
    }, [state.branchID]);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleSubmit = async () => {
        let data = {
            branchID: state.branchID,
            feedbackEmailAddress: state.feedbackEmailAddress,
            overdueEmail: state.overdueEmail,
            technicalAssistanceEmail: state.technicalAssistanceEmail,
            workshopDiaryEmail: state.branchWorkshopDiaryEmail,
            urgentActionEmailAddress: state.workshopDiaryEmail,
            partStatusEmail: state.partStatusEmail,
            collectionStatusEmail: state.collectionStatusEmail,
            cashCustomerMessage: state.cashCustomerMessage
        };
        console.log(data);
        let res = await postBranchParameters(data);
        if (!res.success) {
            console.error(res);
            showToast(res.message);
        } else {
            showToast('Branch Parameters save successfully');
            pullBranchById();
        }
    };

    return (
        <div>
            <BreadCrumbs crumbs={crumbs()} />
            <Grid container style={{ padding: '0px 10px' }}>
                <Grid item xs={12}>
                    <Grid container style={{ border: '1px solid lightgrey', paddingBottom: 20 }}>
                        <Grid item xs={12}>
                            <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                style={{
                                    background: portalSetting.portalHeaderBackgroundColour,
                                    color: portalSetting.portalHeaderTextColour,
                                    height: '60px',
                                    padding: '0px 10px'
                                }}
                            >
                                <Grid item>
                                    {/* <span>
                                        <TableChartIcon />
                                    </span> */}
                                    <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Branch Parameters</span>
                                </Grid>
                                <Grid item>
                                    <SecondaryButton onClick={handleSubmit}>Update</SecondaryButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" spacing={3}>
                                        <Grid item xs={6}>
                                            <FormLabel style={{ paddingBottom: 10 }}>Branch Parameters</FormLabel>
                                            <SelectBox onChange={handleFieldChange} value={state.branchID} name="branchID" List={state.branchList || []} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sm={12} md={6} spacing={1}>
                                    <Grid item xs={12}>
                                        <FormLabel>Overdue Email</FormLabel>
                                        <TextBox value={state.overdueEmail} placeholder="Overdue Email" onChange={handleFieldChange} name="overdueEmail" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>Part Status Email</FormLabel>
                                        <TextBox
                                            value={state.partStatusEmail}
                                            placeholder="Overdue EmailPart Status Email"
                                            onChange={handleFieldChange}
                                            name="partStatusEmail"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>Urgent Action Email</FormLabel>
                                        <TextBox
                                            value={state.workshopDiaryEmail}
                                            placeholder="Urgent Action Email"
                                            onChange={handleFieldChange}
                                            name="workshopDiaryEmail"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>Cash Customer Message</FormLabel>
                                        <TextBox
                                            value={state.cashCustomerMessage}
                                            placeholder="Cash Customer Message"
                                            onChange={handleFieldChange}
                                            name="cashCustomerMessage"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sm={12} md={6} spacing={1}>
                                    <Grid item xs={12}>
                                        <FormLabel>Feedback Email</FormLabel>
                                        <TextBox
                                            value={state.feedbackEmailAddress}
                                            placeholder="Feedback Email"
                                            onChange={handleFieldChange}
                                            name="feedbackEmailAddress"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>Technical Assistant Email</FormLabel>
                                        <TextBox
                                            value={state.technicalAssistanceEmail}
                                            placeholder="Technical Assistant Email"
                                            onChange={handleFieldChange}
                                            name="technicalAssistanceEmail"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>Workshop Diary Email</FormLabel>
                                        <TextBox
                                            value={state.branchWorkshopDiaryEmail}
                                            placeholder="Workshop Diary Email"
                                            onChange={handleFieldChange}
                                            name="branchWorkshopDiaryEmail"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel>Collection Status Email</FormLabel>
                                        <TextBox
                                            value={state.collectionStatusEmail}
                                            placeholder="Collection Status Email"
                                            onChange={handleFieldChange}
                                            name="collectionStatusEmail"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default BranchParametersScreen;
