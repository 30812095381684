import { get, post, put } from './http-calls';

export const getPartReqs = async (jobId, technicianJobId) => {
    let res = '';
    if (technicianJobId) {
        res = await get(`PartReqs/${jobId}?TechnicianJobId=${technicianJobId}`, { useAuthToken: true });
    } else {
        res = await get(`PartReqs/${jobId}`, { useAuthToken: true });
    }
    return res;
};
export const addPartReq = async (jobId, reqParts, technicianJobId) => {
    let res = await post(`PartReqs/${jobId}?TechnicianJobId=${technicianJobId}`, reqParts, { useAuthToken: true });
    return res;
};

export const approvePartReq = async (jobId) => {
    let res = await post(`PartReqs/Approve/${jobId}`, {}, { useAuthToken: true });
    return res;
};

export const issuePartsQty = async (jobId, data, technicianJobId) => {
    let res = await post(`PartReqs/IssueParts/${jobId}?TechnicianJobId=${technicianJobId}`, data, { useAuthToken: true });
    return res;
};

export const getPartReqStatus = async () => {
    let res = await get(`PartReqs/PartReqsStatus`, { useAuthToken: true });
    return res;
};

export const getPhotosByPartReqID = async (id) => {
    let res = await get(`PartReqs/PartReqPhotos/${id}`, { useAuthToken: true });
    return res;
};

// export const postPhotosByPartReqID = async (data) => {
//     let res = await post(`PartReqs/Photo/PartReqPhotos`, data, { useAuthToken: true });
//     return res;
// };

export const PartReqPhotoDeleteByPhotoId = async (id) => {
    let res = await post(`PartReqs/PartReqPhotoDelete/${id}`, { id: id }, { useAuthToken: true });
    return res;
};

export const postPhotosByPartReqID = async (rows) => {
    const prom = [];
    rows.forEach((m) => {
        if ((m?.partReqId || m.id) && m?.Photos?.length > 0) {
            prom.push(
                post(
                    `PartReqs/Photo/PartReqPhotos`,
                    {
                        partReqPhotoID: null,
                        PartReqPhotoPartReqID: m?.partReqId || m.id,
                        PartReqPhotoFile: m.Photos.map((k) => k.partReqPhotoFile)
                    },
                    {
                        useAuthToken: true
                    }
                )
            );
        }
    });
    return Promise.all(prom)
        .then((ress) => {
            console.log('response from all photos post calls:', ress);
            return {
                success: true,
                message: 'Photos saved successfully.'
            };
        })
        .catch((ex) => {
            console.error(ex);
            return {
                success: false,
                message: 'Failed to upload photos.  Please try again.'
            };
        });
};
