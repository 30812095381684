import { get, post } from './http-calls';

export const getQCSheet = async (id) => {
    let res = await get(`QCSheet/${id}`, { useAuthToken: true });
    return res;
};
export const addQCSheet = async (id, note, sign, mileage, headerID, results) => {
    let res = await post(
        `QCSheet`,
        {
            HeaderRecordID: id,
            QCHeaderNotes: note,
            QCHeaderSignature: sign,
            QCHeaderMileage: mileage,
            QCHeaderID: headerID,
            qCResults: results
        },
        { useAuthToken: true }
    );
    return res;
};
