// import React from 'react';
import React, { useEffect, useState } from 'react';
import { FormLabel, Grid, IconButton, MenuItem, Typography } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { TextBox } from '../../../Core/FormInput';
import CommonGijgoGrid from '../../../Core/Controls/GijgoGrid/index';
import { getqcJobs } from '../../../Core/Service/qc_jobs-service';
import moment from 'moment';
import formatters from './../../../Core/Grid/inputFormatter';
import ReactDOM from 'react-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useMemo } from 'react';
import DialogComp from '../../../Core/Modal/dialogModal';
import QualityControlSheet from '../workflow-job/QualityControlSheet';
import AirplayIcon from '@material-ui/icons/Airplay';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        border: '2px solid #fbfbfb',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px', // margin: 3,
        borderRadius: 7,
        background: '#fbfbfb'
        // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px'
    }
}));

const baseColumns = [
    { field: 'action', sortable: true, title: `Action`, width: 70 },
    { field: 'w', sortable: true, title: `WIP No`, width: 80 },
    { field: 'r', sortable: true, title: `Registration`, width: 100 },
    { field: 'm', sortable: true, title: `Make/Model`, width: 200 },
    { field: 'st', sortable: true, title: `Job Description`, width: 150 },
    // { field: 'f', sortable: true, title: `Franchise`, width: 200 },
    {
        field: 'di',
        sortable: true,
        title: `Date Due In`,
        width: 150,
        renderer: function (value, record) {
            if (record.di != null) {
                return moment(record.di).format('DD-MMM-YYYY');
            }
            return '-';
        }
    },
    {
        field: 'dout',
        sortable: true,
        title: `Date Due Out`,
        width: 150,
        renderer: function (value, record) {
            if (record.dout != null) {
                return moment(record.dout).format('DD-MMM-YYYY');
            }
            return '-';
        }
    },
    { field: 'c', sortable: true, title: `Customer`, width: 120 },
    { field: 'cb', sortable: true, title: 'Created By', width: 150 },
    {
        title: 'Branch',
        field: 'b',
        width: 90
    },
    { field: 'oo', sortable: true, title: `Owning Operator`, width: 150 },
    { field: 'wn', sortable: true, title: `Workflow`, width: 150 }
];

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');

const QCJobsDashboard = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        qcList: [],
        qcBranch: [],
        finalQcVal: [],
        franchiseList: [],
        startDate: startDate,
        endDate: endDate,
        branch: 'allBranch'
    });
    useEffect(async () => {
        let res = await getqcJobs(state.startDate, state.endDate);
        let qcList = [...res.data.list];
        let qcBranch = [...res.data.branch];

        setState((st) => ({
            ...st,
            qcList: qcList,
            qcBranch: qcBranch
        }));
    }, [state.startDate, state.endDate, state.branch]);

    const handleSelect = (e) => {
        setState((st) => ({ ...st, selecedBranch: e.target.value }));
    };

    useEffect(() => {
        let finalQcVal = [...state.qcList].reduce((a, b) => {
            let index = a.find((m) => m.branch === b.branch);
            if (index) {
                index.qcDone += b.qcDone;
                index.totalJobs += b.totalJobs;
                return a;
            }
            a.push({ ...b });
            return a;
        }, []);

        setState((m) => ({ ...m, finalQcVal: finalQcVal }));
    }, [state.qcList, state.startDate, state.endDate, state.branch]);

    useEffect(() => {
        let franchiseList = [...state.qcList].reduce((a, b) => {
            let index = a.find((m) => m.franchise === b.franchise);
            if (index) {
                index.qcDone += b.qcDone;
                index.totalJobs += b.totalJobs;
                return a;
            }
            a.push({ ...b });
            return a;
        }, []);

        setState((m) => ({ ...m, franchiseList: franchiseList }));
    }, [state.qcList]);

    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleBtnClick = (val) => {
        props.history.push(`/Job/${val.hrid}/${val.wid}`);
    };
    const handleQualityControlSheetClick = (val) => {
        setState((st) => ({ ...st, showQualityControlSheet: true, jobID: val.hrid }));
    };
    const closeQualityControlSheet = (val) => {
        setState((st) => ({ ...st, showQualityControlSheet: false, jobID: '' }));
    };

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="space-around">
                <Grid>
                    <IconButton size="small" onClick={() => handleBtnClick(val)}>
                        <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                </Grid>
                <Grid>
                    <IconButton size="small" onClick={() => handleQualityControlSheetClick(val)}>
                        <AirplayIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);

    let baseUrl = `QCSheet/GetQCJobs?startDate=${state.startDate}&endDate=${state.endDate}${
        state.branch !== 'allBranch' && state.branch ? `&b=${state.branch}` : ''
    }`;

    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={1}>
                <Grid item container spacing={1} xs={12}>
                    {' '}
                    <Grid item xs={12} md={4} lg={4}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">Branch</FormLabel>
                            <TextBox select name="branch" value={state.branch} variant="outlined" onChange={fieldChange}>
                                <MenuItem key={'all'} value={'allBranch'}>
                                    All Branches
                                </MenuItem>
                                {state.qcBranch.map((option, i) => (
                                    <MenuItem key={i} value={option.branch}>
                                        {option.branch}
                                    </MenuItem>
                                ))}
                            </TextBox>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={6}>
                    {/* <Card className={classes.root}> */}
                    <Grid container justifyContent="flex-start" alignContent="flex-start" className={classes.root}>
                        <Grid item xs={12}>
                            <Typography vraient="h5" color="secondary" className="dashHeading">
                                By Branch
                            </Typography>
                        </Grid>

                        {state.finalQcVal.map((m) => {
                            let val = (m.qcDone / m.totalJobs) * 100;
                            let fix = val.toFixed(2);
                            return (
                                <Grid item style={{ padding: '10px 10px' }}>
                                    <div
                                        style={{
                                            borderLeft: '5px solid #00F433',
                                            padding: '5px 10px 0px 10px',
                                            width: '100%',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingBottom: 10,
                                                textAlign: 'left'
                                            }}
                                        >
                                            <span style={{ fontSize: 13, fontWeight: 550, marginBottom: 10, fontFamily: `"Public Sans", sans-serif` }}>
                                                {' '}
                                                {m.branch}
                                            </span>
                                            <div>
                                                <span style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>{m.qcDone}</span>/
                                                {m.totalJobs}&nbsp; <b>({fix}%)</b>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                    {/* </Card> */}
                </Grid>
                <Grid item container xs={6}>
                    {/* <Card className={classes.root}> */}
                    <Grid container justifyContent="flex-start" alignContent="flex-start" className={classes.root}>
                        <Grid item xs={12}>
                            <Typography vraient="h5" color="secondary" className="dashHeading">
                                By Franchise
                            </Typography>
                        </Grid>
                        {state.franchiseList.map((m) => {
                            let val = (m.qcDone / m.totalJobs) * 100;
                            let fix = val.toFixed(2);
                            return (
                                <Grid item style={{ padding: '10px 10px' }}>
                                    <div
                                        style={{
                                            borderLeft: '5px solid #00F433',
                                            padding: '5px 10px 0px 10px',
                                            width: '100%',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingBottom: 10,
                                                textAlign: 'left'
                                            }}
                                        >
                                            <span style={{ fontSize: 13, fontWeight: 550, marginBottom: 10, fontFamily: `"Public Sans", sans-serif` }}>
                                                {' '}
                                                {m.franchise}
                                            </span>
                                            <div>
                                                <span style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>{m.qcDone}</span>/
                                                {m.totalJobs} <b>({fix}%)</b>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                    {/* </Card> */}
                </Grid>
                <Grid item container xs={12}>
                    <CommonGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={column}
                        getUrl={baseUrl}
                        displayPagination={true}
                        isReload={state.isReload}
                        downloadBtnName="QC_Jobs"
                    />
                </Grid>

                {state.showQualityControlSheet ? (
                    <DialogComp title="Assigned Technician" maxWidth="xl" onClose={() => closeQualityControlSheet(false)} fullWidth overflow={'auto'}>
                        <QualityControlSheet jobID={state.jobID} />
                    </DialogComp>
                ) : null}
            </Grid>
        </div>
    );
};

export default QCJobsDashboard;
