import { useEffect } from 'react';
import { useState } from 'react';
import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../../../Core/FormInput';
import { postJobProgressSetReBookingStatus } from '../../../../../../Core/Service/communicator-service';
import { useContext } from 'react';
import AppContext from '../../../../../../App/AppContext';
import { singleEmailValidation } from '../../../../../../Core/Service/StaticService';

const RebookingRequiredModal = (props) => {
    const { showToast } = useContext(AppContext);

    const [state, setState] = useState({
        messaegList: [],
        actionLog: false,
        editActionLog: false,
        selectedStatus: props.selectedStatus,
        logText: '',
        errors: {}
    });

    useEffect(async () => {
        setState((st) => ({ ...st, logText: props.smsText, isCustomerUpdate: props.sendSMSUpdate, isUpdateSms: props.sendSMSUpdate }));
    }, [props]);

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        if (type == 'checkbox') {
            setState((st) => {
                let newSt = { ...st };
                newSt[name] = checked;
                if (!newSt.isCustomerUpdate) {
                    newSt.isCustomerEmail = '';
                }
                if (!newSt.isUpdateSms) {
                    newSt.isPhoneNo = '';
                }
                return newSt;
            });
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };

    const validation = () => {
        let { partStatus, rebookingDate, isCustomerUpdate, isCustomerEmail, isUpdateSms, isPhoneNo } = state;
        let isValid = true;
        let errors = {};

        if (!rebookingDate) {
            isValid = false;
            errors.rebookingDate = 'Please Add Rebooking Required Date';
        }
        if (isCustomerUpdate && !singleEmailValidation(isCustomerEmail)) {
            isValid = false;
            errors.isCustomerEmail = 'Please Add Customer Email';
        }
        if (isUpdateSms && !isPhoneNo) {
            isValid = false;
            errors.isPhoneNo = 'Please Add Customer Email';
        }

        setState((st) => ({ ...st, errors: errors }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let data = {
                headerRecordID: props.headerRecordID,
                rebookingDate: state.rebookingDate,
                status: +state.selectedStatus,
                logText: state.logText,
                isPhoneNo: state.isPhoneNo,
                isCustomerEmail: state.isCustomerEmail
            };
            let res = await postJobProgressSetReBookingStatus(data);
            if (res.success) {
                showToast(`Rebooking Required Status Added Successfully`);
                props.onClose(true);
            } else {
                showToast(`Rebooking Required Status Failed To Add`);
            }
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <InputLabel shrink required className="mandatoryfields">
                        Rebooking Date
                    </InputLabel>
                    <TextBox type="datetime-local" name="rebookingDate" value={state.rebookingDate} variant="outlined" fullWidth onChange={handleInput} />
                    <FormHelperText error>{state.errors.rebookingDate}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="secondary">Customer Message</Typography>
                    <TextBox placeholder="Message" type="text" name="logText" variant="outlined" fullWidth value={state.logText} onChange={handleInput} />
                    <FormHelperText error>{state.errors.logText}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={8}>
                    <InputLabel shrink>
                        <input type="checkbox" style={{ cursor: 'pointer' }} name="isCustomerUpdate" onChange={handleInput} checked={state.isCustomerUpdate} />
                        Customer Email
                    </InputLabel>
                    <TextBox
                        placeholder="Email update to customer"
                        type="email"
                        name="isCustomerEmail"
                        variant="outlined"
                        fullWidth
                        value={state.isCustomerEmail}
                        onChange={handleInput}
                        error={state.isCustomerUpdate}
                    />
                    <FormHelperText error>{state.errors.isCustomerEmail}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel shrink>
                        <input type="checkbox" style={{ cursor: 'pointer' }} onChange={handleInput} name="isUpdateSms" checked={state.isUpdateSms} />
                        Phone Number
                    </InputLabel>
                    <TextBox
                        placeholder="Phone Number"
                        type="tel"
                        name="isPhoneNo"
                        variant="outlined"
                        fullWidth
                        value={state.isPhoneNo}
                        onChange={handleInput}
                        error={state.isUpdateSms}
                    />
                    <FormHelperText error>{state.errors.isPhoneNo}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmit}>
                        Rebooking Required
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default RebookingRequiredModal;
