import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Typography,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { TextBox } from '../../../Core/FormInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DescriptionIcon } from '@material-ui/icons/Description';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SecondaryBatteryModal from './../../technician/BatteryTest/SecondaryBatteryModal';
import { SecondaryButton } from './../../../Core/FormInput/AppButton';
import moment, { relativeTimeRounding } from 'moment';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.hover
        //     color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
});

const PartDialog = (props) => {
    const { part, setPart, work, setWork, control, setControl } = props;

    const classes = useStyles();

    const addWorkRow = () => {
        setWork((st) => [
            ...st,
            {
                service24WorkID: null,
                service24WorkRequested: '',
                service24WorkReason: '',
                service24WorkAction: '',
                service24WorkTimeTaken: null
            }
        ]);
    };

    const removeWorkAt = (indx) => {
        setWork((st) => {
            const newSt = [...st];
            newSt.splice(indx, 1);
            return newSt;
        });
    };

    const addPartsRow = () => {
        setPart((st) => [
            ...st,
            {
                service24PartID: null,
                service24PartNumber: '',
                service24PartDescription: '',
                service24PartQuantity: 0,
                service24PartPrice: null
            }
        ]);
    };

    const removePartAt = (indx) => {
        setPart((st) => {
            const newSt = [...st];
            newSt.splice(indx, 1);
            return newSt;
        });
    };

    const handleInput = (e) => {
        let { name, value } = e.target;
        setControl((st) => ({ ...st, [name]: value }));
    };

    const handlepart = (index) => (e) => {
        let { name, value } = e.target;
        setPart((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex[name] = value;
            }
            return newSt;
        });
    };

    const handlwork = (index) => (e) => {
        let { name, value } = e.target;
        setWork((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex[name] = value;
            }
            return newSt;
        });
    };

    const totalRepair = moment(control.service24RepairEndTime).diff(control.service24RepairStartTime, 'minute');
    let repairHours = Math.floor(totalRepair / 60);
    let repairMinutes = totalRepair % 60;
    const TotalRepairTime = control.service24RepairEndTime && totalRepair > 0 ? `${repairHours} hours ${repairMinutes} minutes` : totalRepair < 0 ? '' : null;

    const Traveltimestart = moment(control.service24RepairStartTime).diff(control.service24DrivingStartTime, 'minute');
    const TraveltimeEnd = moment(control.service24DrivingEndTime).diff(control.service24RepairEndTime, 'minute');

    let TravelDiff = Traveltimestart + TraveltimeEnd;
    let travelHours = Math.floor(TravelDiff / 60);
    let travelMinutes = TravelDiff % 60;

    const TotalTravelTime = control.service24DrivingEndTime && TravelDiff > 0 ? `${travelHours} hours ${travelMinutes} minutes` : TravelDiff < 0 ? '' : null;

    const TechTime = totalRepair + TravelDiff;

    let techHH = Math.floor(TechTime / 60);
    let techMM = TechTime % 60;

    const TotaltechTime = TotalTravelTime && TechTime > 0 ? `${techHH} hours ${techMM} minutes` : TravelDiff < 0 ? '' : null;

    const TotalMileage = control.service24DrivingEndTime && control.service24DrivingFinishMileage - control.service24DrivingStartMileage;

    return (
        <div style={{ textAlign: 'left' }}>
            {/* <DialogTitle>
                Service 24h Control Form
                <IconButton size="small" style={{ float: 'right' }} onClick={props.onClose}>
                    <Close size="small" />
                </IconButton>
            </DialogTitle> */}
            <div>
                <Grid container direction="column" fullWidth spacing={1}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <InputLabel shrink>Driving Start Time</InputLabel>
                            <TextBox
                                type="datetime-local"
                                name="service24DrivingStartTime"
                                value={control.service24DrivingStartTime}
                                onChange={handleInput}
                                InputProps={{ inputProps: { max: `${control.service24RepairStartTime}` } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <InputLabel shrink>Driving Finish Time</InputLabel>
                            <TextBox
                                type="datetime-local"
                                name="service24DrivingEndTime"
                                value={control.service24DrivingEndTime}
                                onChange={handleInput}
                                InputProps={{ inputProps: { min: `${control.service24RepairEndTime}` } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <InputLabel shrink>Work Start Time</InputLabel>
                            <TextBox
                                type="datetime-local"
                                name="service24RepairStartTime"
                                value={control.service24RepairStartTime}
                                onChange={handleInput}
                                InputProps={{ inputProps: { max: `${control.service24RepairEndTime}`, min: `${control.service24RepairStartTime}` } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <InputLabel shrink>Work Completed End Time</InputLabel>
                            <TextBox
                                type="datetime-local"
                                name="service24RepairEndTime"
                                value={control.service24RepairEndTime}
                                onChange={handleInput}
                                InputProps={{ inputProps: { max: `${control.service24DrivingEndTime}`, min: `${control.service24RepairStartTime}` } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={6} style={{ paddingRight: '8px' }}>
                                    <InputLabel shrink>Start Post Code</InputLabel>
                                    <TextBox
                                        // type="number"
                                        name="service24StartPostCode"
                                        value={control.service24StartPostCode}
                                        onChange={handleInput}
                                        placeholder="Post Code"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Start Mileage</InputLabel>
                                    <TextBox
                                        type="number"
                                        name="service24DrivingStartMileage"
                                        value={control.service24DrivingStartMileage}
                                        onChange={handleInput}
                                        placeholder="Start Mileage"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Grid container>
                                <Grid item xs={12} sm={6} style={{ paddingRight: '8px' }}>
                                    <InputLabel shrink>Finish Post Code</InputLabel>
                                    <TextBox
                                        // type="number"
                                        name="service24FinishPostCode"
                                        value={control.service24FinishPostCode}
                                        onChange={handleInput}
                                        placeholder="Post Code"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputLabel shrink>Finish Mileage</InputLabel>
                                    <TextBox
                                        type="number"
                                        name="service24DrivingFinishMileage"
                                        value={control.service24DrivingFinishMileage}
                                        onChange={handleInput}
                                        placeholder="Finish Mileage"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Total Repair Time</InputLabel>
                            <TextBox disabled={true} value={TotalRepairTime} placeholder="Total Repair Time" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Total Travel Time</InputLabel>
                            <TextBox disabled={true} value={TotalTravelTime} placeholder="Total Travel Time" />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Service Van</InputLabel>
                            <TextBox
                                type="text"
                                name="service24ServiceVan"
                                value={control.service24ServiceVan}
                                onChange={handleInput}
                                placeholder="Service Van"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Total Mileage</InputLabel>
                            <TextBox disabled={true} value={TotalMileage > 0 ? TotalMileage : ''} placeholder="Total Mileage" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Technician Time</InputLabel>
                            <TextBox disabled={true} value={TotaltechTime} placeholder="Technician Time" />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">
                                Materials Used
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                        <TableContainer>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell width="20%">Part Number</StyledTableCell>
                                        <StyledTableCell width="40%">Description</StyledTableCell>
                                        <StyledTableCell width="20%">Quantity</StyledTableCell>
                                        <StyledTableCell width="15%">Price</StyledTableCell>
                                        <StyledTableCell width="5%"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {part.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                <TextBox
                                                    name="service24PartNumber"
                                                    value={row.service24PartNumber}
                                                    placeholder="Part Number"
                                                    onChange={handlepart(index)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextBox
                                                    name="service24PartDescription"
                                                    value={row.service24PartDescription}
                                                    placeholder="Description"
                                                    onChange={handlepart(index)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextBox
                                                    name="service24PartQuantity"
                                                    type="number"
                                                    value={row.service24PartQuantity}
                                                    placeholder="Quantity"
                                                    onChange={handlepart(index)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextBox
                                                    name="service24PartPrice"
                                                    type="number"
                                                    value={row.service24PartPrice}
                                                    placeholder="Price"
                                                    onChange={handlepart(index)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {index !== 0 && (
                                                    <RemoveCircleIcon
                                                        style={{ color: 'red', cursor: 'pointer' }}
                                                        fontSize="medium"
                                                        onClick={() => removePartAt(index)}
                                                    />
                                                )}
                                                &nbsp;
                                                {index === part.length - 1 && (
                                                    <AddBoxIcon style={{ color: 'green', cursor: 'pointer' }} fontSize="medium" onClick={addPartsRow} />
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid container item xs={12}>
                        <TableContainer>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell width="20%">Work Requested</StyledTableCell>
                                        <StyledTableCell width="40%">Reason/Cause</StyledTableCell>
                                        <StyledTableCell width="20%">Corrective Action</StyledTableCell>
                                        <StyledTableCell width="15%">Time Taken</StyledTableCell>
                                        <StyledTableCell width="5%"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {work.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                <TextBox
                                                    name="service24WorkRequested"
                                                    value={row.service24WorkRequested}
                                                    placeholder="Work Requested"
                                                    onChange={handlwork(index)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextBox
                                                    name="service24WorkReason"
                                                    value={row.service24WorkReason}
                                                    placeholder="Reason/Cause"
                                                    onChange={handlwork(index)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextBox
                                                    name="service24WorkAction"
                                                    value={row.service24WorkAction}
                                                    placeholder="Corrective Action"
                                                    onChange={handlwork(index)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <TextBox
                                                    name="service24WorkTimeTaken"
                                                    type="number"
                                                    value={row.service24WorkTimeTaken}
                                                    placeholder="Time Taken"
                                                    onChange={handlwork(index)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {index !== 0 && (
                                                    <RemoveCircleIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => removeWorkAt(index)} />
                                                )}
                                                &nbsp;
                                                {index === work.length - 1 && <AddBoxIcon style={{ color: 'green', cursor: 'pointer' }} onClick={addWorkRow} />}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="secondary">
                                Invoicing Details
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12}>
                            <RadioGroup
                                row
                                aria-label="paymentType"
                                name="service24PaymentTypeID"
                                value={control.service24PaymentTypeID}
                                onChange={handleInput}
                            >
                                {control.service24PaymentTypes.map((n) => {
                                    return <FormControlLabel value={n.id} control={<Radio />} label={n.name} />;
                                })}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    {/* <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12}>
                            <SecondaryButton fullWidth onClick={props.onClose}>
                                Submit
                            </SecondaryButton>
                        </Grid>
                    </Grid> */}
                </Grid>
            </div>
        </div>
    );
};

export default PartDialog;
