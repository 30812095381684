import React, { useContext, useState, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import AppContext from '../../../App/AppContext';
import Typography from '@material-ui/core/Typography';
import SignaturePad from 'react-signature-canvas';
import { TextBox, SecondaryButton, DialogContents, DefaultButton, CustomButton } from '../../../Core/FormInput';
import { getCustomerConsultations, addCustomerConsultation } from './../../../Core/Service/customerConsultation-service';
import moment from 'moment';
import { AppStorage } from '../../../Core/Service/storage-service';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';

const CustomerConsultation = (props) => {
    const { hideModal, hideTopNavAndMenu, showToast } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);
    const signCanvas = useRef({});
    const signCanvasAdvisor = useRef({});
    const [state, setState] = useState({
        date: '',
        wipNumber: '',
        registration: '',
        customerConsultationDefectReported: '',
        customerConsultationCustomerSignature: null,
        imgsign: null,
        serviceAdvisorSign: null,
        imgServiceAdvisonsign: null,
        CustomerConsultationID: null,
        headerRecordID: '',
        custConsultatItems: [],
        custConsultatOptions: [],
        fields: {},
        errors: {}
    });
    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.customerConsultationDefectReported) {
            formIsValid = false;
            errors['reportDefect'] = 'This field cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const Id = props.match.params.jobId;
    useEffect(async () => {
        let result = await getCustomerConsultations(Id);
        const updatedUser = result.data;
        if (updatedUser) {
            setState((st) => ({
                ...st,
                customerConsultationDefectReported: updatedUser.customerConsultationDefectReported,
                date: moment(updatedUser.dateDueIn).format('DD-MM-YYYY'),
                headerRecordID: updatedUser.headerRecordID,
                registration: updatedUser.registration,
                wipNumber: updatedUser.wipNumber,
                customerConsultationCustomerSignature: updatedUser.customerConsultationCustomerSignature,
                imgsign: updatedUser.customerConsultationCustomerSignature,
                custConsultatItems: updatedUser.custConsultatItems,
                custConsultatOptions: updatedUser.custConsultatOptions,
                CustomerConsultationID: updatedUser.customerConsultationID,
                serviceAdvisorSign: updatedUser.customerConsultationServiceAdvisorSignature,
                imgServiceAdvisonsign: updatedUser.customerConsultationServiceAdvisorSignature
            }));
        }
    }, []);

    const handleSubmit = async () => {
        if (handleValidation()) {
            const results = state.custConsultatItems.map((row) => ({
                customerConsultationResultItemID:
                    row.customerConsultationResultItemID === null ? row.customerConsultationItemID : row.customerConsultationResultItemID,
                customerConsultationResultOptionID: row.customerConsultationResultOptionID === null ? '3' : row.customerConsultationResultOptionID,
                customerConsultationResultNotes: row.customerConsultationResultNotes
            }));
            let res = await addCustomerConsultation(
                Id,
                state.customerConsultationDefectReported,
                state.customerConsultationCustomerSignature,
                state.serviceAdvisorSign,
                state.CustomerConsultationID,
                results
            );
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
            }
            showToast(res.message);
            if (res.success && fromStep) {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }
        }
    };

    const handleClick = (ItemID, OptionID) => {
        setState((st) => {
            const nst = {
                ...st,
                custConsultatItems: st.custConsultatItems.map((el) =>
                    el.customerConsultationItemID == ItemID
                        ? {
                              ...el,
                              customerConsultationResultOptionID: +OptionID,
                              customerConsultationResultItemID: +ItemID
                          }
                        : el
                )
            };
            return nst;
        });
    };
    const NoteChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = {
                ...st,
                custConsultatItems: st.custConsultatItems.map((el) =>
                    el.customerConsultationItemID == name ? { ...el, customerConsultationResultNotes: value } : el
                )
            };
            return nst;
        });
    };
    const Getsign = () => {
        let fields = state.fields;
        fields['customerConsultationCustomerSignature'] = signCanvas.current.toDataURL();
        setState((st) => {
            const nst = {
                ...st,
                customerConsultationCustomerSignature: signCanvas.current.toDataURL(),
                fields: fields
            };
            return nst;
        });
    };
    const GetServiceAdvisorsign = () => {
        let fields = state.fields;
        fields['serviceAdvisorSign'] = signCanvasAdvisor.current.toDataURL();
        setState((st) => {
            const nst = {
                ...st,
                serviceAdvisorSign: signCanvasAdvisor.current.toDataURL(),
                fields: fields
            };
            return nst;
        });
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            return { ...st, [name]: value, fields: fields };
        });
    };

    const useDefaultSig = () => {
        let userSign = AppStorage.getSignature();
        if (userSign) {
            signCanvasAdvisor.current.fromDataURL(AppStorage.getSignature());
            setState((st) => ({ ...st, serviceAdvisorSign: AppStorage.getSignature() }));
        } else {
            showToast('User signature not found');
        }
    };

    const clearLeaderSign = () => {
        if (state.imgServiceAdvisonsign) {
            setState((st) => ({
                ...st,
                imgServiceAdvisonsign: null,
                serviceAdvisorSign: null
            }));
        } else {
            signCanvasAdvisor.current.clear();
        }
    };
    return (
        <>
            <DialogContents>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <FormLabel>Date</FormLabel>
                        <br></br>
                        <Typography variant="button" style={{ float: 'left' }}>
                            {state.date}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormLabel component="legend">Registration Number</FormLabel>
                        <br></br>
                        <Typography variant="button" style={{ float: 'left' }}>
                            {state.registration}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormLabel>WIP No</FormLabel>
                        <br></br>
                        <Typography variant="button" style={{ float: 'left' }}>
                            {state.wipNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginBottom: 5 }}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Reported Defect
                        </FormLabel>
                        <TextBox
                            name="customerConsultationDefectReported"
                            value={state.customerConsultationDefectReported}
                            onChange={fieldChange}
                            multiline
                            rows={2}
                        />
                        <span className="mandatoryfields">{state.errors['reportDefect']}</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {state.custConsultatItems.map((m, i) => (
                            <Grid container spacing={2} style={{ background: i % 2 == 0 ? '#f7f7f7' : 'white' }}>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={8} md={8} lg={4}>
                                        <FormLabel component="legend" style={{ lineHeight: '50px' }}>
                                            {m.customerConsultationItemTitle}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={4} sm={3}>
                                        <Grid container spacing={3}>
                                            {m.customerConsultationItemID &&
                                                state.custConsultatOptions
                                                    ?.filter(function (a) {
                                                        if (a.customerConsultationItemID == m.customerConsultationItemID) return a;
                                                    })
                                                    .map((option) => (
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            sm={4}
                                                            style={{
                                                                float: 'left'
                                                            }}
                                                        >
                                                            <DefaultButton
                                                                className={
                                                                    `${option.id}` === `${m.customerConsultationResultOptionID}` &&
                                                                    `${m.customerConsultationResultOptionID}` === '7'
                                                                        ? 'btnActive1'
                                                                        : `${option.id}` === `${m.customerConsultationResultOptionID}` &&
                                                                          `${m.customerConsultationResultOptionID}` === '8'
                                                                        ? 'btnActiveNo1'
                                                                        : `${option.id}` === `${m.customerConsultationResultOptionID}` &&
                                                                          `${m.customerConsultationResultOptionID}` === '9'
                                                                        ? 'btnActiveNA1'
                                                                        : 'btninActive1'
                                                                }
                                                                style={{
                                                                    height: '10px'
                                                                }}
                                                                value={m.customerConsultationResultOptionID}
                                                                onClick={() => handleClick(`${m.customerConsultationItemID}`, `${option.id}`)}
                                                            >
                                                                {option.name}
                                                            </DefaultButton>
                                                        </Grid>
                                                    ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} style={{ marginTop: -20 }}>
                                    <TextBox
                                        label="Notes"
                                        placeholder={m.customerConsultationItemHelp}
                                        name={m.customerConsultationItemID}
                                        value={m.customerConsultationResultNotes}
                                        onChange={NoteChange}
                                        multiline
                                        rows={2}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={4} className="mt-11">
                        <FormLabel component="legend" style={{ marginBottom: '6px' }}>
                            Customer Signature
                        </FormLabel>
                        {state.imgsign ? (
                            <img
                                src={state.imgsign}
                                alt="my signature"
                                style={{
                                    display: 'block',
                                    margin: '0 auto',
                                    border: '1px solid black',
                                    width: '100%',
                                    height: '100px'
                                }}
                            />
                        ) : (
                            <SignaturePad
                                ref={signCanvas}
                                onEnd={() => {
                                    Getsign();
                                }}
                                canvasProps={{
                                    className: 'signatureCanvas'
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4} className="mt-11">
                        <FormLabel component="legend">
                            Service Advisor Signature &nbsp;
                            {!state.imgServiceAdvisonsign && (
                                <CustomButton icon={BorderColorIcon} onClick={useDefaultSig} toolTipTitle="Use default signature" />
                            )}
                            {!state.imgServiceAdvisonsign && (
                                <CustomButton icon={DeleteIcon} onClick={clearLeaderSign} IconColor="gray" toolTipTitle="Remove signature" />
                            )}
                        </FormLabel>
                        {state.imgServiceAdvisonsign ? (
                            <img
                                src={state.imgServiceAdvisonsign}
                                alt="my signature"
                                style={{
                                    display: 'block',
                                    margin: '0 auto',
                                    border: '1px solid black',
                                    width: '100%',
                                    height: '100px'
                                }}
                            />
                        ) : (
                            <SignaturePad
                                ref={signCanvasAdvisor}
                                onEnd={() => {
                                    GetServiceAdvisorsign();
                                }}
                                canvasProps={{
                                    className: 'signatureCanvas'
                                }}
                            />
                        )}
                        <span className="mandatoryfields">{state.errors['serviceAdvisorSign']}</span>
                    </Grid>
                </Grid>
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Submit
                </SecondaryButton>
            </DialogContents>
        </>
    );
};
export default CustomerConsultation;
