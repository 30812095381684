import { get, post } from './http-calls';

export let getReportingNotes = async (smn) => {
    let data = await get(`AgedMeasures/GetReportingAccountQuery?ReportingAccountQuerySystemMatchNumber=${smn}`, { useAuthToken: true });
    return data;
};
export let getHoverReportingNotes = async (smn) => {
    let data = await get(`AgedMeasures/GetReportingAccountQueryNotes?smn=10846442`, { useAuthToken: true });
    return data;
};

export let getReportingWIPLogs = async (headerRecordID) => {
    let data = await get(`agedMeasures/GetReportingWIPLogs?headerRecordID=${headerRecordID}`, { useAuthToken: true });
    return data;
};
export let addReportingWIPLogs = async (data) => {
    let res = await post(`AgedMeasures/SaveReportingWIPLog`, data, { useAuthToken: true });
    return res;
};

export const addReportingNotes = async (data) => {
    let res = await post(`AgedMeasures/SaveReportingAccountQuery`, data, { useAuthToken: true });
    return res;
};

export let getWeeklyFlashReport = async () => {
    let data = await get(`Docs/WeeklyFlashReport`, { useAuthToken: true });
    return data;
};

export let getMotHistory = async (regNo, FranchiseCode) => {
    let data = await get(`services/CheckMotHistory?regNo=${regNo}&FranchiseCode=${FranchiseCode}`, { useAuthToken: true });
    return data;
};

export let getAgedMeasuresViewDocument = async (InvoiceFileName) => {
    let data = await get(`AgedMeasures/ViewDocument?InvoiceFileName=${InvoiceFileName}`, { useAuthToken: true, responseType: 'blob' });
    return data;
};
