import { IconButton, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../../App/AppContext";
import { DefaultButton, DialogContents, Dialogs, DialogsActions, DialogTitles, SecondaryButton, TextBox } from "./../../../../Core/FormInput/index";
import "./../../User Management/UserManagement.css";
import { getOCRDocumentFieldToProcess, getOCRDocumentFieldToProcessDropdown, addUpdateDocumentFieldToProcess } from "../../../../Core/Service/OcrDocuments-service";
import { Tooltip } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddBoxIcon from "@material-ui/icons/AddBox";

const DocumentFieldToProcess = (props) => {
    const { hideModal, showToast } = useContext(AppContext);

    const [state, setState] = useState({
        id: null,
        enabled: true,
        ocrDocumentFieldId: null,
        documentsList: [],
        errors: [],
    });

    const splitByList = [
        {
            displayName: `${'\u00A0'}`,
            description: " "
        },
        {
            displayName: '_',
            description: '_'
        }
    ];

    const [inputList, setInputList] = useState([]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];

        list[index][name] = value;

        if(name === "splitBy" && value === " ") {
            list[index].splitIndex = 1;
        }
        if(name === "splitIndex") {
            if(value > 9) {
                list[index].splitIndex = 9;
            }
            if(value < 1) {
                list[index].splitIndex = 1;
            }
        }

        setInputList(list);
    };
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                splitBy: ' ',
                splitIndex: 1,
            }
        ]);
    };

    useEffect(async () => {
        pulldata()
    }, []);

    useEffect(() => {
        /* Called and Updated when scriptOnFileName state is set/changed. 
        Do not modify that state unless this needs to be re-called */

        if (state?.scriptOnFileName) {
            /* convert the retrieved data into parsable JSON */
            const str = state.scriptOnFileName
                .replaceAll("'", '"')
                .replaceAll("]", "")
                .replaceAll("[", "")
                .replaceAll("method", '"method"')
                .replaceAll("splitBy", '"splitBy"')
                .replaceAll("parts", '"splitIndex"')
                .replaceAll("by", '"splitBy"');

            /* Parse JSON */
            const dataArr = JSON.parse(`[${str}]`);

            /* Remove un-needed field in JSON */
            dataArr.map((obj) => {
                delete obj.method;
            })

            /* Add JSON Objects to InputList (which adds visible rows) */
            setInputList([
                ...dataArr,
            ]);
        }
    }, [state?.scriptOnFileName]);

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!state.ocrDocumentFieldId) {
            formIsValid = false;
            errors["ocrDocumentFieldId"] = "Document Field cannot be empty";
        }

        setState((st) => ({
            ...st,
            errors: errors,
        }));
        return formIsValid;
    };

    const pulldata = async () => {
        let res = await getOCRDocumentFieldToProcess(props.OCRDocToProcessList.id);

        const data = res?.data[0];

        if(data.id) {
            setState((st) => ({
                ...st,
                id: data.id,
                enabled: !data.isDeleted,
                ocrDocumentFieldId: data.ocrDocumentFieldId,
                scriptOnFileName: data.scriptOnFileName,
            }));
        }

        if (res.success) {
            let resDropdown = await getOCRDocumentFieldToProcessDropdown(props.OCRDocToProcessList.id)

            setState((st) => ({
                ...st,
                documentsList: resDropdown.data,
            }));
        }
    };

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const handleSubmit = async () => {
        // Below is the format that is expected
        /* XENTRY [{method:'split',splitBy:'_',parts:[6]},{method:'split',splitBy:'_',parts:[7]},{method:'split',splitBy:'_',parts:[9]}] */
        /* OTHER [{method:'split',by:' ',parts:[1]}] */

        /* Re-Create the script string in format that is expected */
        let script = "[";
        inputList.map((x, i) => {
            script += `{method:'split',${inputList.length == 1 ? "by" : "splitBy"}:'${x.splitBy}',parts:[${x.splitIndex}]}` + ((i + 1) != inputList.length ? "," : "]");
        })

        const data = {
            Id: state.id ? state.id : null,
            OcrDocumentToProcessId: props.OCRDocToProcessList.id,
            OcrDocumentFieldId: state.ocrDocumentFieldId,
            IsDeleted: !state.enabled,
            ScriptOnFileName: script === "[" ? "" : script
        }

        if(handleValidation()) {
            let res = await addUpdateDocumentFieldToProcess(data);
            if (!res.success) {
                console.error(res);
            } else {
                handleClose()
            }
        }
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <Dialogs open={true} onClose={handleClose} width="lg">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitles>Update OCR Document Field</DialogTitles>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormLabel component="legend">Document Field</FormLabel>
                        <TextBox
                            select
                            name="ocrDocumentFieldId"
                            value={state.ocrDocumentFieldId}
                            onChange={fieldChange}
                            variant="outlined"
                        >
                            {state.documentsList?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.fieldLabel}
                                </MenuItem>
                            ))}
                        </TextBox>
                        <span className="mandatoryfields">
                            {state.errors["ocrDocumentFieldId"]}
                        </span>
                    </Grid>

                    {inputList.length == 0 && (
                        <Grid item xs={12}>
                            <Typography variant="p" style={{ color: 'red', fontWeight: 'bold' }}>
                                Add split data using the + icon
                            </Typography>
                            <Tooltip title="Add Split Section" aria-label="add">
                                <IconButton
                                    onClick={handleAddClick}
                                    className="rightButton"
                                    style={{
                                        marginRight: '5px'
                                    }}
                                >
                                    <AddBoxIcon color="secondary" fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}

                    {inputList.map((x, i) => (
                        <>
                            <Grid item xs={12}>
                                <Tooltip title="Remove Split Section" aria-label="add">
                                    <IconButton onClick={() => handleRemoveClick(i)} className="rightButton">
                                        <RemoveCircleIcon color="primary" fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                {inputList.length - 1 === i && (
                                    <Tooltip title="Add Split Section" aria-label="add">
                                        <IconButton
                                            onClick={handleAddClick}
                                            className="rightButton"
                                            style={{
                                                marginRight: '5px'
                                            }}
                                        >
                                            <AddBoxIcon color="secondary" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>

                            <Grid item xs={6}>
                                <FormLabel component="legend">Split By</FormLabel>
                                <TextBox
                                    select
                                    name="splitBy"
                                    value={x.splitBy}
                                    onChange={(e) => handleInputChange(e, i)}
                                    variant="outlined"
                                >
                                    {splitByList?.map((option) => (
                                        <MenuItem key={option.description} value={option.description}>
                                            {option.displayName}
                                        </MenuItem>
                                    ))}
                                </TextBox>
                            </Grid>
                            <Grid item xs={6}>
                                <FormLabel component="legend">Split Index</FormLabel>
                                <TextBox
                                    disabled={x.splitBy == " "}
                                    name="splitIndex"
                                    onChange={(e) => handleInputChange(e, i)}
                                    type="number"
                                    value={x.splitIndex}
                                />
                            </Grid>
                        </>
                    ))}

                    <Grid item xs={2} sm={2}>
                        <FormLabel
                            component="legend"
                            className="btn_add"
                        >
                            Is Active?
                        </FormLabel>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <DefaultButton
                            className={state.enabled ? "btnActive" : "btninActive"}
                            value={state.enabled}
                            name="enabled"
                            onClick={() =>
                                handleClick("enabled", true)
                            }
                        >
                            Yes
                        </DefaultButton>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <DefaultButton
                            className={state.enabled ? "btninActive" : "btnActiveNo"}
                            value={state.enabled}
                            name="enabled"
                            onClick={() =>
                                handleClick("enabled", false)
                            }
                        >
                            No
                        </DefaultButton>
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default DocumentFieldToProcess;
