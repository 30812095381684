import React, { useReducer } from 'react';
import { Grid, Typography, InputLabel, RadioGroup, FormControlLabel, Radio, Checkbox, FormHelperText } from '@material-ui/core';
import { reducer, initState } from '../../../Reducer/action';
import { SecondaryButton, TextBox, YesNoButton } from '../../../../Core/FormInput/index';
import { useState, useCallback } from 'react';
import CommonGijgoGrid from '../../../../Core/Controls/GijgoGrid/index';
import { moment } from 'moment';
import SelectBox from './../../../../Core/FormInput/SelectBox';
import DataGridComp from '../../../../Core/Grid';
import { GridOverlay } from '@material-ui/data-grid';
import { Email } from '@material-ui/icons';
import { postCommunicatorAddActionLog } from '../../../../Core/Service/communicator-service';
import { useContext } from 'react';
import AppContext from '../../../../App/AppContext';

const AddEditActionLog = (props) => {
    const [state, setState] = useState({
        logVisibleToCustomer: false,
        logHighPriority: false,
        isCustomerUpdate: false,
        isUpdateSms: false,
        errors: {}
    });

    const { showToast, showModal } = useContext(AppContext);
    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setState((st) => ({ ...st, [name]: checked }));
    };

    const Newvalidation = () => {
        let { logText, logActionTime } = state;
        let errors = {};
        let newlogTextmessage = '';
        let logActionTimeMessage = '';
        let isValid = true;
        if (!logText) {
            isValid = false;
            newlogTextmessage = 'Log Message is required';
        }
        if (!logActionTime) {
            isValid = false;
            logActionTimeMessage = 'Reminder Action Date & Time is  required';
        }
        if (state.isUpdateSms && !state.isPhoneNo) {
            isValid = false;
            errors.isPhoneNo = 'SMS is required';
        }
        if (state.isCustomerUpdate && !state.isUpdateCustomerEmail) {
            isValid = false;
            errors.isUpdateCustomerEmail = 'Email is required';
        }
        if (state.logHighPriority && !state.isHighPriorityEmail) {
            isValid = false;
            errors.isHighPriorityEmail = 'Email is required';
        }
        setState((st) => ({ ...st, newlogTextmessage: newlogTextmessage, logActionTimeMessage: logActionTimeMessage, errors: errors }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (Newvalidation()) {
            let data = {
                logID: null,
                logHeaderRecordID: props.headerRecordID,
                logText: state.logText,
                logActionTime: state.logActionTime,
                logResultText: state.logResultText,
                logVisibleToCustomer: state.logVisibleToCustomer,
                logHighPriority: state.logHighPriority,
                isPhoneNo: state.isPhoneNo || '',
                isUpdateCustomerEmail: state.isUpdateCustomerEmail || '',
                isHighPriorityEmail: state.isHighPriorityEmail || ''
            };

            let res = await postCommunicatorAddActionLog(data);
            if (res.success) {
                showToast(`Action Log Added Successfully`);
                props.onClose(true);
            } else {
                showToast(`Action Log Failed To Add`);
            }
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ fontSize: 17, textAlign: 'left', marginBottom: 10 }}>
                    WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>
                    {/* <span style={{ float: 'right' }}>
                        Key No:&nbsp; <b> 63216</b>{' '}
                    </span> */}
                </Grid>
                <Grid container item xs={12} spacing={2} style={{ marginLeft: 2 }}>
                    <Grid item xs={12}>
                        <InputLabel shrink error>
                            Log Message (
                            <input
                                type="checkbox"
                                style={{ cursor: 'pointer' }}
                                onChange={handleCheck}
                                name="logVisibleToCustomer"
                                checked={state.logVisibleToCustomer}
                            />{' '}
                            Visible to customer?)
                        </InputLabel>
                        <TextBox placeholder="Log Message" name="logText" value={state.logText} multiline rows={4} variant="outlined" onChange={handleInput} />
                        <FormHelperText error>{state.newlogTextmessage}</FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink error style={{ paddingBottom: 4 }}>
                            Reminder Action Date & Time
                        </InputLabel>
                        <TextBox type="datetime-local" name="logActionTime" value={state.logActionTime} variant="outlined" fullWidth onChange={handleInput} />
                        <FormHelperText error>{state.logActionTimeMessage}</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel shrink>
                            <input
                                type="checkbox"
                                style={{ cursor: 'pointer' }}
                                onChange={handleCheck}
                                name="logHighPriority"
                                checked={state.logHighPriority}
                            />
                            High Priority
                        </InputLabel>
                        <TextBox
                            placeholder="Email action log to high priority email"
                            type="email"
                            name="isHighPriorityEmail"
                            variant="outlined"
                            fullWidth
                            value={state.isHighPriorityEmail}
                            onChange={handleInput}
                            style={{ paddingTop: 0 }}
                            error={state.logHighPriority}
                        />
                        <FormHelperText error>{state.errors.isHighPriorityEmail}</FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel shrink>
                            <input
                                type="checkbox"
                                style={{ cursor: 'pointer' }}
                                name="isCustomerUpdate"
                                onChange={handleCheck}
                                checked={state.isCustomerUpdate}
                            />
                            Customer Email
                        </InputLabel>
                        <TextBox
                            placeholder="Email update to customer"
                            type="email"
                            name="isUpdateCustomerEmail"
                            variant="outlined"
                            fullWidth
                            value={state.isUpdateCustomerEmail}
                            onChange={handleInput}
                            error={state.isCustomerUpdate}
                        />
                        <FormHelperText error>{state.errors.isUpdateCustomerEmail}</FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel shrink>
                            <input type="checkbox" style={{ cursor: 'pointer' }} onChange={handleCheck} name="isUpdateSms" checked={state.isUpdateSms} />
                            Phone Number
                        </InputLabel>
                        <TextBox
                            placeholder="Phone Number"
                            type="tel"
                            name="isPhoneNo"
                            variant="outlined"
                            fullWidth
                            value={state.isPhoneNo}
                            onChange={handleInput}
                            error={state.isUpdateSms}
                        />
                        <FormHelperText error>{state.errors.isPhoneNo}</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmit}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddEditActionLog;
