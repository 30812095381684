import { Grid, IconButton } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { ExpBaseURL } from '../../../../../Core/Service/http-calls';
import { AppStorage } from '../../../../../Core/Service/storage-service';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReactDOM from 'react-dom';
import { useState } from 'react';
import formatters from '../../../../../Core/Grid/inputFormatter';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';

import '../../GijgoForReportsScreen/reportGijgoGrid.scss';
import { getAgedMeasuresViewDocument } from '../../../../../Core/Service/ReportScreens-service';
import DialogComp from '../../../../../Core/Modal/dialogModal';
import ViewPdfModal from '../../../../../Core/Modal/PdfViweModal';

var mainGrid, ccGrid;
var $ = window.$;

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Outstanding Invoices', active: true }
];

const OutstandingInvoices = () => {
    let token = AppStorage.getToken();
    const [state, setState] = useState({});
    const mainGridOnSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        mainGrid.render(obj);
    };
    const ccGridOnSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        ccGrid.render(obj);
    };

    const documentHandler = async (record) => {
        let res = await getAgedMeasuresViewDocument(record.ifn);
        if (res) {
            setState((st) => ({ ...st, showPdfModal: true, pdfFileData: res }));
        } else {
            console.error(res.message);
        }
    };

    const docEditButton = (value, record) => {
        console.log(record);
        const spn = document.createElement('span');
        let isFile = record.ifn;
        const lnk = (
            <span style={{ cursor: isFile ? 'pointer' : 'text' }} onClick={isFile ? () => documentHandler(record) : ''}>
                {value}
            </span>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    let mainGridcolumns = [
        { field: 'cc', title: 'Account Code', width: 120 },
        { field: 'cn', title: 'Customer Name', width: 250 },
        { field: 'os', title: 'O/S Amount', renderer: formatters.CurrencyThousandSeparator, align: 'right', width: 110 },
        { field: 'osi', title: '# Of Invoices', width: 100, align: 'right' },
        { field: 'atdt', title: 'ATD Turnover', width: 150, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
        { field: 'ptdt', title: 'PTD Turnover', width: 150, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
        { field: 'pu', title: 'Periods Unused', width: 150, align: 'right' },
        { field: 'lp', title: 'Last Payment', width: 150, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
        {
            field: 'lpd',
            title: 'Last Day',
            width: 150,
            renderer: formatters.MonthShortFormatter,
            align: 'right'
        }
    ];

    let ccGridcolumns = [
        { field: 'dn', title: 'Document No', width: 156, align: 'right', renderer: docEditButton },
        { field: 'bal', title: 'Balance', renderer: formatters.CurrencyThousandSeparator, align: 'right' },
        { field: 'org', title: 'Orignal', renderer: formatters.CurrencyThousandSeparator, align: 'right' },
        { field: 'dd', title: 'Document Date', width: 150, renderer: formatters.MonthShortFormatter },
        { field: 'due', title: 'Due', width: 150, renderer: formatters.MonthShortFormatter },
        { field: 'am', title: 'Age (Months)', width: 150, align: 'right' },
        { field: 'ag', title: 'Age (Days)', width: 150, align: 'right' },
        { field: 'br', title: 'Branch', width: 150 }
    ];

    useEffect(() => {
        mainGrid = $('#OutstandingInvoices').grid({
            primaryKey: 'cc',
            dataSource: {
                url: `${ExpBaseURL}/api/Sales/CustomerOutstandingInvoices`,
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: mainGridOnSuccessFunc
            },
            minWidth: 1200,
            detailTemplate: '<div><table/></div>',
            columns: mainGridcolumns,
            headerFilter: {
                type: 'onchange'
            },
            pager: { limit: 20 }
        });
        mainGrid.on('detailExpand', function (e, $detailWrapper, cc) {
            ccGrid = $detailWrapper.find('table').grid({
                params: { type: 2, cc: cc.trim() },
                primaryKey: 'opno',
                headerFilter: {
                    type: 'onchange'
                },
                dataSource: {
                    url: `${ExpBaseURL}/api/Sales/CustomerOutstandingInvoices`,
                    headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                    success: ccGridOnSuccessFunc
                },
                columns: ccGridcolumns
            });
        });
    });

    return (
        <div className="report-screen-container">
            {state.showPdfModal ? (
                <DialogComp title="View PDF" maxWidth="md" onClose={() => setState((st) => ({ ...st, showPdfModal: false }))} fullWidth>
                    <ViewPdfModal pdfFileData={state.pdfFileData} />
                </DialogComp>
            ) : null}
            <BreadCrumbs crumbs={crumbs} />
            <Grid item container className="report-screen-gijgo" spacing={1} xs={12}>
                <Grid item xs={12}>
                    <table id={`OutstandingInvoices`}></table>
                </Grid>
            </Grid>
        </div>
    );
};

export default OutstandingInvoices;
