import { useMemo, useState } from 'react';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import { FormLabel, Grid, TextField, Tooltip } from '@material-ui/core';
import SendMessageModal from './sendMessageModal';
import moment from 'moment';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import ReactDOM from 'react-dom';
import { CustomIconButton, SquareButton } from '../../../Core/FormInput/AppButton';
import ReportGijgoGrid from '../../internal/ReportsScreens/GijgoForReportsScreen';
import ListAltIcon from '@material-ui/icons/ListAlt';
import BookingsScreen from '../../internal/Communicator/Bookings';
import { SlideToggle } from '../../../Core/FormInput';
import { Autocomplete } from '@material-ui/lab';
import formatters from '../../../Core/Grid/inputFormatter';
import { AppStorage } from '../../../Core/Service/storage-service';

const years = () => {
    const years = [];
    const d = new Date();
    let startYear = d.getFullYear() - 10;
    let endYear = d.getFullYear() + 10;

    while (endYear - startYear >= 0) {
        years.push({ description: `${startYear}`, id: `${startYear}` });
        startYear = startYear + 1;
    }
    return years;
};

const baseColumns = [
    {
        title: 'Action',
        field: 'action',
        width: 70,
        filterable: false
    },
    { field: 'wipNumber', sortable: true, title: `${'WIP No'}`, width: 70 },
    { field: 'registration', sortable: true, title: `${'Vehicle Reg'}`, width: 90 },
    { field: 'st', sortable: true, title: `${'Job Description'}`, width: 170 },
    { field: 'makeModel', sortable: true, title: `${'Model'}`, width: 200 },
    {
        field: 'dateDueIn',
        sortable: true,
        title: `${'Due Date In'}`,
        type: 'dateTime',
        width: 135,
        renderer: function (value, record) {
            if (record.dateDueIn != null) {
                return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
            }
            return '-';
        }
    },
    {
        field: 'dateDueOut',
        sortable: true,
        title: `${'Date Due Out'}`,
        type: 'dateTime',
        width: 135,
        renderer: function (value, record) {
            if (record.dateDueOut != null) {
                return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
            }
            return '-';
        }
    },
    { field: 'branchShortName', sortable: true, title: `${'Branch'}`, width: 120 },
    {
        field: 'status',
        sortable: true,
        title: `${'Status'}`,
        width: 100
    }
];

const screenName = {
    S: 'Site Vehicles',
    V: 'VOR Vehicles',
    W: 'Workshop Vehicles'
};
// const crumbs = (val) => {
//     return [
//         { name: 'Home', path: '/' },
//         { name: 'Communicator', path: '/communicator_dash' },
//         { name: 'Bookings', active: true }
//     ];
// };

const crumbs = (menuPermissionCounter) => {
    let list = [{ name: 'Home', path: '/' }];
    if (menuPermissionCounter !== 1) {
        list.push({ name: 'Communicator', path: '/communicator_dash' });
    }
    list.push({ name: 'Bookings', active: true });
    return list;
};

const CustBookings = (props) => {
    const monthsList = formatters.constantMonthList();
    let menuPermissionCounter = AppStorage.menuPermissionCounter();

    const [show, setShow] = useState({
        showSendMessageModal: false,
        isGrid: true,
        monthCode: monthsList[`${new Date().getMonth()}`],
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` }
    });

    const HandelSendMessage = (val) => {
        setShow((st) => ({
            ...st,
            showSendMessageModal: true,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <CustomIconButton onClick={() => HandelSendMessage(record)} toolTipTitle="Add Action Log">
                        <ListAltIcon fontSize="medium" style={{ color: '#183B68' }} />
                    </CustomIconButton>
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumns];
        let colAction = col.find((m) => m.field === 'action');
        if (colAction) {
            colAction.renderer = editButton;
        }
        return col;
    }, []);

    const handleClose = (res) => {
        let newSt = {};
        newSt.showSendMessageModal = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setShow((st) => ({
            ...st,
            ...newSt
        }));
    };

    const handleInput = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setShow((st) => ({ ...st, [name]: checked }));
        } else {
            setShow((st) => ({ ...st, [name]: value }));
        }
    };

    const handleAutoChange = (fieldName) => (e, val) => {
        setShow((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            return newSt;
        });
    };
    console.log(show.monthCode, show.yearCode);
    const getUrl = `CustUserCommunicator/Booking?Month=${show.monthCode.id}&Year=${show.yearCode.id}`;

    return (
        <div className="report-screen-container">
            <Grid container justify="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs(menuPermissionCounter)} />
                </Grid>
                <Grid item>
                    <Tooltip title="Select Page View" aria-label="add">
                        <Grid container alignItems="center">
                            <Grid item>
                                <SlideToggle leftLabel="Calendar" rightLabel="List" name="isGrid" checked={show.isGrid} onChange={handleInput} />
                            </Grid>
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid container>
                {show.isGrid ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormLabel>Select Month</FormLabel>
                                <Autocomplete
                                    disableClearable
                                    autoSelect
                                    options={monthsList}
                                    name="Month"
                                    value={show.monthCode}
                                    onChange={handleAutoChange('monthCode')}
                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                    renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormLabel>Select Year</FormLabel>
                                <Autocomplete
                                    disableClearable
                                    autoSelect
                                    options={years()}
                                    name="Years"
                                    value={show.yearCode}
                                    onChange={handleAutoChange('yearCode')}
                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                    renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <ReportGijgoGrid
                                dataConverter={(res) => ({
                                    records: res.data.list,
                                    total: res.data.total
                                })}
                                columns={column}
                                getUrl={getUrl}
                                displayPagination={true}
                                isReload={show.isReload}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <BookingsScreen />
                    </Grid>
                )}
            </Grid>
            {show.showSendMessageModal ? (
                <DialogComp title={`Send Message (WIP : ${show.wip})`} onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                    <SendMessageModal onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default CustBookings;
