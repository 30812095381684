import { useEffect, useState } from 'react';
import { DefaultButton, PrimaryButton, SecondaryButton } from '../FormInput';
import { useHistory } from 'react-router-dom';

export const SpaceType = {
    start: 'start',
    spaceAround: 'space-around',
    spaceBetween: 'space-between'
};

const BasicButtonGroup = ({
    className = '',
    buttons = [],
    btnSelectedId,
    selectedId = 0,
    triggerButtonClickOnLoad = true,
    onClick,
    spaceType = SpaceType.start,
    margin = 0,
    color = 'secondary',
    allGrid
}) => {
    const [state, setState] = useState({
        selectedId,
        buttons: [],
        basicStyle: { minHeight: 30, display: 'flex', justifyContent: spaceType, margin }
    });
    useEffect(() => {
        if (triggerButtonClickOnLoad && onClick) {
            if (btnSelectedId !== 'All' && btnSelectedId) {
                const hasSelected = buttons.filter((b) => b.id === +btnSelectedId).length === 1;
                hasSelected ? buttonClicked(buttons.filter((b) => b.id === +btnSelectedId)[0]) : buttons.length > 0 && buttonClicked(buttons[0]);
            }
            if (!allGrid) {
                const hasSelected = buttons.filter((b) => b.id === state.selectedId).length === 1;
                hasSelected ? buttonClicked(buttons.filter((b) => b.id === state.selectedId)[0]) : buttons.length > 0 && buttonClicked(buttons[0]);
            }
        } else {
            const hasSelected = buttons.filter((b) => b.id === selectedId).length === 1;
            hasSelected ? buttonClicked(buttons.filter((b) => b.id === selectedId)[0]) : buttons.length > 0 && buttonClicked(buttons[0]);
        }
        setState((st) => ({
            ...st,
            buttons
        }));
    }, [selectedId, buttons]);

    const buttonClicked = (btn) => {
        setState((st) => ({ ...st, selectedId: btn.id }));
        onClick && onClick(btn);
    };

    let history = useHistory();
    let mainWorkflowButtonId = history.location.pathname.split('/')[3];

    return (
        <div style={state.basicStyle} className={className}>
            {state.buttons.map((btn) => {
                if (btn.id == mainWorkflowButtonId) {
                    return (
                        <SecondaryButton style={{ backgroundColor: 'green' }} key={btn.id} size="small" onClick={() => buttonClicked(btn)}>
                            {btn.label}
                        </SecondaryButton>
                    );
                }
                if (btn.id === state.selectedId) {
                    if (color === 'secondary') {
                        return (
                            <SecondaryButton key={btn.id} size="small" onClick={() => buttonClicked(btn)}>
                                {btn.label}
                            </SecondaryButton>
                        );
                    }
                    return (
                        <PrimaryButton key={btn.id} size="small" onClick={() => buttonClicked(btn)}>
                            {btn.label}
                        </PrimaryButton>
                    );
                } else {
                    return (
                        <DefaultButton key={btn.id} size="small" onClick={() => buttonClicked(btn)}>
                            {btn.label}
                        </DefaultButton>
                    );
                }
            })}
        </div>
    );
};

export default BasicButtonGroup;
