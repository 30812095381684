import { get, post } from './http-calls';

export const getJobNotes = async jobId =>{
    let res = await get(`JobNotes/${jobId}`, { useAuthToken : true });    
    return res;
}

export const addJobNotes = async (jobId, message) =>{
    let res = await post(`JobNotes`, {jobId, message}, { useAuthToken : true });    
    return res;
}