import React, { useEffect, useState } from 'react';
import Dashboard from './../../../dashboard/dashboard';

import { ServicePieChart, ServieBarChart, ServiceLineChart } from './serviceChart';

import { getWarrantyDashBoard, getSoldHoursDashBoard, getWIPDashBoard, getGetUserFilterRecords } from './../../../../../Core/Service/serviceDashboard_service';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

const ServiceDashboard = () => {
    const [state, setState] = useState({
        soldbyMonth: [],
        soldbyBranch: [],
        servicecWarrenty: [],
        partWarrenty: [],
        WipHour: [],
        WipOutStanding: [],
        showLoader: true
    });
    useEffect(async () => {
        let res = await getWarrantyDashBoard();
        let res_2 = await getSoldHoursDashBoard();
        let res_3 = await getWIPDashBoard();
        let res_4 = await getGetUserFilterRecords();

        setState((st) => ({
            ...st,
            servicecWarrenty: res.data.table.map((n) => {
                return {
                    name: n.branch,
                    total: n.totalAmount
                };
            }),
            partWarrenty: res.data.table1.map((n) => {
                return {
                    name: n.branch,
                    total: n.totalAmount
                };
            }),
            soldbyMonth: res_2.data.table.map((n) => {
                return {
                    name: n.labourMonth,
                    total: n.labourHours
                };
            }),
            soldbyBranch: res_2.data.table1.map((n) => {
                return {
                    name: n.branchShortName,
                    total: n.labourTotal
                };
            }),

            WipHour: res_3.data.table.map((n) => {
                return {
                    name: n.branchShortName,
                    total: n.totalHours
                };
            }),
            WipOutStanding: res_3.data.table1.map((n) => {
                return {
                    name: n.branchShortName,
                    total: n.totalOutstanding
                };
            }),
            showLoader: false
        }));
    }, []);

    return (
        <div style={{ padding: '10px' }}>
            {state.showLoader ? (
                <div style={{ width: '100%' }}>
                    <div style={{ height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                </div>
            ) : (
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={6} xl={4}>
                        <Typography color="secondary" variant="button">
                            Work In Progress Clocked Hours
                        </Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                <div style={{ width: '100%', height: 400 }}>
                                    <ServieBarChart data={state.WipHour} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ width: '100%', height: 400, position: 'relative' }}>
                                    <ServicePieChart data={state.WipOutStanding} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={6} lg={6} xl={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography color="secondary" variant="button">
                                    Service Warranty Write off
                                </Typography>
                                <div style={{ width: '100%', height: 400 }}>
                                    <ServieBarChart data={state.servicecWarrenty} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="secondary" variant="button">
                                    Part Warranty Write off
                                </Typography>
                                <div style={{ width: '100%', height: 400 }}>
                                    <ServieBarChart data={state.partWarrenty} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12} xl={4}>
                        <Grid container>
                            <Grid item sm={12} md={6} lg={6} xl={12}>
                                <Typography color="secondary" variant="button">
                                    Sold Hours By Month Total
                                </Typography>
                                <div style={{ width: '100%', height: 400 }}>
                                    <ServieBarChart data={state.soldbyMonth} />
                                </div>
                            </Grid>
                            <Grid item sm={12} md={6} lg={6} xl={12}>
                                <Typography color="secondary" variant="button">
                                    Sold Hours By Branch
                                </Typography>
                                <div style={{ width: '100%', height: 400 }}>
                                    <ServiceLineChart data={state.soldbyBranch} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default ServiceDashboard;
