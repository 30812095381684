import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, SecondaryButton, DialogContents, DialogTitles, Dialogs, UploadWithBadge } from '../../../Core/FormInput';
import '../../../Core/Modal/model.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { getPartReqs, addPartReq } from '../../../Core/Service/partRequisitions-service';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './TechnicianStyle.scss';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import { isInteger, some } from 'lodash';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import { withStyles } from '@material-ui/core/styles';
import PartReqPhotoModal from './PartReqPhotoUploadmodal';
import InfoIcon from '@material-ui/icons/Info';
import PartReqOtherInfo from './RequestorApproverIssuerInfo';
import { postPhotosByPartReqID } from '../../../Core/Service/partRequisitions-service';

const styles = {
    gridImage: { width: 60, height: 'auto', backgroundSize: 'cover', marginTop: '5%' },
    gridImageContainer: { width: '100%', textAlign: 'center' }
};

const PartRequisitionsModal = (props) => {
    const { showToast, hideTopNavAndMenu, userRoles } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const [windowWidths, windowHeight] = useWindowSize();

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const [btnLoader, setBtnLoader] = useState({ btn1: false, btn2: false });

    const [state, setState] = useState({
        part: '',
        quantity: undefined,
        rows: [],
        partReq: [],
        isUpdate: false,
        partId: null,
        technicianJobId: null,
        technician: [],
        wipDetails: [],
        partReqTechnicianSignature: null,
        errors: {},
        imgPreview: false,
        signature: null,
        checkIssued: null,
        showImage: false
    });
    const Id = props.match.params.jobId;

    const GlobalCss = withStyles({
        '@global': {
            '.MuiOutlinedInput-inputMarginDense': {
                paddingTop: '4.1px',
                paddingBottom: '4.1px'
            }
        }
    })(() => null);

    const handleUpdatePart = (params) => {
        const row = params.value.row;
        setState((st) => {
            const nst = {
                ...st,
                id: params.value.row.id,
                part: row.partReqPartDescription,
                quantity: row.partReqQuantity,
                isUpdate: true,
                partId: params.value.row.id
            };
            return nst;
        });
    };
    const handleDeletePart = (params) => {
        const id = params.value.row.id;
        const newList = state.rows.filter((item) => item.id !== id);
        let newPartToSetIsDelete = {
            id: params.value.row.id,
            partReqPartDescription: params.value.row.partReqPartDescription,
            partReqQuantity: params.value.row.partReqQuantity,
            isDeleted: true
        };
        newList.push(newPartToSetIsDelete);
        setState((st) => {
            const nst = {
                ...st,
                rows: newList
            };
            return nst;
        });
    };
    const setImgPreview = (val, data = null, date) => {
        setState((st) => ({ ...st, imgPreview: val, signature: data, sigDate: date }));
    };

    const changeQantity = (id, des, name) => (e) => {
        let changedArray = [...state.rows];
        // let temp = [...state.changedRecordIds];
        let objIndex = state.rows.findIndex((x) => x.id === id);
        changedArray[objIndex][name] = e.target.value;
        // let findIDExist = temp.find((a) => a == id);
        // if (!findIDExist) {
        //     temp.push(id);
        // }
        setState((st) => ({
            ...st,
            rows: changedArray
            // changedRecordIds: temp
        }));
    };

    const onImagemodalClose = (res) => {
        setState((st) => ({ ...st, showImage: false, partId: null, desc: '' }));
        makeEmptyPhotos('oldPhoto');
        res && pullWorkflowAndUpdateState();
    };
    const [data, setData] = useState({ showInfo: false });
    const setShowInfo = (val) => {
        if (val.requestor) {
            setData((st) => ({
                ...st,
                showInfo: true,
                requestor: val.requestor,
                requestorSign: val.requestorSignature,
                requestorDate: val.requestedTime,
                issuer: val.issuer,
                issuerSign: val.issuerSignature,
                issuerDate: val.issuedTime,
                approver: val.approver,
                approverSign: val.approverSignature,
                approverDate: val.approvedTime
            }));
        }
    };

    const columns = [
        {
            field: 'partReqPartDescription',
            headerName: 'Part',
            flex: 1,
            renderCell: (params) => {
                return (
                    <input
                        name="desc"
                        id="desc"
                        data-rowid={params.row.id}
                        // onChange={onIssueQtyChange}
                        onChange={changeQantity(params.row.id, params.row.partReqPartDescription, 'partReqPartDescription')}
                        value={params.value}
                        className="pa-4"
                        disabled={params.row.disabled}
                    />
                );
            }
        },
        {
            field: 'partReqQuantity',
            headerName: 'Qty',
            width: 100,
            renderCell: (params) => {
                return (
                    <div style={{ position: 'relative', width: '100%' }}>
                        {!params.row.partReqQuantity && <span style={{ position: 'absolute', top: -11, right: -5, color: 'red' }}>*</span>}
                        <input
                            name="desc"
                            id="desc"
                            data-rowid={params.row.id}
                            onChange={changeQantity(params.row.id, params.row.partReqQuantity, 'partReqQuantity')}
                            value={params.value}
                            className="pa-4"
                            disabled={params.row.disabled}
                        />
                    </div>
                );
            }
        },
        { field: 'issueStatus', headerName: 'Issue Status', width: 230 },
        {
            field: '',
            headerName: '',
            width: 140,
            sortable: false,
            renderCell: (params) => (
                <div style={{ margin: '20px 0px 0px 9px', cursor: `${params.row.photosUploaded >= 0 ? 'pointer' : ''}` }}>
                    <UploadWithBadge
                        state={params.row.photosUploaded > 0 || 0}
                        count={params.row.photosUploaded || 0}
                        onClick={() =>
                            params.row.photosUploaded >= 0 &&
                            setState((st) => ({ ...st, partId: params.row.id, desc: params.row.partReqPartDescription, showImage: true }))
                        }
                    />
                    <InfoIcon disabled={params.row.disabled} className="doneIcon" onClick={() => setShowInfo(params.row)} />
                </div>
            )
        }
    ];
    const isTechnician = userRoles.includes('technician');

    const new_columns = useMemo(() => {
        const tempCols = [...columns];
        if (state.checkIssued) {
            tempCols.splice(0, 2, { field: 'qnIssued', headerName: 'Issued', width: 140 });
        }
        return tempCols;
    }, [columns]);

    const technicianJobId = props.match.params?.technicianJobId;
    useEffect(async () => {
        setState((st) => ({
            ...st,
            technicianNotesJobID: technicianJobId
        }));
    }, []);

    useEffect(() => {
        pullWorkflowAndUpdateState();
    }, [state.technicianNotesJobID]);

    const TechBtnClick = (id) => {
        setState((st) => ({
            ...st,
            technicianNotesJobID: id
        }));
    };

    const pullWorkflowAndUpdateState = async () => {
        let result = await getPartReqs(Id, state.technicianNotesJobID);
        const Result = result.data.partReqs.map((row) => ({
            id: row.partReqID,
            partReqPartDescription: row.partReqPartDescription,
            partReqQuantity: row.partReqQuantity,
            isDeleted: false,
            disabled: row.issuerSignature && row.approverSignature,
            isApproved: row.isApproved,
            qnIssued: row.partReqIssuedQuantity,
            requestor: row.requestor,
            approver: row.approver,
            partReqStatusDescription: row.partReqStatusDescription,
            issuer: row.issuer,
            issueStatus: row.issueStatus,
            requestorSignature: row.requestorSignature,
            issuerSignature: row.issuerSignature,
            approverSignature: row.approverSignature,
            partReqId: row.partReqID,
            requestedTime: row.requestedTime,
            issuedTime: row.issuedTime,
            approvedTime: row.approvedTime,
            photosUploaded: row.photosUploaded,
            Photos: [],
            oldPhoto: []
        }));
        if (result.success) {
            setState((st) => ({
                ...st,
                rows: Result,
                technician: result.data.technician,
                wipDetails: result.data.wipDetails[0],
                checkIssued: some(result.data.partReqs, (r) => r.qnIssued)
                // technicianSignature: result.data.partReqs[0]?.partReqTechnicianSignature,
                // partReqTechnicianSignature: result.data.partReqs[0]?.partReqTechnicianSignature
            }));
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const addPartToLocally = () => {
        if (state.quantity === null) {
            showToast('Quantity is required');
        } else {
            let newPartToAdd = {
                partReqPartDescription: state.part,
                partReqQuantity: state.quantity,
                qnIssued: '-',
                isDeleted: +state.quantity === 0,
                ...(state.partId && { partReqId: state.partId || null })
            };
            setState((st) => ({
                ...st,
                rows: state.rows.filter((item) => item.id !== state.partId)
            }));
            setState((st) => {
                newPartToAdd.id = st.rows.length === 0 ? 1 : Math.max(...st.rows.map((c) => c.id)) + 1;
                return {
                    ...st,
                    rows: [...st.rows, newPartToAdd],
                    part: '',
                    quantity: '',
                    isUpdate: false,
                    partId: undefined
                };
            });
        }
    };

    const validations = (mode) => {
        const { partReqTechnicianSignature } = state;
        let formIsValid = true;
        let errors = {};

        if (!partReqTechnicianSignature) {
            errors.partReqTechnicianSignature = 'Technician signature is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handelAddPartToServer = async (mode) => {
        if (isTechnician) {
            if (state.rows?.length > 0) {
                addPart(mode);
            } else {
                showToast('Please add least one part');
            }
        } else {
            addPart(mode);
        }
    };
    const addPart = async (mode) => {
        mode == 'save' ? setBtnLoader((st) => ({ ...st, btn2: true })) : setBtnLoader((st) => ({ ...st, btn1: true }));
        const data = {
            partReqTechnicianSignature: state.partReqTechnicianSignature,
            partReqsAddVM: state.rows.map((c) => ({
                partReqQuantity: c.partReqQuantity,
                partReqPartDescription: c.partReqPartDescription,
                isDeleted: c.isDeleted,
                partReqId: c.partReqId
            })),
            mode: mode
        };
        let res1 = await postPhotosByPartReqID(state.rows);
        let res = await addPartReq(Id, data, state.technicianNotesJobID);
        if (!res.success) {
            console.error(res);
        }
        showToast(res.message);
        if (res.success && fromStep) {
            mode == 'save' ? setBtnLoader((st) => ({ ...st, btn2: false })) : setBtnLoader((st) => ({ ...st, btn1: false }));
            setTimeout(() => {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }, 1000);
        }
    };

    const showConfirmationModal = () => {
        if (validations()) {
            setState((st) => ({ ...st, showConfirmModal: true }));
        }
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const signChange = (e) => {
        setState((st) => {
            return { ...st, ...e };
        });
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            partReqTechnicianSignature: null
        }));
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No parts available</div>
            </GridOverlay>
        );
    }

    const gridheight = `calc(100vh - ${isTechnician ? '348px' : '274px'})`;

    const inputFile = useRef(null);

    const upload = async (e) => {
        const files = Array.from(e.target.files);

        files.map((file) => {
            const reader = new FileReader();
            reader.readAsText(file);

            reader.onload = () => {
                const res = reader.result.split('\n');
                res.splice(0, 1);

                res.map((row) => {
                    const parts = row.split('|');

                    const partDesc = parts[0] + ' - ' + parts[1] + ' - ' + parts[5];
                    const quantity = parts[2];

                    if (row == '' || quantity == '') return;

                    let newPartToAdd = {
                        partReqPartDescription: partDesc,
                        partReqQuantity: quantity % 1 == 0 ? Math.trunc(quantity) : quantity,
                        qnIssued: '-'
                    };

                    setState((st) => {
                        newPartToAdd.id = st.rows.length === 0 ? 1 : Math.max(...st.rows.map((c) => c.id)) + 1;
                        return {
                            ...st,
                            rows: [...st.rows, newPartToAdd],
                            part: '',
                            quantity: '',
                            isUpdate: false,
                            partId: undefined
                        };
                    });
                });
            };

            reader.onerror = () => {
                console.log('Error reading .xfr File');
            };
        });
    };

    const makeEmptyPhotos = (name) => {
        let changedArray = [...state.rows];
        let objIndex = state.rows.findIndex((x) => x.partReqId === state.partId);
        changedArray[objIndex][name] = [];
        setState((st) => ({ ...st, rows: changedArray }));
    };
    const setPhotos = (name) => {
        setState((st) => ({ ...st, name }));
    };

    return (
        <div style={{ maxWidth: '100%' }}>
            <GlobalCss />
            {!fromStep && (
                <Breadcrumbs separator=">>" aria-label="breadcrumb">
                    <Link color="inherit" to={'/'}>
                        Technician App
                    </Link>
                    <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                        Part Requisitions
                    </Typography>
                </Breadcrumbs>
            )}
            <DialogTitles>
                <Grid container spacing={2}>
                    {state.technician.map((m) => (
                        <div className="tech-btn-group">
                            <Button
                                className={state.technicianNotesJobID == m.technicianJobID ? 'active-btn' : 'all-btn'}
                                onClick={() => TechBtnClick(m.technicianJobID)}
                            >
                                {m.technician}
                            </Button>
                        </div>
                    ))}
                </Grid>
            </DialogTitles>
            <DialogContents>
                <Grid container spacing={1}>
                    {data.showInfo && <PartReqOtherInfo data={data} onClose={() => setData((st) => ({ ...st, showInfo: false }))} />}
                    {state.showImage && (
                        <DialogComp title="Image Upload" onClose={() => onImagemodalClose(false)} fullWidth maxWidth="lg">
                            <PartReqPhotoModal
                                partId={state.partId}
                                onFormSubmit={() => pullWorkflowAndUpdateState()}
                                desc={state.desc}
                                onClose={onImagemodalClose}
                                onImageChange={setPhotos}
                                rows={state.rows}
                            />
                        </DialogComp>
                    )}
                    {state.imgPreview ? (
                        <DialogComp title="Signature" onClose={() => setImgPreview(false)} fullWidth maxWidth="md">
                            <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', bottom: 10, right: 0 }}>{state.sigDate}</div>
                                <img src={state.signature} alt={'stamp'} width="500" height="300" />
                            </div>
                        </DialogComp>
                    ) : null}
                    <Grid item xs={12} sm={8} style={{ fontSize: 21, textAlign: 'left' }}>
                        Registration Number:&nbsp; <b> {state.wipDetails?.registration}</b> &nbsp; WIP No:&nbsp; <b> {state.wipDetails?.wipNumber}</b>
                    </Grid>
                    {isTechnician ? (
                        <>
                            <Grid item xs={4} sm={2}>
                                <SecondaryButton isBtnLoader={btnLoader.btn2} onClick={() => handelAddPartToServer('save')} className="setFullWidth">
                                    Save
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <SecondaryButton isBtnLoader={btnLoader.btn1} onClick={showConfirmationModal} className="setFullWidth">
                                    Submit
                                </SecondaryButton>
                            </Grid>

                            <Grid item container spacing={1} alignItems="flex-end">
                                <Grid item xs={6} sm={6}>
                                    <FormLabel component="legend">Part</FormLabel>
                                    <TextBox onChange={fieldChange} value={state.part} name="part" autoFocus={true} />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <FormLabel component="legend">Quantity</FormLabel>
                                    <TextBox type="number" onChange={fieldChange} value={state.quantity} name="quantity" />
                                </Grid>
                                <Grid item xs={2} sm={2} className="add-btn-container">
                                    <SecondaryButton
                                        onClick={addPartToLocally}
                                        className="setFullWidth"
                                        disabled={state.isUpdate === false && state.quantity === '0'}
                                    >
                                        Add
                                    </SecondaryButton>
                                </Grid>
                                <Grid item xs={2} sm={2} className="add-btn-container">
                                    <input
                                        name="xfrFile"
                                        multiple
                                        type="file"
                                        onChange={(e) => upload(e)}
                                        style={{ display: 'none' }}
                                        accept=".xfr"
                                        ref={inputFile}
                                    />
                                    <SecondaryButton className="setFullWidth" onClick={() => inputFile.current.click()}>
                                        Import Shopping List
                                    </SecondaryButton>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                    <div
                        style={{
                            height: `${windowHeight < 400 ? '400px' : gridheight}`,
                            width: '100%'
                        }}
                    >
                        <DataGrid
                            className="data-grid-container"
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            headerHeight={50}
                            rowHeight={50}
                            hideFooter={true}
                            columns={new_columns}
                            rows={state.rows.filter((item) => item.isDeleted !== true)}
                            disableColumnMenu={true}
                            showColumnRightBorder={true}
                            pageSize={100}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} style={{ marginTop: 8 }}>
                    <CustomeSignPad
                        onChange={signChange}
                        clearSig={clearSig}
                        content={'Requestor Signature'}
                        sign={state.technicianSignature}
                        name="partReqTechnicianSignature"
                    />
                    <span className="mandatoryfields">{state.errors['partReqTechnicianSignature']}</span>
                </Grid>
            </DialogContents>
            {state.showConfirmModal ? (
                <DialogComp title="Part Requisitions" onClose={() => setState((st) => ({ ...st, showConfirmModal: false }))} maxWidth="sm">
                    <ConfirmationModal
                        message={`By submitting you will not be able to make any further changes to the form. If you need to make further changes please click cancel and select the save option.`}
                        handleSubmit={() => handelAddPartToServer('submit')}
                        handleCancel={() => setState((st) => ({ ...st, showConfirmModal: false }))}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default PartRequisitionsModal;
