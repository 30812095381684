import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { TextBox } from '../../../../../Core/FormInput';
import { ExpBaseURL } from '../../../../../Core/Service/http-calls';
import { AppStorage } from '../../../../../Core/Service/storage-service';
import formatters from '../../../../../Core/Grid/inputFormatter';

const nominalColumns = [
    { title: 'Technician', field: 'rn', width: 100, sortable: true },
    { title: 'Total Idle Hours', field: 'totalIdleHours', width: 100, sortable: true, align: 'right' },
    { title: 'Productive Hours', field: 'productiveHours', width: 90, sortable: true, align: 'right' },
    { title: 'Non Productive Hours', field: 'nonProductiveHours', width: 100, align: 'right', sortable: true }
];

const baseColumns = [
    { field: 'bn', title: 'Branch' },
    { field: 'totalIdleHours', title: 'Total Idle Hours', align: 'right' },
    { field: 'productiveHours', title: 'Productive Hours', align: 'right' },
    { field: 'nonProductiveHours', title: 'Non Productive Hours', align: 'right' }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Services', active: true },
    { name: 'Idle Time Analysis', active: true }
];

var $ = window.$;
var mainGrid, opGrid;

const IdleTimeAnalysis = (props) => {
    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate
    });
    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    let token = AppStorage.getToken();
    const onSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        mainGrid.render(obj);
    };

    const onSuccessOperatorFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        opGrid.render(obj);
    };
    const PullSalesNominalData = async () => {
        window.$(`#IdleTimeAnalysis`).grid('destroy', true, true);
        mainGrid = $('#IdleTimeAnalysis').grid({
            primaryKey: 'branchID',
            dataSource: {
                url: `${ExpBaseURL}/api/services/IdleTimeAnalysis`,
                data: { StartDate: state.startDate, EndDate: state.endDate },
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: onSuccessFunc
            },
            detailTemplate: '<div><table/></div>',
            headerFilter: false,
            columns: baseColumns,
            pager: { limit: 10 }
        });
        mainGrid.on('dataBound', function (e, records, totalRecords) {
            var sumIdleHours = 0,
                sumProductiveHours = 0,
                sumNonProductiveHours = 0,
                $tfoot;
            $.each(records, function () {
                sumIdleHours += parseFloat(this.totalIdleHours);
                sumProductiveHours += parseFloat(this.productiveHours);
                sumNonProductiveHours += parseFloat(this.nonProductiveHours);
            });
            $tfoot = mainGrid.children('tfoot');
            $tfoot.children('tr[data-role="TotalAmount"]').remove();
            $tfoot.children('tr[data-role="pager"]').remove();
            $tfoot.prepend(
                '<tr data-role="TotalAmount"><th></th><th>Total</th><th class="text-right" >' +
                    formatters.ToFixedSeparator(sumIdleHours) +
                    '</th><th class="text-right" >' +
                    formatters.ToFixedSeparator(sumProductiveHours) +
                    '</th><th class="text-right" >' +
                    formatters.ToFixedSeparator(sumNonProductiveHours) +
                    '</th></tr>'
            );
        });
        mainGrid.on('detailExpand', function (e, $detailWrapper, branchID) {
            opGrid = $detailWrapper.find('table').grid({
                params: { branchID: branchID },
                headerFilter: true,
                primaryKey: 'dn',
                dataSource: {
                    url: `${ExpBaseURL}/api/services/IdleTimeAnalysis`,
                    headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                    data: { StartDate: state.startDate, EndDate: state.endDate },
                    success: onSuccessOperatorFunc
                },
                columns: nominalColumns
            });
        });

        mainGrid.on('detailCollapse', function (e, $detailWrapper, id) {
            $detailWrapper.find('table').grid('destroy', true, true);
        });
        mainGrid.reload();
    };
    useEffect(() => {
        PullSalesNominalData();
    }, [state.startDate, state.endDate, props.type]);
    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <Grid item container spacing={1} xs={12} style={{ padding: '0px 10px' }}>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'left' }}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'left' }}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="report-screen-gijgo">
                    <Grid item xs={12}>
                        <table id={`IdleTimeAnalysis`}></table>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
export default IdleTimeAnalysis;
