import { FormLabel, Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { SketchPicker } from 'react-color';
import AppContext from '../../../../App/AppContext';
import { DefaultButton, SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { addUpdateResultListOption } from '../../../../Core/Service/qc-service';

const EditResultOption = (props) => {
    const [state, setState] = useState({
        QCResultOptionID: props?.focusedRow?.qcResultOptionID ? props?.focusedRow?.qcResultOptionID : null,
        QCResultOptionDescription: props?.focusedRow?.qcResultOptionDescription ? props?.focusedRow?.qcResultOptionDescription : '',
        QCResultOptionActive: props?.focusedRow?.qcResultOptionActive ? props?.focusedRow?.qcResultOptionActive : true,
        QCResultOptionBackgroundColour: props?.focusedRow?.qcResultOptionBackgroundColour ? props?.focusedRow?.qcResultOptionBackgroundColour : '#ff0000',
        QCResultOptionForegroundColour: props?.focusedRow?.qcResultOptionForegroundColour ? props?.focusedRow?.qcResultOptionForegroundColour : '#ff0000',

        errors: [],
    });

    const { showToast } = useContext(AppContext);

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        setState((st) => ({
            ...st,
            [name]: val
        }))
    }

    const validations = () => {
        const {
            QCResultOptionDescription
        } = state;
        let formIsValid = true;
        let errors = {};

        if (QCResultOptionDescription == null || QCResultOptionDescription == '') {
            errors.QCResultOptionDescription = 'Description field is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };
    
    const handleSubmit = async () => {
        if(validations()) {
            const data = {
                "QCResultOptionID": state.QCResultOptionID ? state.QCResultOptionID : null,
                "QCResultOptionDescription": state.QCResultOptionDescription,
                "QCResultOptionActive": state.QCResultOptionActive ? state.QCResultOptionActive : true,
                "QCResultOptionListID": props.QCResultListID,
                "QCResultOptionBackgroundColour": state.QCResultOptionBackgroundColour ? state.QCResultOptionBackgroundColour : '',
                "QCResultOptionForegroundColour": state.QCResultOptionForegroundColour ? state.QCResultOptionForegroundColour : '',
            }
            let res = await addUpdateResultListOption(data);
            if (!res.success) {
                console.error(res);
                showToast("Error occured.")
            } else {
                showToast("QC Results List option updated successfully")
                props.onClose()
            }
        }
    }

    const handleClick = (compon, val) => {
        setState((st) => ({
            ...st,
            [compon]: val
        }))
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel required component="legend">
                        Results List Option Description
                    </FormLabel>
                    <TextBox value={state.QCResultOptionDescription} name="QCResultOptionDescription" onChange={handleFieldChange} />
                    <span className="mandatoryfields">{state.errors['QCResultOptionDescription']}</span>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ paddingBottom: '25px' }}>
                        <FormLabel component="legend">Background Colour</FormLabel>
                    </div>
                    <div>
                        <SketchPicker
                            color={state.QCResultOptionBackgroundColour}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    QCResultOptionBackgroundColour: updatedColor.hex
                                }))
                            }
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ paddingBottom: '25px' }}>
                        <FormLabel component="legend">Foreground Colour</FormLabel>
                    </div>
                    <div>
                        <SketchPicker
                            color={state.QCResultOptionForegroundColour}
                            onChange={(updatedColor) =>
                                setState((state) => ({
                                    ...state,
                                    QCResultOptionForegroundColour: updatedColor.hex
                                }))
                            }
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={2} sm={2}>
                            <FormLabel
                                component="legend"
                                className="btn_add"
                            >
                                Is Active?
                            </FormLabel>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <DefaultButton
                                className={
                                    state.QCResultOptionActive ? "btnActive" : "btninActive"
                                }
                                value={state.QCResultOptionActive}
                                name="QCResultOptionActive"
                                onClick={() =>
                                    handleClick("QCResultOptionActive", true)
                                }
                            >
                                Yes
                            </DefaultButton>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <DefaultButton
                                className={
                                    state.QCResultOptionActive ? "btninActive" : "btnActiveNo"
                                }
                                value={state.QCResultOptionActive}
                                name="QCResultOptionActive"
                                onClick={() =>
                                    handleClick("QCResultOptionActive", false)
                                }
                            >
                                No
                            </DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
export default EditResultOption;
