import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ForumIcon from '@material-ui/icons/Forum';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import HomeIcon from '@material-ui/icons/Home';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PaymentIcon from '@material-ui/icons/Payment';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import SendIcon from '@material-ui/icons/Send';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

export default [
    {
        label: 'Dashboard',
        path: '/',
        exact: true,
        component: Components.CustCommonDash,
        authType: TypeOfAuth.Auth,
        icon: <HomeIcon />
    },
    {
        label: 'eWIP',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <LoyaltyIcon />,
        children: [
            {
                label: 'Dashboard',
                path: '/cust_dashboard',
                exact: true,
                component: Components.CustDashboard,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Videos',
                path: '/Lists/Videos',
                exact: true,
                component: Components.Videos,
                authType: TypeOfAuth.Auth,
                icon: <VideoLibraryIcon />
            }
        ]
    },

    {
        label: 'Invoices',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ReceiptIcon />,
        children: [
            // {
            //     label: 'Customers',
            //     exact: true,
            //     path: '/invoice/Customers',
            //     component: () => <Components.InvoiceCustomersListScreen />,
            //     authType: TypeOfAuth.Auth
            // },
            {
                label: 'Dashboard',
                exact: true,
                path: '/invoice/dashboard',
                component: () => <Components.InvoicesDashboard />,
                authType: TypeOfAuth.Auth,
                icon: <HomeIcon />
            },
            {
                label: 'All Invoices',
                exact: true,
                path: '/invoice/allInvoices',
                component: () => <Components.InvoiceScreens />,
                authType: TypeOfAuth.Auth,
                icon: <FileCopyIcon />
            },
            {
                label: 'Invoices Outstanding',
                exact: true,
                path: '/invoice/Invoice_Outstanding',
                component: () => <Components.InvoiceScreens status={1} />,
                authType: TypeOfAuth.Auth,
                icon: <ReceiptIcon />
            },
            {
                label: 'Invoices Paid',
                exact: true,
                path: '/invoice/Invoice_Paid',
                component: () => <Components.InvoiceScreens status={3} />,
                authType: TypeOfAuth.Auth,
                icon: <PaymentIcon />
            },
            {
                label: 'Invoices Queried',
                exact: true,
                path: '/invoice/Invoice_Queried',
                component: () => <Components.InvoiceScreens status={2} />,
                authType: TypeOfAuth.Auth,
                icon: <RemoveFromQueueIcon />
            },
            {
                label: 'Messages Sent',
                exact: true,
                path: '/invoice/messagesSent',
                component: () => <Components.InvoiceScreens status={-2} />,
                authType: TypeOfAuth.Auth,
                icon: <RateReviewIcon />
            },
            {
                label: 'Messages Received',
                exact: true,
                path: '/invoice/messagesReceived',
                component: () => <Components.InvoiceScreens status={-1} />,
                authType: TypeOfAuth.Auth,
                icon: <SpeakerNotesIcon />
            }
        ]
    },
    {
        label: 'Customer',
        path: '/Customer/:name/:id',
        exact: true,
        hidden: true,
        component: Components.JobDetails,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Workflow Job',
        hidden: true,
        exact: true,
        path: '/job/:jobId/:workflowId',
        component: Components.workflowJobDetails,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Logout',
        path: '/logout',
        hidden: true,
        component: Components.logout,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Communicator',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ForumIcon />,
        children: [
            {
                label: 'Dashboard',
                path: '/communicator_dash',
                exact: true,
                component: Components.CustCommunicatorDash,
                authType: TypeOfAuth.Auth,
                icon: <HomeIcon />
            },
            {
                label: 'Site Vehicles',
                path: '/SiteVehicles',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.CustSiteVehicles status="S" />,
                icon: <EmojiTransportationIcon />
            },
            {
                label: 'VOR Vehicles',
                path: '/VORVehicles',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.CustSiteVehicles status="VOR" />,
                icon: <DepartureBoardIcon />
            },
            {
                label: 'Awaiting Authority',
                path: '/AwaitingAuthority',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: Components.CustAwaitingAuthority,
                icon: <EmojiTransportationIcon />
            },
            {
                label: 'Jobs Approved',
                path: '/JobsApproved',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.CustSiteVehicles status="A" />,
                icon: <DepartureBoardIcon />
            },
            {
                label: 'Bookings',
                path: '/bookings',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: Components.CustBookings,
                icon: <BookmarksIcon />
            }
        ]
    }
];
