import { createContext, useEffect, useState, useRef, useContext } from 'react';
import AppContext from '../../../../App/AppContext';
import { getBrakeReport, postBrakeReport } from '../../../../Core/Service/BrakeDiscPadWear-service';
import { AppStorage } from '../../../../Core/Service/storage-service';
import formatters from '../../../../Core/Grid/inputFormatter';
import SignaturePad from 'react-signature-canvas';

export const BrakeDiscPadScreenContext = createContext();

export const BrakeDiscPadProvider = (props) => {
    const { showToast } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const fromStep = urlParams.get('fromstep') === 'true';
    const navigateToDashboard = urlParams.get('navigate') === 'true';

    const handleValidation = () => {
        let errors = {};
        const list = [...inputList];
        let formIsValid = true;
        if (list[0]['brakeReportItemAxlePosition'] === '') {
            formIsValid = false;
            errors['brakeReportItemAxlePosition'] = 'Axel position cannot be empty';
        }
        if (list[0]['brakeReportItemNearSideBrakePadWear'] === '') {
            formIsValid = false;
            errors['brakeReportItemNearSideBrakePadWear'] = 'Brake pad wear cannot be empty';
        }
        if (list[0]['brakeReportItemNearSideBrakeDiscThickness'] === '') {
            formIsValid = false;
            errors['brakeReportItemNearSideBrakeDiscThickness'] = 'Thickness cannot be empty';
        }
        if (list[0]['brakeReportItemNearSideBrakeDiscThicknessLimit'] === '') {
            formIsValid = false;
            errors['brakeReportItemNearSideBrakeDiscThicknessLimit'] = 'Limit cannot be empty';
        }
        if (list[0]['brakeReportItemNearSideReasonForRenewal'] === '') {
            formIsValid = false;
            errors['brakeReportItemNearSideReasonForRenewal'] = 'Reason for renewal cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const DatendSignValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (state.brakeReportDate === 'Invalid date' || !state.brakeReportDate) {
            formIsValid = false;
            errors['brakeReportDate'] = 'Date cannot be empty';
        }
        if (!state.techniciansign) {
            formIsValid = false;
            errors['techniciansign'] = 'Signature cannot be empty';
        }
        setState((st) => ({
            ...st,
            DatendSign: errors
        }));
        return formIsValid;
    };
    let data = {
        brakeReportItemNearSideBrakePadWear: '',
        brakeReportItemAxlePosition: '',
        brakeReportItemNearSideBrakeDiscThickness: '',
        brakeReportItemNearSideBrakeDiscThicknessLimit: '',
        brakeReportItemNearSidePhoto: null,
        brakeReportItemNearSideReasonForRenewal: '',
        brakeReportItemOffSideBrakePadWear: '',
        brakeReportItemOffSideBrakeDiscThickness: '',
        brakeReportItemOffSideBrakeDiscThicknessLimit: '',
        brakeReportItemOffSideReasonForRenewal: '',
        brakeReportItemOffSidePhoto: null,
        brakeReportItemID: null
    };
   
   // const signCanvas = useRef({});

    const [inputList, setInputList] = useState([{ ...data }, { ...data }, { ...data }]);

    const [state, setState] = useState({
        showAdd: true,
        DatendSign: {},
        headerRecords: {},
        brakeReportID: null,
        fields: {},
        errors: {},
        brakeReportDate: null,
        hideSign: null,
        brakeReportHeaderID: props.match.params?.jobId,
        showLogo: false,
        logoValue: null,
        index: null,
        logoName: null,
        axelPosition: '',
        showCircularLoader: true,
        brakeReportDateCreated: null,
        techncianName: null,
    });

    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        if (state.brakeReportHeaderID) {
            let res = await getBrakeReport(state.brakeReportHeaderID);
            if (res.success) {
                const updatedUser = res?.data;
                setInputList(
                    !updatedUser.brakeReportItems.length
                        ? [
                              { ...data },
                              { ...data },
                              { ...data }
                          ]
                        : updatedUser.brakeReportItems.map((c, i) => ({ ...c, id: i + 1 }))
                );

                let brakeReports = res?.data?.brakeReports[0];
                setState((st) => ({
                    ...st,
                    brakeReportID: brakeReports?.brakeReportID || null,
                    brakeReportDate: formatters.DateFormatter(brakeReports?.brakeReportDate),
                    techniciansign: brakeReports?.brakeReportSignature,
                    hideSign: brakeReports?.brakeReportSignature,
                    brakeReportDateCreated: brakeReports?.brakeReportDateCreated,
                    techncianName: brakeReports?.techncianName,
                    headerRecords: res.data?.headerRecords[0],
                    // showAdd: updatedUser.brakeReportItems.length > 0 ? false : true,
                    showAdd: true,
                    showCircularLoader:false
                }));
            }
        }
    }, []);

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index]['id'] = index + 1;
        list[index][name] = value;
        setInputList(list);
    };

    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    let handleImage = (event, i) => {
        const { name } = event.target;
        let reader = new FileReader();
        const list = [...inputList];
        reader.onload = (e) => {
            const { result } = e.target;
            list[i][name] = result;
            setInputList(list);
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    let RemoveImage = () => {
        const list = [...inputList];
        list[state.index][state.logoName] = null;
        setInputList(list);
        setState((st) => ({
            ...st,
            logoValue:null,
        }))
    };

    let UploadNewImage = (val) => {
        const list = [...inputList];
        list[state.index][state.logoName] = val;
        setInputList(list);
        setState((st) => ({
            ...st,
            logoValue:val,
        }))
    }

    const showImage = (i,name) => {
        const list = [...inputList];
        setState((st) => ({
            ...st,
            showLogo: true,
            logoValue: list[i][name],
            axelPosition: list[i]['brakeReportItemAxlePosition'],
            index: i,
            logoName: name

        }));
    };

    const hideImage = () => {
        setState((st) => ({
            ...st,
            showLogo: false,
            logoValue: null,
            index: null,
            logoName: null
        }));
    };

    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                brakeReportItemID: null,
                brakeReportItemNearSideBrakePadWear: '',
                brakeReportItemAxlePosition: '',
                brakeReportItemNearSideBrakeDiscThickness: '',
                brakeReportItemNearSideBrakeDiscThicknessLimit: '',
                brakeReportItemNearSidePhoto: null,
                brakeReportItemNearSideReasonForRenewal: '',
                brakeReportItemOffSideBrakePadWear: '',
                brakeReportItemOffSideBrakeDiscThickness: '',
                brakeReportItemOffSideBrakeDiscThicknessLimit: '',
                brakeReportItemOffSidePhoto: null,
                brakeReportItemOffSideReasonForRenewal: ''
            }
        ]);
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            techniciansign: null
        }));
    };

    const signChange = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    }; 

    const handleSubmit = async () => {
        setBtnLoader(true)
        let list = [];
        let temp = inputList.filter((m) => {
                delete m['id'];
                list.push(m);
        });
        const obj = {
            brakeReportItems: list,
            brakeReportID: state.brakeReportID,
            brakeReportHeaderID: state.brakeReportHeaderID,
            brakeReportDate: state.brakeReportDate,
            brakeReportSignature: state.techniciansign
        };
        if (handleValidation()) {
            if (DatendSignValidation()) {
                let res = await postBrakeReport(obj);
                if (res.success) {
                    showToast(res.message);
                    if (fromStep) {
                        setTimeout(() => {
                            window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                        }, 1000);
                    }
                } else {
                    showToast(res.error);
                    setBtnLoader(false)
                }
            }
        }
    };

    return (
        <BrakeDiscPadScreenContext.Provider
            value={{
                state,
                inputList,
                handleInputChange,
                handleRemoveClick,
                handleAddClick,
                fieldChange,
                handleSubmit,
                handleImage,
                showImage,
                hideImage,
                RemoveImage,
                UploadNewImage,
                signChange,
                clearSig,
                btnLoader
            }}
        >
            {props.children}
        </BrakeDiscPadScreenContext.Provider>
    );
};