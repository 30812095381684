import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel, Breadcrumbs } from '@material-ui/core';
import { TextBox } from './../../../../../Core/FormInput';
import { Link } from 'react-router-dom';
import NestedGijgoGrid from './../../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import BreadCrumbs from './../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import CommonGijgoGrid from './../../../../../Core/Controls/GijgoGrid/index';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import DialogComp from '../../../../../Core/Modal/dialogModal';
import { useState } from 'react';
import MotHistoryScreen from '../../MotHistoryScreen';
import ReactDOM from 'react-dom';

var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};

const baseColumns = [
    { title: 'Reg Number', field: 'rn', width: 100, sortable: true },
    {
        title: 'Vehicle Description',
        field: 'vd',
        width: 250,
        sortable: true,

        cssClass: 'text-center'
    },
    { title: 'VSB No', field: 'sn', width: 80, sortable: true },
    {
        title: 'Invoice Date',
        field: 'ivd',
        width: 100,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'In Stock',
        field: 'isd',
        width: 100,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'RegDate',
        field: 'rd',
        width: 100,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Mileage',
        field: 'm',
        width: 80,

        sortable: true
    },
    { title: 'Status', field: 'acs', width: 50, sortable: true },
    { title: 'Q', field: 'qv', width: 50, sortable: true },

    {
        title: 'VAT Price',
        field: 'va',
        width: 90,
        sortable: true,

        renderer: function (value, record) {
            return addCommas(value);
        }
    },

    {
        title: 'Recon',
        field: 'rc',
        width: 90,
        sortable: true
    },
    {
        title: 'Total',
        field: 'total',
        width: 90,
        sortable: true,

        renderer: function (value, record) {
            return addCommas(value);
        }
    },
    {
        title: 'Age',
        field: 'age',
        width: 90,
        sortable: true
    },

    {
        title: 'Model Code',
        field: 'mdc',
        width: 90,
        sortable: true
    },
    {
        title: 'Franchise',
        field: 'fn',
        width: 70,
        sortable: true
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Used Vehicle Stock', active: true }
];

const UsedVehicleStock = (props) => {
    const [state, setState] = useState({ showMotHistory: false });

    const regNumberRendrer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showMotHistory: true, regNo: record?.rn, FranchiseCode: record?.franchiseCode }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        const tempCols = [...baseColumns];

        let regNumberCol = tempCols.find((element) => element.field === 'rn');

        if (regNumberCol) {
            regNumberCol.renderer = regNumberRendrer({});
        }

        return tempCols;
    }, []);

    const baseUrl = `Sales/UsedVehicleStock`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container>
                <Grid container>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        displayPagination={true}
                        // detailTemplate={detailTemplate}
                    />
                </Grid>
            </Grid>
            {state.showMotHistory && (
                <DialogComp title="MOT History" onClose={() => setState((st) => ({ ...st, showMotHistory: false }))} maxWidth="md" fullWidth overflow={'auto'}>
                    <MotHistoryScreen regNo={state.regNo} FranchiseCode={state.FranchiseCode} hideSeachText={true} />
                </DialogComp>
            )}
        </div>
    );
};

export default UsedVehicleStock;
