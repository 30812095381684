import { Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { SecondaryButton } from '../../../../Core/FormInput';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
import DialogComp from '../../../../Core/Modal/dialogModal';
import ReportGijgoGrid from '../../../internal/ReportsScreens/GijgoForReportsScreen';
import EditResultOption from './editResultOption';

const ResultListOptions = (props) => {
    const [state, setState] = useState({
        QCResultListID: props.QCResultListID,
        showAddEdit: false,

        focusedRow: null,
    });

    useEffect(async () => {

    }, []);

    const handleAddEdit = (val) => {
        if (val) {
            setState((st) => ({
                ...st,
                showAddEdit: val,
                isReload: false
            }))
        } else {
            setState((st) => ({
                ...st,
                showAddEdit: val,
                focusedRow: null,
                isReload: true,
            }))
        }
    }

    const baseUrl = `QC/Options/${state.QCResultListID}`;

    const baseColumns = [
        {
            field: 'action',
            sortable: false,
            title: `Action`,
            width: '3%',
            filterable: false
        },
        {
            field: 'qcResultOptionID',
            title: `ID`,
            hidden: true
        },
        {
            field: 'qcResultOptionDescription',
            title: `Description`,
            width: '30%'
        },
        {
            field: 'qcResultOptionBackgroundColour',
            title: `Foreground`,
            width: '15%'
        },
        {
            field: 'qcResultOptionForegroundColour',
            title: `Background`,
            width: '15%'
        },
        {
            field: 'qcResultOptionActive',
            title: `Active`,
            width: '10%'
        },
    ];

    const crumbs = () => [
        { name: 'Home', path: '/' },
        { name: 'QC', active: true },
        { name: `Results List Options`, active: true }
    ];

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent='space-evenly'>
                <SquareButton
                    style={{
                        backgroundColor: '#183B68',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => {
                        handleAddEdit(true)
                        setState((st) => ({
                            ...st,
                            focusedRow: record
                        }))
                    }}
                >
                    <Edit fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');

        if (actionCol) {
            actionCol.renderer = editButton({});
        }

        return tempCols;
    }, []);

    return (
        <div>
            <Grid item container justify="space-between">
                <Grid item>
                    <BreadCrumbs crumbs={crumbs()} />
                </Grid>
                <Grid item style={{ marginTop: '15px' }}>
                    <SecondaryButton
                        variant="contained"
                        color="secondary"
                        onClick={() => handleAddEdit(true)}
                        className="btnadd"
                    >
                        Add Results List Option
                    </SecondaryButton>
                </Grid>
            </Grid>

            <Grid item container spacing={1} xs={12} style={{ marginTop: '-25px' }}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isReload={state.isReload}
                    />
                </Grid>
            </Grid>

            {state.showAddEdit ? (
                <DialogComp title={state.focusedRow ? "Edit QC Results Option" : "Add QC Results Option"} maxWidth="sm" onClose={() => handleAddEdit(false)} fullWidth>
                    <EditResultOption focusedRow={state?.focusedRow} QCResultListID={state.QCResultListID} onClose={() => handleAddEdit(false)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default ResultListOptions;
