import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AppStorage } from './../../../Core/Service/storage-service';
import { IconButton, Tooltip } from '@material-ui/core';
import { ArrowForwardIos, PeopleOutline, Reply } from '@material-ui/icons';
import { ExpBaseURL } from './../../../Core/Service/http-calls';
import AssignToAuditor from './AssignToAuditor';
import './ScreenStyle.scss';
import AppContext from '../../../App/AppContext';
import ReturnToWorkflow from '../workflow-job/ReturnToWorkflow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { GetArchivedJobsExcelFile } from '../../../Core/Service/archivedJobs-service';
var grid1;

const ArchivedJobs = (props) => {
    const [state, setState] = useState({
        jobAssignAuditorsTo: false,
        showReturnReason: false,
        workflowID: null,
        jobId: null
    });

    const { canAssignJobsToAuditor, showToast } = useContext(AppContext);
    const token = AppStorage.getToken();

    useEffect(async () => {
        if (token) {
            pullArchivedJobsAndUpdateState();
        } else {
            window.location = '/';
            AppStorage.performLogout();
        }
    }, [token]);

    const assignJob = (job) => {
        setState((st) => ({ ...st, jobAssignAuditorsTo: job }));
    };
    const handelReturnReason = (job) => {
        setState((st) => ({ ...st, showReturnReason: true, workflowID: job.workflowID, jobId: job.id }));
    };
    const hideAssignAuditor = (res) => {
        setState((st) => ({ ...st, jobAssignAuditorsTo: undefined, showReturnReason: false }));
        res && pullArchivedJobsAndUpdateState();
    };

    const pullArchivedJobsAndUpdateState = async () => {
        const onSuccessFunc = function (response) {
            var obj = {
                records: response.data.rows,
                total: response.data.total
            };
            grid1.render(obj);
        };

        const editButton = (vl, job) => {
            const spn = document.createElement('span');
            const lnk = (
                <>
                    <a href={`/Job/${vl}/2`} >
                        <IconButton size="small">
                            <ArrowForwardIos fontSize="small" />
                        </IconButton>
                    </a>
                    {canAssignJobsToAuditor && (
                        <Tooltip title="assign Auditors to Job">
                            <IconButton size="small" onClick={() => assignJob(job)}>
                                <PeopleOutline fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Return">
                        <IconButton size="small" onClick={() => handelReturnReason(job)}>
                            <Reply fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            );
            ReactDOM.render(lnk, spn);
            return spn;
        };
        grid1 = window.$('#grid').grid({
            primaryKey: 'Id',
            dataSource: {
                url: `${ExpBaseURL}/api/ArchivedJobs`,
                headers: { Authorization: `Bearer ${token}` },
                success: onSuccessFunc
            },
            headerFilter: true,
            columns: [
                {
                    title: 'Action',
                    field: 'id',
                    renderer: editButton,
                    width: canAssignJobsToAuditor ? 70 : 50,
                    filterable: false
                },
                {
                    title: 'WIP Number',
                    field: 'wipNo',
                    width: 70,
                    sortable: true
                },
                {
                    title: 'Vehicle Reg',
                    field: 'registration',
                    width: 70,
                    sortable: true
                },
                {
                    title: 'Model',
                    field: 'makeModel',
                    width: 100,
                    sortable: true
                },
                {
                    title: 'Customer Name',
                    field: 'customerName',
                    width: 90,
                    sortable: true
                },
                {
                    title: 'Chassis Number',
                    field: 'chassisNumber',
                    width: 100,
                    sortable: true
                },
                {
                    title: 'Date Due In',
                    field: 'dateDueIn',
                    width: 90,
                    sortable: false,
                    type: 'date',
                    cssClass: 'text-center',
                    renderer: function (value, record) {
                        if (record.dateDueIn != null) {
                            return moment(record.dateDueIn).format('DD/MM/YYYY');
                        }
                        return '';
                    }
                },
                {
                    title: 'Branch',
                    field: 'branchShortName',
                    width: 80,
                    sortable: true
                },
                {
                    title: 'Franchise',
                    field: 'franchiseName',
                    width: 100,
                    sortable: true
                },
                {
                    title: 'Account',
                    field: 'accountNo',
                    width: 60,
                    sortable: true
                }
            ],
            pager: { limit: 12 }
        });
        grid1.on('dataBound', function (e, records, totalRecords) {});
    };
    const downloadCSVWrapper = async () => {
        GetArchivedJobsExcelFile();
    };

    return (
        <div>
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Archived Jobs
                </Typography>
            </Breadcrumbs>

            <Grid container item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}>
                <Grid item style={{ bottom: '3px', left: '16px', zIndex: 99, position: 'absolute' }}>
                    <Tooltip title={`Download Excel`} arrow>
                        <IconButton aria-label="delete" style={{ padding: '0px' }}>
                            <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <table id={`grid`}></table>
            </Grid>
            {state.showReturnReason && (
                <ReturnToWorkflow
                    onClose={hideAssignAuditor}
                    onReturnProcessed={hideAssignAuditor}
                    workflowId={state.workflowID || 7}
                    showToast={showToast}
                    jobId={state.jobId}
                />
            )}
            {state.jobAssignAuditorsTo && <AssignToAuditor onClose={hideAssignAuditor} archivedJob={state.jobAssignAuditorsTo} />}
        </div>
    );
};
export default ArchivedJobs;
