import React, { useContext, useMemo, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getMenuForRole } from './_menu';
import AppbarComponent from './Appbar';
import AppContext from '../../App/AppContext';
import { WorkflowDash } from '../../Components';
import { AppStorage } from '../Service/storage-service';
export let Navbar = (props) => {
    const { loggedIn, userRoles, showTopNavAndMenu, tempMenus, portalSettings } = useContext(AppContext);
    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed();
    const isInvoiceAllowed = AppStorage.getInvoiceAllowed();
    const isReportingAllowed = AppStorage.getReportingAllowed();
    const isWIPAllowed = AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled;
    let menuPermissionCounter = isCommunicatorAllowed + isInvoiceAllowed + isReportingAllowed + isWIPAllowed;
    const currentMenus = useMemo(() => {
        let menus = getMenuForRole(userRoles, loggedIn);
        if (isWIPAllowed) {
            let dashMenus = [];
            if (tempMenus.menus.length > 0) {
                dashMenus = tempMenus.menus.map((x) => {
                    return {
                        label: `${x.label}`,
                        path: `/dashboard/${x.id}`,
                        exact: true,
                        authType: 1,
                        component: () => {
                            return <WorkflowDash workFlowId={x.id} />;
                        }
                    };
                });
            }
            if (menuPermissionCounter == 1) {
                let isDash = menus.findIndex((p) => p.isDynamicChildren === 'eWipDashboard');
                if (tempMenus.menus.length > 0 && isDash >= 0) {
                    menus[isDash].children = dashMenus;
                }
            } else {
                let iseWIPMenu = menus.findIndex((p) => p.label === 'eWIP');
                let isDash = menus[iseWIPMenu].children.findIndex((p) => p.isDynamicChildren === 'eWipDashboard');
                if (tempMenus.menus.length > 0 && isDash >= 0) {
                    menus[iseWIPMenu].children[isDash].children = dashMenus;
                }
            }
        }
        return menus;
    }, [loggedIn, tempMenus]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search.toLowerCase());
        const fromStep = urlParams.get('fromstep') === 'true';
        if (fromStep && !loggedIn) {
            window.top.postMessage({ type: 'trigger-logout' }, '*');
        }
    }, []);

    return (
        <>
            {showTopNavAndMenu && <AppbarComponent />}
            <div className="component-container">
                <Switch>
                    {currentMenus.map((m, i) => {
                        return m.children ? (
                            m.children.map((a) => {
                                return a.children ? (
                                    a.children.map((b) => {
                                        return <Route key={b.label} exact={b.exact} path={b.path} component={b.component}></Route>;
                                    })
                                ) : (
                                    <Route key={a.label} exact={a.exact} path={a.path} component={a.component}></Route>
                                );
                            })
                        ) : (
                            <Route key={m.label} exact={m.exact} path={m.path} component={m.component}></Route>
                        );
                    })}
                </Switch>
            </div>
        </>
    );
};
