import React from 'react';
import { moment } from 'moment';
import { Grid, FormLabel, Breadcrumbs } from '@material-ui/core';
import { TextBox } from './../../../../../Core/FormInput';
import { Link } from 'react-router-dom';
import NestedGijgoGrid from './../../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import BreadCrumbs from './../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import CommonGijgoGrid from './../../../../../Core/Controls/GijgoGrid/index';
import ReportGijgoGrid from '../../GijgoForReportsScreen';

var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};

const baseColumns = [
    { title: 'Department', field: 'rn', width: 90, sortable: true },
    {
        title: 'WIP No.',
        field: 'vd',
        width: 100,
        sortable: true,

        cssClass: 'text-center'
    },
    {
        title: 'Raised',
        field: 'ivd',
        width: 100,
        sortable: true,
        // align: 'center',
        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    { title: 'Account', field: 'sn', width: 100, sortable: true },
    {
        title: 'Phone No.',
        field: 'isd',
        width: 100,
        sortable: true,
        // align: 'center',
        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Part Total',
        field: 'va',
        width: 90,
        sortable: true,
        // align: 'right',
        renderer: function (value, record) {
            return addCommas(value);
        }
    },
    {
        title: 'Labour Total',
        field: 'lt',
        width: 90,
        sortable: true,
        align: 'right',
        renderer: function (value, record) {
            return addCommas(value);
        }
    },
    {
        title: 'Total',
        field: 'total',
        width: 90,
        sortable: true,
        align: 'right',
        renderer: function (value, record) {
            return addCommas(value);
        }
    },

    {
        title: 'Address',
        field: 'rd',
        width: 200,
        sortable: true,
        align: 'center'
    },
    {
        title: 'Code',
        field: 'mdc',
        width: 90,
        sortable: true,
        align: 'right'
    },
    {
        title: 'Franchise',
        field: 'm',
        width: 100,
        align: 'right',
        sortable: true
    },
    { title: 'Branch', field: 'acs', width: 100, align: 'center', sortable: true },
    { title: 'D', field: 'qv', width: 50, align: 'center', sortable: true }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Sales Contingency Debt', active: true }
];

const SalesContingencyDebt = (props) => {
    const baseUrl = `Sales/SalesContingencyDebt`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container>
                <Grid container>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={baseColumns}
                        getUrl={baseUrl}
                        displayPagination={true}
                        // detailTemplate={detailTemplate}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default SalesContingencyDebt;
