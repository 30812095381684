import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import './reportGijgoGrid.scss';
import ReactDOM from 'react-dom';

import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { AppStorage } from '../../../../Core/Service/storage-service';
import { ExpBaseURL } from '../../../../Core/Service/http-calls';
import formatters from '../../../../Core/Grid/inputFormatter';
import MailIcon from '@material-ui/icons/Mail';
import { SquareButton } from '../../../../Core/FormInput/AppButton';

var grid1;
var $ = window.$;

let addCommas = (val) => {
    if (val === '') {
        return '';
    } else {
        return val ? `£${val?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
    }
};

const ReportGijgoGrid = (props) => {
    let isAction = props.columns.find((c) => c.field === 'action');

    const useStyles = makeStyles((theme) => ({
        // gijgoGrid: {
        //     '& .gj-grid-md th:first-of-type': {
        //         // backgroundColor: 'red',
        //         padding: (props) => {
        //             return props.isAction ? '12px 5px 12px 5px' : '12px 15px 12px 10px';
        //         }
        //     }
        // }
    }));

    const classes = useStyles({ isAction: isAction });
    let token = AppStorage.getToken();
    const [state, setState] = useState({
        recordId: props.recordId,
        fileCount: `${props.fileCount || 0}`,
        configName: props.fileUploaderConfigName,
        columns: []
    });
    const [gridReload, setGridReload] = useState(new Date());

    const gridFileCountRefresh = () => {
        setGridReload((st) => ({ ...st, gridReload: new Date() }));
    };

    useEffect(() => {
        let finalColumns = [...props.columns].map((c) => {
            let tempObj = {
                ...c,
                // headerCssClass: 'gridHeader'
                align: c.align ? c.align : 'left'
            };
            let currencyRenderer = c.isCurrency;
            if (currencyRenderer) {
                tempObj.renderer = function (value, record) {
                    return '<span title="' + value + '"  >' + formatters.CurrencyThousandSeparator(value) + '</span>';
                };
            }
            return { ...tempObj };
        });
        setState((st) => ({ ...st, columns: finalColumns }));
    }, [props.columns, gridReload]);

    useEffect(async () => {
        if (state.columns.length > 0) {
            pullArchivedJobsAndUpdateState();
        }
    }, [state.columns, props.isReload, props.getUrl]);

    const pullArchivedJobsAndUpdateState = async () => {
        window.$(`#${'key1'}`).grid('destroy', true, true);

        const onSuccessFunc = function (response) {
            grid1.render(props.dataConverter(response));
        };

        grid1 = window.$(`#${'key1'}`).grid({
            primaryKey: 'Id',
            dataSource: {
                url: `${ExpBaseURL}/api/${props.getUrl}`,
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: onSuccessFunc
            },
            ...(props.minWidth !== null && { minWidth: 1350 }),
            ...(props.isGroupingId && { grouping: { groupBy: `${props.isGroupingId}` } }),
            // fixedHeader: true,
            // uiLibrary: 'bootstrap4',
            headerFilter: {
                type: 'onchange'
            },
            ...(props.selectionType && { selectionType: props.selectionType }),
            ...(props.selectionMethod && { selectionMethod: props.selectionMethod }),
            // selectionType: 'multiple',
            // selectionMethod: 'checkbox',
            ...(props.detailTemplate && { detailTemplate: props.detailTemplate() }),
            columns: state.columns,
            pager: {
                limit: 20
            }
        });

        grid1.on('dataBound', function (e, records, totalRecords) {
            let $tfoot = grid1.children('tfoot');
            let $thead = grid1.children('thead');
            if (props.hidePagination) {
                $tfoot.children('tr[data-role="pager"]').remove();
            }
            if (props.hideFilterRow) {
                $thead.children('tr[data-role="filter"]').remove();
            }
            if (props.isShowTotal) {
                let cal = state.columns.map((p) => {
                    if (p.showColumnTotal) {
                        let total = 0;
                        records.forEach((u) => {
                            total += +u[`${p.field}`];
                        });
                        return total;
                    } else {
                        return '';
                    }
                });
                $tfoot.children('tr[data-role="TotalAmount"]').remove();
                $tfoot.prepend(
                    `<tr data-role="TotalAmount" class="total_ft"> 
            ${cal.map((y, i) => {
                if (i == 0) {
                    return `<th style="text-align:left;font-weight:bold;">Total</th>`;
                }

                return `<th style="text-align:right;font-weight:bold;">${addCommas(y)}</th>`;
            })}
            </tr>`
                );
            }

            if (props.showCheckboxColFilterBtn) {
                //     var SendMulitpleInvoiceslink = `<a href="#SendMulipleInvoicesPopup" id="lnkSendSelected" style="top:0px" title="Email Invoices"
                // data-url="@Url.Action("SendInvoices", "Invoices", new { Area = "InternalUser" })">
                // <button>val</button>
                // </a>`;
                //     $('#key1').find('thead tr[data-role="filter"] > th:nth-child(1)').html(SendMulitpleInvoiceslink);
            }
            if (props.showCheckboxColFilterBtn) {
                $('#key1')
                    .find('thead tr[data-role="filter"] > th:nth-child(1)')
                    .html(() => props.showCheckboxColFilterBtn('#key1', records));
            }
        });

        grid1.on('rowDataBound', function (e, $row, id, record) {
            if (props.setRowStyleOnCondition) {
                let resColor = props.setRowStyleFunction(record);
                if (resColor) {
                    for (var key in resColor) {
                        $row.css(`${key}`, `${resColor[key]}`);
                    }
                }
            }
        });

        grid1.on('cellDataBound', function (e, $displayEl, id, column, record) {
            if (props.setColStyleOnCondition) {
                let resColor = props.setColStyleFunction(record);
                if (resColor) {
                    for (var key in resColor) {
                        $displayEl.css(`${key}`, `${resColor[key]}`);
                    }
                }
            }
        });

        // grid1.on('rowSelect', function (e, $row, id, record) {
        //     console.log(record, 'record');
        //     console.log($row, '$row');
        // });

        // grid1.on('rowSelect', function (e, $row, id, record) {
        //     console.log(record, 'record');
        //     // $('#key1')
        //     //     .find('thead tr[data-role="filter"] > th:nth-child(1)')
        //     //     .html(() => props.showCheckboxColFilterBtn(record));
        // });
        grid1.reload();
    };

    const downloadCSVWrapper = () => {
        grid1?.downloadCSV(`${props.downloadBtnName}-${moment().format('DD-MMM-YYYY HH:mm')}.csv`);
    };
    return (
        <Grid
            container
            // style={{ position: 'relative' }}
        >
            <Grid container className="report-screen-gijgo " item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}>
                {props.downloadBtnName && (
                    <Grid
                        item
                        // style={{ top: '23px', left: '18px', zIndex: 99, position: 'absolute' }}
                        style={{ bottom: '3px', left: '16px', zIndex: 99, position: 'absolute' }}
                    >
                        <Tooltip title="Download CSV" arrow>
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} style={{ color: '#3f51b5' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}

                <table id={`${'key1'}`}></table>
            </Grid>
        </Grid>
    );
};

export default ReportGijgoGrid;
