import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import { TextBox } from '../../../../../Core/FormInput';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';

let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};

const baseColumns = [
    { field: 'accountCode', title: `Code`, width: 80, sortable: false },
    { field: 'customersName', title: `Customer`, width: 200, sortable: false },
    { field: 'totalAmount', sortable: true, title: `Total`, width: 120, showColumnTotal: true, align: 'right' },
    { field: 'currentMonthTotal', sortable: true, title: `Current`, width: 120, showColumnTotal: true, align: 'right' },
    {
        field: 'oneMonthTotal',
        sortable: true,
        title: `1 Month`,
        width: 150,
        showColumnTotal: true,
        align: 'right'
    },
    { field: 'twoMonthsTotal', sortable: true, title: `2 Months`, width: 120, showColumnTotal: true, align: 'right' },
    { field: 'threeMonthsTotal', sortable: true, title: `3 Months`, width: 120, showColumnTotal: true, align: 'right' },
    {
        field: 'fourMonthsAndOverTotal',
        sortable: true,
        title: '4+ Months',
        width: 120,
        showColumnTotal: true,
        align: 'right'
    },
    {
        field: 'unallocatedItemsTotal',
        title: 'Unallocated',
        width: 120,
        showColumnTotal: true,
        align: 'right',

        sortable: false
    },
    { field: 'areaCode', title: `Area Code`, width: 100, sortable: false }
];
const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'Aged Measures', path: '/AgedMeasures' },
    { name: measuredesc, active: true }
];

const AgedDebt = (props) => {
    const history = useHistory();
    const redirectToScreens = (value, record, MeasureID, field) => {
        let searchQuery = `?&measuredesc=${props.MeasureDesc}&MeasureID=${MeasureID}&month=${field.month == 0 ? 0 : field.month || ''}&unallocated=${
            field.unallocated || ''
        }&accCode=${record.accountCode || ''}`;
        history.push({
            pathname: '/AgedMeasures/GetAgedMeasuresRanMWarrantyCashGen',
            search: searchQuery
        });
    };

    const editButton = (field) => (value, record) => {
        let isClickable = record.accessAllowed.trim() === 'Y';
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                title={value}
                onClick={isClickable ? () => redirectToScreens(value, record, props.MeasureID, field) : ''}
                style={{ cursor: isClickable ? 'pointer' : '' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const monthsActionHandler = (field) => (value, record) => {
        let isClickable = record.accessAllowed.trim() === 'Y';
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                title={field.toolTipLabel ? record[field.toolTipLabel] : value}
                onClick={isClickable ? () => redirectToScreens(value, record, props.MeasureID, field) : ''}
                style={{ cursor: isClickable ? 'pointer' : '' }}
            >
                {addCommas(value)}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const totalAmountWrapper = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid>
                {addCommas(record.currentMonthTotal + record.oneMonthTotal + record.twoMonthsTotal + record.threeMonthsTotal + record.fourMonthsAndOverTotal)}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let accountCodeCol = tempCols.find((element) => element.field === 'accountCode');
        let currentMonthTotalCol = tempCols.find((element) => element.field === 'currentMonthTotal');
        let totalAmount = tempCols.find((element) => element.field === 'totalAmount');
        let oneMonthTotalCol = tempCols.find((element) => element.field === 'oneMonthTotal');
        let twoMonthTotalCol = tempCols.find((element) => element.field === 'twoMonthsTotal');
        let threeMonthTotalCol = tempCols.find((element) => element.field === 'threeMonthsTotal');
        let fourPlusMonthTotalCol = tempCols.find((element) => element.field === 'fourMonthsAndOverTotal');
        let unallocatedTotalCol = tempCols.find((element) => element.field === 'unallocatedItemsTotal');
        if (accountCodeCol) {
            accountCodeCol.renderer = editButton({});
        }
        if (totalAmount) {
            totalAmount.renderer = totalAmountWrapper;
        }
        if (currentMonthTotalCol) {
            currentMonthTotalCol.renderer = monthsActionHandler({ month: 0, toolTipLabel: 'currentMonthCount' });
        }
        if (oneMonthTotalCol) {
            oneMonthTotalCol.renderer = monthsActionHandler({ month: 1, toolTipLabel: 'oneMonthCount' });
        }
        if (twoMonthTotalCol) {
            twoMonthTotalCol.renderer = monthsActionHandler({ month: 2, toolTipLabel: 'twoMonthsCount' });
        }
        if (threeMonthTotalCol) {
            threeMonthTotalCol.renderer = monthsActionHandler({ month: 3, toolTipLabel: 'threeMonthsCount' });
        }
        if (fourPlusMonthTotalCol) {
            fourPlusMonthTotalCol.renderer = monthsActionHandler({ month: 4, toolTipLabel: 'fourMonthsAndOverCount' });
        }
        if (unallocatedTotalCol) {
            unallocatedTotalCol.renderer = monthsActionHandler({ unallocated: 1, toolTipLabel: 'unallocatedItemsCount' });
        }
        return tempCols;
    }, [props.MeasureID]);

    const baseUrl = `AgedMeasures/AgedDebt?MeasureID=${props.MeasureID}`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(props.MeasureDesc)} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={true}
                        // hidePagination={true}
                        // hideFilterRow={true}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default AgedDebt;
