import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, ButtonGroup, Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart } from 'recharts';
import { PieChart, Pie, Sector, LabelList } from 'recharts';
import { Wifi } from '@material-ui/icons';
import './mainDashboard.scss';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 3,
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 120,
        padding: '5px 0px',
        background: '#f5f5f5',
        borderRadius: 10
    }
}));

let setObject = {
    ['<24hr']: '#00F433',
    ['1-5 days']: '#88EBA4',
    ['5-10 days']: '#FFBF00',
    ['10-20 days']: '#FFE28A',
    ['21-30 days']: '#bc4443',
    ['>30 days']: '#F11C0E'
};

let timenumber = {
    ['<24hr']: 1,
    ['1-5 days']: 2,
    ['5-10 days']: 3,
    ['10-20 days']: 4,
    ['21-30 days']: 5,
    ['>30 days']: 6
};

const COLORS = [
    '#0074D9',
    '#FF4136',
    '#2ECC40',
    '#FF851B',
    '#7FDBFF',
    '#B10DC9',
    '#FFDC00',
    '#001f3f',
    '#39CCCC',
    '#01FF70',
    '#85144b',
    '#F012BE',
    '#3D9970',
    '#111111',
    '#AAAAAA'
];

function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = '#' + hex.toString(16);

    return color;
}

const DashboardCharts = (props) => {
    const { state } = props;
    const classes = useStyles();
    const history = useHistory();
    const [array, setArray] = useState({
        workflowReturnReasonArray: [],
        keys: [],
        byTimeObj: {},
        selectRRarr: [],
        showLoader: true,
        selectedFilterTechData: [],
        techJobsTotal: 0
    });

    useEffect(() => {
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));

        if (btTimeData?.branchShortName) {
            props.getbranchByOnchange(btTimeData?.branchShortName);
        } else {
            props.getbranchByOnchange('Default');
        }
    }, []);

    useEffect(() => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_')) || {};
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
        localStorage.setItem('FiltterParams', JSON.stringify({ ...btTimeData, branchTime: '' }));

        if (state) {
            if (state.sumOFByTimeForALL) {
                findByTimeValues(btTimeData?.branchShortName || 'Default');
            }
            if (state.workFlowDataBarData) {
                pullWorkflowRRdata(tempData.workflowReturnReason || state.workFlowDataBarData[0]?.workflow);
            }

            if (state.chartdata) {
                setArray((st) => ({ ...st, showLoader: false, timeByBranch: tempData?.BranchBytime || state?.chartdata[0]?.name }));
            }
            if (!tempData) {
                localStorage.setItem('_dashboard_', JSON.stringify({ ...tempData, workflowReturnReason: state.workFlowDataBarData[0]?.workflow }));
            }
        }
        if (btTimeData) {
            setArray((st) => ({ ...st, byTimeBranch: btTimeData.branchShortName }));
        }
    }, [state.workFlowDataBarData]);

    useEffect(() => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_')) || {};
        if (state.technicianJobsData) {
            pullTechnicianJobsData(tempData?.selectedValueByTech || state?.chartdata[0]?.name);
        }
    }, [state.technicianJobsData]);

    const pullTechnicianJobsData = (val) => {
        let tempLocalData = JSON.parse(localStorage.getItem('_dashboard_'));
        let tempData = [...state.technicianJobsData].find((m) => m.branch == val) || [];
        let techJobsTotal = 0;
        if (tempData?.length > 0) {
            tempData.forEach((q) => {
                techJobsTotal += q.total;
            });
        }
        setArray((st) => ({ ...st, selectedValueByTech: val, selectedFilterTechData: tempData, techJobsTotal: techJobsTotal }));
        localStorage.setItem('_dashboard_', JSON.stringify({ ...tempLocalData, selectedValueByTech: val }));
    };

    const pullWorkflowRRdata = (value) => {
        let newArray = [];
        let keys = [];
        if (state.workFlowDataBarData) {
            let index = state.workFlowDataBarData.find((n) => n.workflow === value);
            if (index) {
                keys = Object.keys(index);
                newArray.push(index);
            }
            setArray((st) => ({
                ...st,
                workflowReturnReasonArray: newArray,
                keys: keys,
                workFlowRR: value
            }));
        }
    };

    let handleArray = (e) => {
        let workflowValue = e.target.value;
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        localStorage.setItem('_dashboard_', JSON.stringify({ ...tempData, workflowReturnReason: workflowValue }));
        pullWorkflowRRdata(workflowValue);
        props.getbranchByOnchange(btTimeData?.branchShortName || 'Default', workflowValue);
    };

    let findByTimeValues = (val) => {
        let indexObj = {};
        if (val === 'Default') {
            if (state.sumOFByTimeForALL) {
                let letNewData = state?.sumOFByTimeForALL?.reduce((acc, obj) => {
                    var index = acc.find((item) => item);
                    if (index) {
                        index.lessThan24HoursOld += obj.lessThan24HoursOld;
                        index.between1And5DaysOld += obj.between1And5DaysOld;
                        index.between5And10DaysOld += obj.between5And10DaysOld;
                        index.between10And20DaysOld += obj.between10And20DaysOld;
                        index.between21And30DaysOld += obj.between21And30DaysOld;
                        index.over30DaysOld += obj.over30DaysOld;
                        index.total += obj.total;
                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);
                indexObj = letNewData.find((item) => item);
                indexObj.branch = '';
                setArray((st) => ({ ...st, byTimeObj: indexObj, byTimeBranch: val }));
            }
        } else {
            indexObj = state.sumOFByTimeForALL.find((n) => n.branch === val);
            setArray((st) => ({ ...st, byTimeObj: indexObj, byTimeBranch: val }));
        }
    };

    const handleChangeByTime = (e) => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        let valueByTime = e.target.value;
        props.getbranchByOnchange(valueByTime);
        findByTimeValues(valueByTime);
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: valueByTime !== 'Default' ? valueByTime : '' }));
        if (valueByTime !== 'Default') {
            localStorage.setItem('_dashboard_', JSON.stringify({ ...tempData, BranchBytime: valueByTime, selectedValueByTech: valueByTime }));
        }
    };

    const handleTimeBranch = (e) => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        let valueByTimeBranch = e.target.value;
        setArray((st) => ({ ...st, timeByBranch: valueByTimeBranch }));

        localStorage.setItem('_dashboard_', JSON.stringify({ ...tempData, BranchBytime: valueByTimeBranch }));
    };
    const handleTechSelect = (e) => {
        let valueByTech = e.target.value;
        pullTechnicianJobsData(valueByTech);
    };

    const handleTechOnClick = (techObj) => {
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: techObj.branch }));
        let searchQuery = `&tid=${techObj.tid || ''}&bnm=${techObj.branch}&tnm=${techObj.name}&bid=${techObj.bid}`;
        history.push({
            pathname: '/techDashgrid',
            search: searchQuery,
            state: {}
        });
    };

    return (
        <Grid item container alignContent="flex-start" style={{ overflow: 'auto', height: 'calc(100vh - 215px)' }} className="custom-scroll">
            <Grid item container xs={12} sm={12} md={12} lg={4} xl={3} style={{ padding: 5 }}>
                <Card className={classes.root} style={{ position: 'relative' }}>
                    <Typography varient="h5" color="secondary" className="dashHeading">
                        By Branch
                    </Typography>

                    <div className="pieChartSize">
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie data={state.chartdata} cx={70} cy={60} paddingAngle={3} outerRadius={60} innerRadius={40} fill="#8884d8" dataKey="value">
                                    {state.chartdata.map((entry, index) => (
                                        <>
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={COLORS[index % COLORS.length]}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => props.onChartClickHandler({ workflowID: 'All', branch: entry.name }, true)}
                                            />
                                        </>
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>

                    <div
                        style={{ position: 'absolute', right: 5, top: 20, maxHeight: '130px', overflowY: 'scroll', overflowX: 'hidden' }}
                        className="custom-scroll"
                    >
                        {state?.chartdata.map((m, i) => {
                            let color = COLORS[i];
                            return (
                                <div style={{ padding: 0 }}>
                                    <div style={{ fontSize: 13, width: 210, display: 'flex', justifyContent: 'flex-start' }}>
                                        {' '}
                                        <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                        <div style={{ fontWeight: 700 }}>&nbsp;{m.value}</div>
                                        <div>&nbsp;{m.name}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Card>
            </Grid>

            {/* <Grid item container xs={12} sm={12} md={6} lg={6} xl={3} style={{ padding: 5, boxSize: 'none' }}>
                <Card className={classes.root} style={{ position: 'relative' }}>
                    <Typography varient="h5" color="secondary" className="dashHeading">
                        By Workflow
                    </Typography>

                    <div className="pieChartSize">
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie
                                    data={state.workflowData}
                                    cx={70}
                                    cy={60}
                                    paddingAngle={3}
                                    outerRadius={60}
                                    innerRadius={40}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {state.workflowData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => props.onChartClickHandler({ workflowID: entry.workflowID, workflow: entry.name, branch: '' }, true)}
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div
                        style={{ position: 'absolute', right: 3, top: 20, maxHeight: '130px', overflowY: 'scroll', overflowX: 'hidden' }}
                        className="custom-scroll"
                    >
                        {state?.workflowData.map((m, i) => {
                            let color = COLORS[i];
                            return (
                                <div style={{ padding: 0 }}>
                                    <div style={{ fontSize: 13, width: 220, display: 'flex', justifyContent: 'flex-start' }}>
                                        {' '}
                                        <div style={{ background: color, height: 10, width: 10, marginTop: 4 }}></div>
                                        <div style={{ fontWeight: 700 }}>&nbsp;{m.value}</div>
                                        <div style={{ fontSize: 13 }}>&nbsp;{m.name}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Card>
            </Grid> */}

            <Grid item container xs={12} sm={12} md={12} lg={8} xl={9} style={{ padding: 5, boxSize: 'none' }}>
                <Card className={classes.root}>
                    <Grid container xs={12}>
                        <Grid item xs={8}>
                            <Typography vraient="h5" color="secondary" className="dashHeading">
                                By Time
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{ float: 'right', marginRight: 40 }}>
                                <select onChange={handleChangeByTime} className="selectclass" value={array.byTimeBranch}>
                                    <option value={'Default'}>All Branches</option>
                                    {state.chartdata.map((btn, i) => {
                                        return (
                                            <option key={i} value={btn.name}>
                                                {btn.name}{' '}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '5px 5px ' }}>
                        <div
                            style={{
                                textAlign: 'left',
                                padding: '0px 20px',
                                fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                fontWeight: 500
                            }}
                        ></div>
                    </Grid>
                    <div style={{ padding: '0px 30px' }}>
                        <hr
                            style={{
                                flexShrink: 0,
                                borderWidth: '0px 0px thin',
                                borderStyle: 'solid',
                                borderColor: 'rgba(58, 53, 65, 0.12)',
                                margin: '1.1rem 0px'
                            }}
                        />
                    </div>
                    <Grid container spacing={1} justifyContent="space-evenly" style={{ padding: '0px 6px' }}>
                        <Grid
                            item
                            style={{ padding: '10px 10px 10px 0px' }}
                            onClick={() =>
                                props.onChartClickHandler(
                                    {
                                        workflowID: 'All',
                                        workflow: array.byTimeObj?.workflow,
                                        branch: array.byTimeObj?.branch,
                                        time: 1
                                    },
                                    true
                                )
                            }
                        >
                            <div
                                style={{
                                    borderLeft: '10px solid #00F433',
                                    padding: '5px 10px 0px 10px',
                                    width: '100%',
                                    cursor: 'pointer'
                                }}
                            // onClick={() => buttonClicked(m)}
                            >
                                <div
                                    style={{
                                        paddingBottom: 10,
                                        textAlign: 'left'
                                    }}
                                >
                                    <span style={{ fontSize: 14, marginBottom: 10, fontFamily: `"Public Sans", sans-serif` }}> Less Than 24Hr</span>
                                    <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                        {' '}
                                        {array.byTimeObj?.lessThan24HoursOld}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            style={{ padding: '5px 5px' }}
                            onClick={() =>
                                props.onChartClickHandler(
                                    {
                                        workflowID: 'All',
                                        workflow: array.byTimeObj?.workflow,
                                        branch: array.byTimeObj?.branch,
                                        time: 2
                                    },
                                    true
                                )
                            }
                        >
                            <div
                                style={{
                                    borderLeft: '10px solid #88EBA4',
                                    padding: '5px 10px 0px 10px',
                                    width: '100%',
                                    cursor: 'pointer'
                                }}
                            // onClick={() => buttonClicked(m)}
                            >
                                <div
                                    style={{
                                        paddingBottom: 10,
                                        textAlign: 'left'
                                    }}
                                >
                                    <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}> 1 to 5 Days</span>
                                    <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                        {' '}
                                        {array.byTimeObj?.between1And5DaysOld}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            style={{ padding: '5px 5px' }}
                            onClick={() =>
                                props.onChartClickHandler(
                                    {
                                        workflowID: 'All',
                                        workflow: array.byTimeObj?.workflow,
                                        branch: array.byTimeObj?.branch,
                                        time: 3
                                    },
                                    true
                                )
                            }
                        >
                            <div
                                style={{
                                    borderLeft: '10px solid #FFBF00',
                                    padding: '5px 10px 0px 10px',
                                    width: '100%',
                                    cursor: 'pointer'
                                }}
                            // onClick={() => buttonClicked(m)}
                            >
                                <div
                                    style={{
                                        paddingBottom: 10,
                                        textAlign: 'left'
                                    }}
                                >
                                    <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}> 5 to 10 Days</span>
                                    <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                        {' '}
                                        {array.byTimeObj?.between5And10DaysOld}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            style={{ padding: '5px 5px' }}
                            onClick={() =>
                                props.onChartClickHandler(
                                    {
                                        workflowID: 'All',
                                        workflow: array.byTimeObj?.workflow,
                                        branch: array.byTimeObj?.branch,
                                        time: 4
                                    },
                                    true
                                )
                            }
                        >
                            <div
                                style={{
                                    borderLeft: '10px solid #FFE28A',
                                    padding: '5px 10px 0px 10px',
                                    width: '100%',
                                    cursor: 'pointer'
                                }}
                            // onClick={() => buttonClicked(m)}
                            >
                                <div
                                    style={{
                                        paddingBottom: 10,
                                        textAlign: 'left'
                                    }}
                                >
                                    <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}> 10 to 20 Days</span>
                                    <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                        {' '}
                                        {array.byTimeObj?.between10And20DaysOld}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            style={{ padding: '5px 5px' }}
                            onClick={() =>
                                props.onChartClickHandler(
                                    {
                                        workflowID: 'All',
                                        workflow: array.byTimeObj?.workflow,
                                        branch: array.byTimeObj?.branch,
                                        time: 5
                                    },
                                    true
                                )
                            }
                        >
                            <div
                                style={{
                                    borderLeft: '10px solid #bc4443',
                                    padding: '5px 10px 0px 10px',
                                    width: '100%',
                                    cursor: 'pointer'
                                }}
                            // onClick={() => buttonClicked(m)}
                            >
                                <div
                                    style={{
                                        paddingBottom: 10,
                                        textAlign: 'left'
                                    }}
                                >
                                    <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}> 20 to 30 Days</span>
                                    <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                        {' '}
                                        {array.byTimeObj?.between21And30DaysOld}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            style={{ padding: '5px 5px' }}
                            onClick={() =>
                                props.onChartClickHandler(
                                    {
                                        workflowID: 'All',
                                        workflow: array.byTimeObj?.workflow,
                                        branch: array.byTimeObj?.branch,
                                        time: 6
                                    },
                                    true
                                )
                            }
                        >
                            <div
                                style={{
                                    borderLeft: '10px solid #F11C0E',
                                    padding: '5px 10px 0px 10px',
                                    width: '100%',
                                    cursor: 'pointer'
                                }}
                            // onClick={() => buttonClicked(m)}
                            >
                                <div
                                    style={{
                                        paddingBottom: 10,
                                        textAlign: 'left'
                                    }}
                                >
                                    <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}> Over 30 Days</span>
                                    <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                        {' '}
                                        {array.byTimeObj?.over30DaysOld}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                <Card className={classes.root}>
                    <Grid container xs={12}>
                        <Grid item xs={8}>
                            <Typography vraient="h5" color="secondary" className="dashHeading">
                                Clocked Hours
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{ float: 'right', marginRight: 40 }}>
                                <select onChange={handleChangeByTime} className="selectclass" value={array.byTimeBranch}>
                                    <option value={'Default'}>All Branches</option>
                                    {state.chartdata.map((btn, i) => {
                                        return (
                                            <option key={i} value={btn.name}>
                                                {btn.name}{' '}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ padding: '5px 5px ' }}>
                        <div
                            style={{
                                textAlign: 'left',
                                padding: '0px 20px',
                                fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                fontWeight: 500
                            }}
                        ></div>
                    </Grid>
                    <div style={{ padding: '0px 30px' }}>
                        <hr
                            style={{
                                flexShrink: 0,
                                borderWidth: '0px 0px thin',
                                borderStyle: 'solid',
                                borderColor: 'rgba(58, 53, 65, 0.12)',
                                margin: '1.1rem 0px'
                            }}
                        />
                    </div>

                    <Grid container spacing={1} justifyContent="space-evenly" style={{ padding: '0px 6px' }}>
                        {
                            state.clockedHours.map((g) => {
                                if (state.selectedBranch == "Default") {
                                    if (g.branch == "Default") {
                                        const { branch, branchID, hoursTaken, orderIndex, workflow, workflowID } = g;
                                        return (
                                            <div>
                                                <Grid
                                                    item
                                                    style={{ padding: '5px 5px' }}
                                                    onClick={() =>
                                                        props.onChartClickHandler(
                                                            {
                                                                workflowID: workflowID,
                                                                workflow: workflow,
                                                            },
                                                            true
                                                        )
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            borderLeft: '10px solid #88EBA4',
                                                            padding: '5px 10px 0px 10px',
                                                            width: '100%',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                paddingBottom: 10,
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}>{workflow}</span>
                                                            <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                                                {' '}
                                                                {hoursTaken.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </div>
                                        )
                                    }
                                } else {
                                    if (g.branch == state.selectedBranch) {
                                        const { branch, branchID, hoursTaken, orderIndex, workflow, workflowID } = g;
                                        return (
                                            <div>
                                                <Grid
                                                    item
                                                    style={{ padding: '5px 5px' }}
                                                    onClick={() =>
                                                        props.onChartClickHandler(
                                                            {
                                                                workflowID: workflowID,
                                                                workflow: workflow,
                                                                branch: branch,
                                                            },
                                                            true
                                                        )
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            borderLeft: '10px solid #88EBA4',
                                                            padding: '5px 10px 0px 10px',
                                                            width: '100%',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                paddingBottom: 10,
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            <span style={{ fontSize: 14, marginBottom: 6, fontFamily: `"Public Sans", sans-serif` }}>{workflow}</span>
                                                            <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>
                                                                {' '}
                                                                {hoursTaken}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </div>
                                        )
                                    }
                                }
                            })
                        }
                    </Grid>
                </Card>
            </Grid>

            {state?.allHeaderData?.map((m, i) => {
                if (m.branch === array.timeByBranch) {
                    return (
                        <Grid item container xs={12} sm={12} md={12} lg={12} xl={state.workflowData?.length > 6 ? 12 : 6} style={{ padding: 5 }}>
                            <Card className={classes.root}>
                                <Grid container xs={12}>
                                    <Grid item xs={8}>
                                        <Typography vraient="h5" color="secondary" className="dashHeading">
                                            {`Workflows By Branch (${m.branch})`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div style={{ float: 'right', marginRight: 40 }}>
                                            <select onChange={handleTimeBranch} className="selectclass" value={array.timeByBranch}>
                                                {state.chartdata.map((btn, i) => {
                                                    return (
                                                        <option key={i} value={btn.name}>
                                                            {' '}
                                                            {btn.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </Grid>
                                </Grid>

                                <div style={{ width: '100%', height: 300 }}>
                                    <ResponsiveContainer>
                                        <BarChart
                                            data={m}
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="workflow" />
                                            <YAxis />
                                            <Tooltip
                                                contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                                                position={{ x: 30, y: 20 }}
                                            />
                                            <Legend />
                                            {state?.allHeaderDataKeys?.map((n, i) => {
                                                return (
                                                    n !== 'name' &&
                                                    n !== 'workflow' &&
                                                    n !== 'workflowID' && (
                                                        <Bar dataKey={n} fill={setObject[n]} barSize={10} style={{ cursor: 'pointer' }}>
                                                            {/* <LabelList dataKey={n} position="top" /> */}
                                                            {m.map((entry, index) => {
                                                                return (
                                                                    <Cell
                                                                        onClick={() =>
                                                                            props.onChartClickHandler(
                                                                                {
                                                                                    workflowID: entry.workflowID,
                                                                                    workflow: entry.workflow,
                                                                                    branch: entry.name,
                                                                                    time: timenumber[n]
                                                                                },
                                                                                true
                                                                            )
                                                                        }
                                                                    />
                                                                );
                                                            })}
                                                        </Bar>
                                                    )
                                                );
                                            })}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Card>
                        </Grid>
                    );
                }
            })}
            <Grid item container xs={12} sm={12} xl={state.workflowData?.length > 6 ? 12 : 6} style={{ padding: 5, boxSize: 'none' }}>
                <Card className={classes.root}>
                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <Typography vraient="h5" color="secondary" className="dashHeading">
                                Return Reason By Workflow ({!array?.byTimeBranch ? 'All Branch' : array?.byTimeBranch})
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ float: 'right', marginRight: 40 }}>
                                <select onChange={handleArray} className="selectclass" value={array.workFlowRR}>
                                    {state?.workflowData.map((j, i) => (
                                        <option key={i} value={j.name}>
                                            {j.name}{' '}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ width: '100%', height: 300 }}>
                        {array.workflowReturnReasonArray.length > 0 ? (
                            <ResponsiveContainer>
                                <BarChart
                                    data={array.workflowReturnReasonArray}
                                    width={300}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                // contentStyle={{ background: 'rgba(0,0,0,0.6)' }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="workflow">{/* <Label value="Pages of my website" offset={-4} position="insideBottom" /> */}</XAxis>
                                    <YAxis />
                                    <Tooltip
                                        contentStyle={{ background: 'rgba(0,0,0,0.8)', color: 'white', fontSize: 12, fontWeight: 700 }}
                                        position={{ x: 30, y: 20 }}
                                    />
                                    {/* <Legend height={10} /> */}

                                    {array?.keys?.map((m, i) => {
                                        let color = state?.returnReasonColors.find((n) => n.returnReason === m);
                                        return (
                                            m !== 'name' &&
                                            m !== 'workflowID' &&
                                            m !== 'workflow' && (
                                                <Bar dataKey={m} fill={color.color} barSize={40} layout="vertical" height={20}>
                                                    <LabelList dataKey={m} position="top" />
                                                    {array.workflowReturnReasonArray.map((entry, index) => {
                                                        return (
                                                            <Cell
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() =>
                                                                    props.onChartClickHandler(
                                                                        { workflowID: entry.workflowID, returnReason: m, branch: entry.name },
                                                                        true
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </Bar>
                                            )
                                        );
                                    })}
                                </BarChart>
                            </ResponsiveContainer>
                        ) : (
                            <Grid container justifyContent="center" alignContent="center">
                                <h5>No chart data Found</h5>
                            </Grid>
                        )}
                    </div>
                </Card>
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 5, boxSize: 'none' }}>
                <Card className={classes.root}>
                    <Grid container xs={12}>
                        <Grid item xs={8}>
                            <Typography vraient="h5" color="secondary" className="dashHeading">
                                {'Jobs By Technicians'}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{ float: 'right', marginRight: 40, display: 'flex' }}>
                                <div
                                    style={{
                                        padding: '2px 12px 0px 0px',
                                        fontSize: 20,
                                        fontWeight: 600,
                                        fontFamily: `"Public Sans", sans-serif`,
                                        cursor: 'pointer'
                                    }}
                                >
                                    <span
                                        onClick={() =>
                                            handleTechOnClick({
                                                tid: '',
                                                branch: array.selectedValueByTech,
                                                name: '',
                                                bid: ''
                                            })
                                        }
                                    >
                                        Total : {array.techJobsTotal}
                                    </span>
                                </div>
                                <div>
                                    <select onChange={handleTechSelect} className="selectclass" value={array.selectedValueByTech}>
                                        {state.chartdata.map((btn, i) => {
                                            return (
                                                <option key={i} value={btn.name}>
                                                    {btn.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '5px 5px ' }}>
                        <div
                            style={{
                                textAlign: 'left',
                                padding: '0px 20px',
                                fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                fontWeight: 500
                            }}
                        ></div>
                    </Grid>
                    <div style={{ padding: '0px 30px' }}>
                        <hr
                            style={{
                                flexShrink: 0,
                                borderWidth: '0px 0px thin',
                                borderStyle: 'solid',
                                borderColor: 'rgba(58, 53, 65, 0.12)',
                                margin: '1.1rem 0px'
                            }}
                        />
                    </div>
                    <Grid container spacing={2} style={{ minHeight: '110px', overflow: 'auto' }}>
                        {array.selectedFilterTechData.map((p) => {
                            return (
                                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} onClick={() => handleTechOnClick(p)} style={{ cursor: 'pointer' }}>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Avatar
                                                style={{
                                                    backgroundColor: randomColor()
                                                }}
                                            >
                                                {p.name[0]}
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <div
                                                style={{
                                                    paddingBottom: 10,
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span style={{ fontSize: 14, marginBottom: 10, fontFamily: `"Public Sans", sans-serif` }}> {p.name}</span>
                                                <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>{p.total}</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DashboardCharts;
