import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';

export default [
    {
        label: 'Technician Write-Up',
        hidden: true,
        exact: true,
        path: '/technicianwriteup/:jobId/:technicianJobId?/:isTechReadonly?',
        component: Components.TechWriteUp,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Part Requisitions',
        hidden: true,
        exact: true,
        path: '/partrequisitions/:jobId/:technicianJobId?/:isTechReadonly?',
        component: Components.PartRequisitionsModal,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Part Requisitions',
        hidden: true,
        exact: true,
        path: '/partreq-approve/:jobId/:technicianJobId?/:isTechReadonly?',
        component: Components.ApprovePartRequisitions,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Part Requisitions',
        hidden: true,
        exact: true,
        path: '/partreq-qty-issue/:jobId/:technicianJobId?/:isTechReadonly?',
        component: Components.PartRequisitionsIssueQty,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Quality Control',
        hidden: true,
        exact: true,
        path: '/qc/:jobId',
        component: Components.QualityControlSheet,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Customer Consultation',
        hidden: true,
        exact: true,
        path: '/customerconsultation/:jobId',
        component: Components.CustomerConsultation,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Lift Check List',
        hidden: true,
        exact: true,
        path: '/liftchecklist/:jobId',
        component: Components.LiftCheckList,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Tech Write-Up',
        hidden: true,
        exact: true,
        path: '/techwriteupadvisor/:jobId/:technicianJobId?',
        component: Components.TechWrite_Up,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Assign Technician',
        hidden: true,
        exact: true,
        path: '/assign-technician/:jobId',
        component: Components.AssignTechnician,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Battery Test',
        hidden: true,
        exact: true,
        path: '/batterytest/:jobId',
        component: Components.BatteryTest,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Defect',
        hidden: true,
        exact: true,
        path: '/defectsworkshopcontroller/:jobId/:isReadOnly?',
        component: Components.DefectWC,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Defects',
        hidden: true,
        exact: true,
        path: '/defects/:jobId',
        component: Components.AddDefects,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Break Disc Pad Wear Evaluation',
        hidden: true,
        exact: true,
        path: '/brakediscpadwear/:jobId/:technicianJobId?/:isTechReadonly?',
        component: Components.BrakeDiscPadWear,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Diagnostic Charges',
        hidden: true,
        exact: true,
        path: '/diagnosticcharges/:jobId',
        component: Components.DiagnosticCharge,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Tyre Check',
        hidden: true,
        exact: true,
        path: '/tyrecheck/:jobId',
        component: Components.TyreChecks,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service 24',
        hidden: true,
        exact: true,
        path: '/technican/service24/:jobId',
        component: Components.Service24Modal,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Diagnostic Sheet',
        hidden: true,
        // icon: <DriveEtaIcon />,
        exact: true,
        path: '/diagnosticSheet/:jobId',
        component: Components.DiagnosticSheets,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Hand Over Sheet',
        hidden: true,
        exact: true,
        path: '/handOverSheetScreen/:jobId',
        component: Components.HandOverSheetScreen,
        authType: TypeOfAuth.Auth
    }
];
