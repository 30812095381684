import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import formatters from '../../../../../Core/Grid/inputFormatter';

const baseColumns = [
    {
        field: 'ac',
        title: 'Account',
        filterable: false,
        sortable: false,
        width: 90
        // isCurrency: true
    },
    {
        field: 'cr',
        title: 'Reference',
        filterable: false,
        sortable: false,
        width: 180
    },
    {
        field: 'rn',
        title: 'Reg No',
        filterable: false,
        sortable: false,
        width: 100
    },
    {
        field: 'iname',
        title: 'Invoice Name',
        filterable: false,
        sortable: false,
        width: 250
    },
    {
        field: 'cn',
        title: 'Customer Name',
        filterable: false,
        sortable: false,
        width: 200
    },
    {
        field: 'bal',
        title: 'Balance',
        showColumnTotal: true,
        filterable: false,
        sortable: false,
        isCurrency: true,
        width: 120,
        align: 'right'
    },
    {
        field: 'id',
        title: 'Invoice Date',
        filterable: false,
        sortable: false,
        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        },
        width: 120
    },
    {
        field: 'age',
        title: 'Age',
        filterable: false,
        sortable: false,
        width: 60
    },
    {
        field: 'loc',
        title: 'Sold By',
        filterable: false,
        sortable: false,
        width: 100
    },
    {
        field: 'comm',
        title: 'Comm Number',
        filterable: false,
        sortable: false,
        width: 140
    },
    {
        field: 'sbn',
        title: 'Stockbook Number',
        filterable: false,
        sortable: false,
        width: 180
    },
    {
        field: 'dn',
        title: 'Invoice No',
        filterable: false,
        sortable: false,
        width: 120
    }
];

const getCrumbs = (ParamsalesExec, ParamSalesExecCode, ParamAgeDays) => {
    console.log(ParamsalesExec, ParamSalesExecCode, ParamAgeDays);
    let temp = {};
    let final = [
        { name: 'Home', path: '' },
        { name: `Vehicle Aged Debt By Salesexec`, path: '/sales/VehicleAgedDebtBySalesexec' }
    ];
    if (ParamsalesExec || ParamSalesExecCode || ParamAgeDays) {
        temp = {
            name: `${ParamsalesExec ? ParamsalesExec : ''} ${
                ParamAgeDays == '7'
                    ? '(7 Days)'
                    : ParamAgeDays == '14'
                    ? '(8-14 Days)'
                    : ParamAgeDays == '21'
                    ? '(15-21 Days)'
                    : ParamAgeDays == '22'
                    ? '(Over 21 Days)'
                    : ''
            }`,
            active: true
        };
        final = [...final, { ...temp }];
    }

    return final;
};

const ViewDebtDetailsSalesexec = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamsalesExec = params.get('salesExec') || '';
    let ParamSalesExecCode = params.get('SalesExecCode') || '';
    let ParamAgeDays = params.get('AgeDays') || '';
    const baseUrl = `Sales/VehicleDebt?SalesExecCode=${ParamSalesExecCode}&AgeDays=${ParamAgeDays}`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={getCrumbs(ParamsalesExec, ParamSalesExecCode, ParamAgeDays)} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={baseColumns}
                        getUrl={baseUrl}
                        isShowTotal={true}
                        hidePagination={true}
                        hideFilterRow={true}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default ViewDebtDetailsSalesexec;
