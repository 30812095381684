import React from 'react';
import AddEditActionLog from './addEditActionLog';
import { useState } from 'react';
import DialogComp from './../../../../Core/Controls/Dialog/DialogComp';
import CommonGijgoGrid from '../../../../Core/Controls/GijgoGrid/index';
import { moment } from 'moment';
import MotHistory from './motHistory';
import EmailModal from './emailPopUp';
import ReportGijgoGrid from '../../ReportsScreens/GijgoForReportsScreen';
import { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { CustomIconButton, SquareButton } from '../../../../Core/FormInput/AppButton';
import ReactDOM from 'react-dom';
import EditIcon from '@material-ui/icons/Edit';
import EditActionLog from './editActionLog';
import MailIcon from '@material-ui/icons/Mail';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import AddEditSiteVehicle from '../SiteVehicles/addEditSiteVehicle';
import UpdateIcon from '@material-ui/icons/Update';

const baseColumn = [
    { field: 'action', sortable: true, filterable: false, title: `Action `, width: 100 },
    {
        title: 'Action log',
        field: 'logText'
        // width: 250
    },

    { field: 'wipNumber', sortable: true, title: `WIP No`, width: 120 },
    { field: 'registration', sortable: true, title: 'Reg No.', width: 120 },
    {
        title: 'Customer',
        field: 'customerName',
        // width: 70
        width: 150
    },

    {
        title: 'Current Balance',
        field: 'balance',
        width: 160,
        filterable: false
        // isCurrency: true
    },

    {
        title: 'Status',
        field: 'status',
        width: 150
    },
    {
        field: 'branchShortName',
        sortable: true,
        title: `Branch`,
        width: 120
    }
];

const OpenActionLog = () => {
    const [show, setShow] = useState({
        actionLog: false,
        MotHistory: false,
        EmailPopUp: false,
        list: []
    });

    // const handleMail = (val) => {
    //     setShow((st) => ({ ...st, EmailPopUp: true, reg: val.registration, wip: val.wipNumber }));
    // };

    const handleEdit = (val) => {
        setShow((st) => ({ ...st, actionLog: true, id: val.id, headerRecordID: val.headerRecordID, reg: val.registration, wip: val.wipNumber }));
    };

    const handleUpdateStatus = (val) => {
        setShow((st) => ({ ...st, siteVehicle: true, headerRecordID: val.headerRecordID, reg: val.registration, wip: val.wipNumber }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <CustomIconButton onClick={() => handleUpdateStatus(record)} toolTipTitle="Vehicle Status">
                        <UpdateIcon fontSize="medium" style={{ color: '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                <Grid item>
                    <CustomIconButton onClick={() => handleEdit(record)} toolTipTitle="Edit Log">
                        <EditIcon fontSize="medium" style={{ color: '#183B68' }} />
                    </CustomIconButton>
                </Grid>
                {/* <Grid item>
                    <SquareButton
                        style={{
                            backgroundColor: '#183B68',
                            borderRadius: '20%',
                            width: '32px',
                            height: '32px',
                            textAlign: 'center',
                            padding: '0px 0px 0px 0px'
                        }}
                        onClick={() => handleMail(record)}
                    >
                        <MailIcon fontSize="small" style={{ color: 'white' }} />
                    </SquareButton>
                </Grid> */}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column = useMemo(() => {
        let col = [...baseColumn];
        let colAction = col.find((m) => m.field === 'action');

        if (colAction) {
            colAction.renderer = editButton;
        }

        return col;
    }, []);

    const handleClose = (res) => {
        let newSt = {};
        newSt.EmailPopUp = false;
        newSt.MotHistory = false;
        newSt.actionLog = false;

        if (res) {
            newSt.isReload = new Date();
        } else {
            newSt.siteVehicle = false;
        }
        setShow((st) => ({
            ...st,
            ...newSt
        }));
    };

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Communicator', path: '/communicator_dash' },
        { name: 'Action Log', active: true }
    ];

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            {show.actionLog ? <EditActionLog onClose={handleClose} headerRecordID={show.headerRecordID} id={show.id} reg={show.reg} wip={show.wip} /> : null}
            {show.EmailPopUp ? (
                <DialogComp title="Make Visible To Customer" onClose={() => handleClose(false)} fullWidth maxWidth="md">
                    <EmailModal onClose={handleClose} reg={show.reg} wip={show.wip} />
                </DialogComp>
            ) : null}
            {show.siteVehicle ? (
                <DialogComp title="Vehicle Status" onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                    <AddEditSiteVehicle onClose={handleClose} headerRecordID={show.headerRecordID} reg={show.reg} wip={show.wip} />
                </DialogComp>
            ) : null}
            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data?.total
                })}
                columns={column}
                getUrl={`Communicators/GetActionLogReminders?ShowJobs=s`}
                displayPagination={true}
                isReload={show.isReload}
            />
        </div>
    );
};
export default OpenActionLog;
