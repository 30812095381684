import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, DialogContents, DialogTitles, Dialogs, SecondaryButton, DialogsActions } from '../../../Core/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { addOcrDocument, getOcrDocument, getTableList, getOcrApiList, getTableColumnsList } from '../../../Core/Service/OcrDocuments-service';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import '../User Management/UserManagement.css';

const AddOcrDocumentsModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);
    const [state, setState] = useState({
        name: '',
        tableName: '',
        OcrApiId: null,
        columnName: '',
        tableList: [],
        ocrList: [],
        columnNameList: []
    });
    const OcrDocumentId = props.OcrDocumentId;
    useEffect(async () => {
        let res1 = await getTableColumnsList(state.tableName);
        if (res1.data) {
            setState((st) => ({
                ...st,
                columnNameList: res1.data.rows
            }));
        }
    }, [state.tableName]);
    useEffect(async () => {
        if (OcrDocumentId) {
            let result = await getOcrDocument(OcrDocumentId);
            const ocrDoc = result.data.rows;
            if (result.data) {
                setState((st) => ({
                    ...st,
                    name: ocrDoc.name,
                    tableName: ocrDoc.tableName,
                    OcrApiId: ocrDoc.ocrApiId,
                    columnName: ocrDoc.columnName
                }));
            }
        }
        let pros = [];
        pros.push(getTableList(), getOcrApiList());
        let responses = await Promise.all(pros);
        if (responses[0].success && responses[1].success) {
            setState((st) => ({
                ...st,
                tableList: responses[0].data.rows,
                ocrList: responses[1].data.rows
            }));
        }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        if (state.name) {
            let res = await addOcrDocument(OcrDocumentId, state.name, state.OcrApiId, state.tableName, state.columnName);
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                props.onFormSubmit(true);
            }
            showToast(res.message);
        } else {
            showToast('Name is required.');
        }
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{OcrDocumentId ? <DialogTitles>Update OCR Document</DialogTitles> : <DialogTitles>Add OCR Document</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents className="hiddenScroll">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Name</FormLabel>
                        <TextBox autoFocus name="name" onChange={fieldChange} value={state.name} />
                    </Grid>
                    {/* <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            Select Table Name
                        </FormLabel>
                        <TextBox
                            select
                            value={state.tableName}
                            name="tableName"
                            onChange={fieldChange}
                            rows={2}
                        >
                            {state.tableList.length > 0 &&
                                state.tableList.map((option) => (
                                    <MenuItem
                                        key={option.tablE_NAME}
                                        value={option.tablE_NAME}
                                    >
                                        {option.tablE_NAME}
                                    </MenuItem>
                                ))}
                        </TextBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">
                            Select Column Name
                        </FormLabel>
                        <TextBox
                            select
                            value={state.columnName}
                            name="columnName"
                            onChange={fieldChange}
                            variant="outlined"
                            rows={2}
                        >
                            {state.columnNameList.length > 0 &&
                                state.columnNameList.map((option) => (
                                    <MenuItem
                                        key={option.columN_NAME}
                                        value={option.columN_NAME}
                                    >
                                        {option.columN_NAME}
                                    </MenuItem>
                                ))}
                        </TextBox>
                    </Grid>
                    */}
                    {/* <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Select OCR Api</FormLabel>
                        <Select
                            className="setHeight"
                            variant="outlined"
                            fullWidth
                            label="Select OCR Api"
                            id="demo-simple-select"
                            name="OcrApiId"
                            value={state.OcrApiId}
                            onChange={fieldChange}
                        >
                            {state.ocrList.length > 0 &&
                                state.ocrList.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid> */}
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddOcrDocumentsModal;
