import React, { useState } from 'react';
import { Grid, InputLabel, FormHelperText } from '@material-ui/core';
import { TextBox } from '../../../Core/FormInput';
import { SecondaryButton } from './../../../Core/FormInput/AppButton';
import { postApprovedJob } from '../../../Core/Service/CustUserCommunicator/communicator-service';
import AppContext from '../../../App/AppContext';
import { useContext } from 'react';

const ApproveJobModal = (props) => {
    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({
        error: {}
    });

    let Validation = () => {
        let isValid = true;
        let error = {};
        if (!state.customerOrderReference) {
            isValid = false;
            error.customerOrderReference = 'Message is required';
        }
        setState((st) => ({
            ...st,
            error: error
        }));
        return isValid;
    };
    const SubmitMessage = async () => {
        if (Validation()) {
            let data = {
                customerOrderReference: state.customerOrderReference,
                headerRecordID: props.headerRecordID
            };
            let res = await postApprovedJob(data);
            if (res.success) {
                showToast(`Job approved successfully`);
                props.onClose(true);
            } else {
                showToast(res.message);
            }
        }
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel shrink className="mandatoryfields">
                        My Reference
                    </InputLabel>
                    <TextBox onChange={handleFieldChange} name="customerOrderReference" value={state.customerOrderReference} />
                    <FormHelperText error>{state.error.customerOrderReference}</FormHelperText>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <SecondaryButton fullWidth onClick={SubmitMessage}>
                        Approve
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default ApproveJobModal;
