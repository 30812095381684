import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Visibility } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';

const DocTableRow = ({ item, showDoc }) => {

    return <tr className='row'>        
        <td>{item.ocrDocName}</td>
        <td className="time-type">{moment(item.createdAt).format("LLL")}</td>
        <td className="created-by">{item.createdBy}</td>
        <td className="actions">
            <div className="icon-button-group">
                <Tooltip title="View">
                    <IconButton color="primary" component="span" onClick={()=>showDoc(item)}>
                        <Visibility />
                    </IconButton>
                </Tooltip>                
            </div>
        </td>
    </tr>
}

const DocTable = ({ rec, showDoc }) => {
    return <>
        <table className="all-documents-header">
            <thead>
                <tr className="header">
                    <th>Document</th>
                    <th className="time-type">Created At</th>
                    <th className="created-by">Created By</th>
                    <th className="actions">Action</th>
                </tr>
            </thead>
        </table>
        <div className="body-table-container custom-scroll">
            <table className="all-documents-body">
                <tbody>
                    {rec.map((r, i) => <DocTableRow key={i} item={r} showDoc={showDoc} />)}                    
                </tbody>
            </table>
        </div>
    </>
}

const JobAllDocs = props => {

    const [state, setState] = useState({
        allDoc: props.allDoc
    });

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const showDoc = item => {
        props.showDoc && props.showDoc(item.ocrDocId, item.docName);
    }
        
    return <><Dialog open={true} onClose={handleClose} maxWidth="md" className="dialog-custom all-documents-modal">
        <DialogTitle>Job Documents
            <div className="title-header-actions">
                <span className="close-icon" onClick={handleClose}>&times;</span>
            </div>
        </DialogTitle>
        <DialogContent>
            <div className="all-documents">
                <DocTable rec={state.allDoc} showDoc={showDoc} />
            </div>            
        </DialogContent>
    </Dialog>        
    </>
}

export default JobAllDocs;