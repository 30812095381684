import React, { useEffect,useRef,useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PrimaryButton, TextBox } from '../../../Core/FormInput';
import { addJobNotes, getJobNotes } from '../../../Core/Service/jobNotes-service';
import moment from 'moment';


const JobSingleNote = props => {
    return <div className="single-note">
        <div className="msg">{props.note.msg}</div>
        <div className="footer">
            {props.note.by} @ {moment(props.note.at).format("LLL")}
        </div>
    </div>
}

const NotesModal = props => {
    const [state, setState] = useState({
        loading: false,
        notes: [],
        newNote: ''
    });

    const notesContainer = useRef(null);
    const handleClose = () => {        
        props.onClose && props.onClose();
    };

    const setNewNote = e=>{
        const newNote = e.target.value;
        setState(st=>({...st, newNote }));
    }

    const addNotesToServer = ()=>{
        addJobNotes(props.jobId, state.newNote).then(res=>{
            if(res.success){
                props.onNotesAdded && props.onNotesAdded(state.newNote);
                setState(st=>({...st, newNote:'' }));
                getJobNotesAndSaveToState();
            }
        })
    }

    const getJobNotesAndSaveToState = () => {
        setState(st=>({...st, loading: true}));
        getJobNotes(props.jobId).then(res=>{
            if(res.success){
                setState(st=>({...st, notes: res.data}));
            }
            notesContainer.current && (notesContainer.current.scrollTop = 0);
        }).finally(()=>{
            setState(st=>({...st, loading: false}));
        })
    }

    useEffect(() => {
        getJobNotesAndSaveToState();
    }, [props.jobId]);
    
    return <Dialog open={true} onClose={handleClose} maxWidth="sm" className="dialog-custom job-notes-modal">
        <DialogTitle>Job Notes
            <div className="title-header-actions">
            <PrimaryButton onClick={addNotesToServer} disabled={state.newNote.length===0} >Add Notes</PrimaryButton>
            <span className="close-icon" onClick={handleClose}>&times;</span>
            </div>
        </DialogTitle>
        <DialogContent>            
            <TextBox label="New Note" required={false} onChange={setNewNote} value={state.newNote} autoFocus={true} />
            <div className="notes-container custom-scroll" ref={notesContainer}>
                {state.loading && <label>Loading...</label>}
                {state.notes.map((n, i)=><JobSingleNote key={i} note={n} />)}                
            </div>
        </DialogContent>        
    </Dialog>
}

export default NotesModal;