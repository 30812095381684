import React, { useEffect, useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import AppContext from "../../../App/AppContext";
import { Link } from "react-router-dom";
import { getauditorDashboard } from "../../../Core/Service/Auditor-services";
import "./dashboard.scss";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const AuditorDashboard = (props) => {
   
    const [state, setState] = useState({
        rows_new: [],
    });
    const { showToast } = useContext(AppContext);
   
    useEffect(async () => {
        let res = await getauditorDashboard();
        if (res.success) {
            setState((st) => ({ ...st, rows_new: res.data }));            
        }
        if (!res.success) {
            showToast(res.message);
        }
        
    }, []);
    const cards = [
        {
            id: 1,
            name: "Current Month",
            color: "#3498db",
            count: state.rows_new.currentMonth,
        },
        {
            id: 2,
            name: "Current Year",
            color: "#2ecc71",
            count: state.rows_new.currentYear,
        },
        {
            id: 3,
            name: "Last Year",
            color: "#dc3545",
            count: state.rows_new.preYear,
        },
        {
            id: 4,
            name: "Total",
            color: "#868e96",
            count: state.rows_new.total,
        },
    ];

    return (
        <div className="auditor-dashboard">
            <Container className="cardGrid" maxWidth="lg">
                <Grid container spacing={4}>
                    {cards.map((option) => (
                        <Grid item key={option.id} xs={6} sm={6} md={6}>
                            <Link
                                style={{ textDecoration: "none" }}
                                color="inherit"
                                to={`/Auditor/${option.name}/${option.id}`}
                            >
                                <Card
                                    className="card"
                                    style={{
                                        backgroundColor: `${option.color}`,
                                    }}
                                >
                                    <CardContent className="cardContent">
                                        <Typography component="h2">
                                            {option.name}
                                        </Typography>
                                        <Typography variant="h5">
                                            {option.count}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};
export default AuditorDashboard;
