import { FormLabel, Grid, MenuItem } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../App/AppContext';
import { DefaultButton, SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { addUpdateQCItem, getDropdown } from '../../../../Core/Service/qc-service';

const AddEditItem = (props) => {
    const [state, setState] = useState({
        QCItemID: props.focusedRow?.qcItemID ? props.focusedRow?.qcItemID : null,
        QCItemVehicleTypeID: props.QCItemVehicleTypeID,
        QCItemTitle: props.focusedRow?.qcItemTitle ? props.focusedRow?.qcItemTitle : '',
        QCItemSequence: props.focusedRow?.qcItemSequence ? props.focusedRow?.qcItemSequence : 0,
        QCItemActive: props.focusedRow?.qcItemActive ? props.focusedRow?.qcItemActive : true,
        QCItemResultListID: props.focusedRow?.qcItemResultListID ? props.focusedRow?.qcItemResultListID : null,

        QCItemResultLists: [],
        errors: [],
    });

    const { showToast } = useContext(AppContext);

    const handleFieldChange = (e) => {
        const name = e.target.name;
        const val = e.target.value;

        setState((st) => ({
            ...st,
            [name]: val
        }))
    }

    const validations = () => {
        const {
            QCItemID,
            QCItemVehicleTypeID,
            QCItemTitle,
            QCItemSequence,
            QCItemActive,
            QCItemResultListID
        } = state;
        let formIsValid = true;
        let errors = {};

        if (QCItemTitle == null || QCItemTitle == '') {
            errors.QCItemTitle = 'QC Item Title field is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            const data = {
                QCItemID: state.QCItemID,
                QCItemVehicleTypeID: state.QCItemVehicleTypeID,
                QCItemTitle: state.QCItemTitle,
                QCItemSequence: state.QCItemSequence,
                QCItemActive: state.QCItemActive,
                QCItemResultListID: state.QCItemResultListID
            }
            let res = await addUpdateQCItem(data);
            if (!res.success) {
                console.error(res);
                showToast("Error occured.")
            } else {
                showToast("QC Item updated successfully")
                props.onClose()
            }
        }
    }

    const fieldChange = (e) => {
        const { name, value } = e.target;

        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleClick = (compon, val) => {
        setState((st) => ({
            ...st,
            [compon]: val
        }))
    }

    const pullDropdown = async () => {
        let result = await getDropdown();

        if (result.success) {
            setState((st) => ({
                ...st,
                QCItemResultLists: result.data,
                QCItemResultListID: st.QCItemResultListID ? st.QCItemResultListID : result.data[0].qcResultListID
            }));
        }
    };

    useEffect(() => {
        pullDropdown();
    }, []);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel required component="legend">QC Item Title</FormLabel>
                    <TextBox value={state.QCItemTitle} name="QCItemTitle" onChange={handleFieldChange} />
                    <span className="mandatoryfields">{state.errors['QCItemTitle']}</span>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel component="legend">Sequence</FormLabel>
                    <TextBox type="number" value={state.QCItemSequence} name="QCItemSequence" onChange={handleFieldChange} />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <FormLabel component="legend">QC Result List</FormLabel>
                    <TextBox
                        style={{ marginTop: 15 }}
                        select
                        name="QCItemResultListID"
                        value={state.QCItemResultListID}
                        onChange={fieldChange}
                        variant="outlined"
                    >
                        {state.QCItemResultLists?.map((option) => (
                            <MenuItem key={option.qcResultListID} value={option.qcResultListID}>
                                {option.qcResultListDescription}
                            </MenuItem>
                        ))}
                    </TextBox>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={2} sm={2}>
                            <FormLabel
                                component="legend"
                                className="btn_add"
                            >
                                Is Active?
                            </FormLabel>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <DefaultButton
                                className={state.QCItemActive ? "btnActive" : "btninActive"}
                                value={state.QCItemActive}
                                name="QCItemActive"
                                onClick={() =>
                                    handleClick("QCItemActive", true)
                                }
                            >
                                Yes
                            </DefaultButton>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <DefaultButton
                                className={state.QCItemActive ? "btninActive" : "btnActiveNo"}
                                value={state.QCItemActive}
                                name="QCItemActive"
                                onClick={() =>
                                    handleClick("QCItemActive", false)
                                }
                            >
                                No
                            </DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
export default AddEditItem;
