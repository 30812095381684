import ButtonGroup, {SpaceType} from './BasicButtonGroup'

const SpaceAroundButtonGroup = props => {
    return <ButtonGroup margin={10} {...props} spaceType={SpaceType.spaceAround}/>
}

const SpaceBetweenButtonGroup = props => {
    return <ButtonGroup margin={10} {...props} spaceType={SpaceType.spaceBetween}/>
}

export { SpaceAroundButtonGroup, SpaceBetweenButtonGroup };