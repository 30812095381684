import React, { useReducer } from 'react';
import { Grid, Typography, InputLabel, RadioGroup, FormControlLabel, Radio, Checkbox, DialogTitle, Chip, Avatar, FormHelperText } from '@material-ui/core';
import { reducer, initState } from '../../../Reducer/action';
import { SecondaryButton, TextBox, YesNoButton } from '../../../../Core/FormInput/index';
import { useState, useCallback } from 'react';
import CommonGijgoGrid from '../../../../Core/Controls/GijgoGrid/index';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LogHistory from './logHistory';
import { postCommunicatorAddActionLog, getActionLogByID } from '../../../../Core/Service/communicator-service';
import { useEffect } from 'react';
import moment from 'moment';
import { useContext } from 'react';
import AppContext from '../../../../App/AppContext';

const EditActionLog = (props) => {
    const { showToast, showModal } = useContext(AppContext);
    const [show, setShow] = useState({
        history: false
    });

    const [state, setState] = useState({
        logVisibleToCustomer: false,
        logHighPriority: false,
        isCustomerUpdate: false,
        isUpdateSms: false,
        newisCustomerUpdate: false,
        newisUpdateSms: false,
        errors: {},
        resultDateAndTime: moment().format('YYYY-MM-DD HH:MM')
    });

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setState((st) => ({ ...st, [name]: checked }));
        if (state.logHighPriority) {
            setState((st) => ({ ...st, newisHighPriorityEmail: state.highPriorityEmail }));
        }
    };

    useEffect(async () => {
        let res = await getActionLogByID(props.id);
        let data = res.data.list[0];
        if (res.success) {
            setState((st) => ({
                ...st,
                logActionTime: data.logActionTime,
                logActioned: data.logActioned,
                logCreatedUserID: data.logCreatedUserID,
                logDateCreated: data.logDateCreated,
                logHeaderRecordID: data.logHeaderRecordID,
                logHighPriority: data.logHighPriority,
                logID: data.logID,
                logText: data.logText,
                logVisibleToCustomer: data.logVisibleToCustomer,
                highPriorityEmail: data.highPriorityEmail,
                newisHighPriorityEmail: data.logHighPriority ? data.highPriorityEmail : ''
            }));
        }
    }, []);

    const handleHistory = () => {
        setShow((st) => ({
            ...st,
            history: true
        }));
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validation = () => {
        let { logResultText } = state;
        let message = '';
        let isValid = true;
        let errors = {};
        if (!logResultText) {
            isValid = false;
            message = 'Log Result is required';
        }
        if (state.isUpdateSms && !state.isPhoneNo) {
            isValid = false;
            errors.isPhoneNo = 'SMS is required';
        }
        if (state.isCustomerUpdate && !state.isUpdateCustomerEmail) {
            isValid = false;
            errors.isUpdateCustomerEmail = 'Email is required';
        }
        setState((st) => ({ ...st, message: message, errors: errors }));
        return isValid;
    };

    const Newvalidation = () => {
        let { newlogText, newlogActionTime } = state;
        let logActionTimeMessage = '';
        let newlogTextmessage = '';
        let errors = {};
        let isValid = true;
        if (!newlogText) {
            isValid = false;
            newlogTextmessage = 'Log Message is required';
        }
        if (!newlogActionTime) {
            isValid = false;
            logActionTimeMessage = 'Reminder Action Date & Time is  required';
        }
        if (state.newisUpdateSms && !state.newisPhoneNo) {
            isValid = false;
            errors.newisPhoneNo = 'SMS is required';
        }
        if (state.newisCustomerUpdate && !state.newisUpdateCustomerEmail) {
            isValid = false;
            errors.newisUpdateCustomerEmail = 'Email is required';
        }
        if (state.newlogHighPriority && !state.newisHighPriorityEmail) {
            isValid = false;
            errors.newisHighPriorityEmail = 'Email is required';
        }
        setState((st) => ({ ...st, newlogTextmessage: newlogTextmessage, logActionTimeMessage: logActionTimeMessage, errors: errors }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let data = {
                logID: props.id,
                logHeaderRecordID: props.headerRecordID,
                logText: state.logText,
                logActionTime: state.logActionTime,
                logResultText: state.logResultText,
                logVisibleToCustomer: state.logVisibleToCustomer,
                logHighPriority: state.logHighPriority,
                isPhoneNo: state.isPhoneNo || '',
                isUpdateCustomerEmail: state.isUpdateCustomerEmail || '',
                isHighPriorityEmail: state.isHighPriorityEmail || ''
            };

            let res = await postCommunicatorAddActionLog(data);
            if (res.success) {
                showToast(`Action Log Closed Successfully`);
                props.onClose(true);
            } else {
                showToast(`Action Log Failed close`);
            }
        }
    };

    const handleNewSubmit = async () => {
        if (Newvalidation()) {
            let data = {
                logID: null,
                logHeaderRecordID: props.headerRecordID,
                logText: state.newlogText,
                logActionTime: state.newlogActionTime,
                logResultText: state.logResultText || '',
                logVisibleToCustomer: state.logVisibleToCustomer,
                logHighPriority: state.logHighPriority,
                isPhoneNo: state.newisPhoneNo || '',
                isUpdateCustomerEmail: state.newisUpdateCustomerEmail || '',
                isHighPriorityEmail: state.newisHighPriorityEmail || ''
            };

            let res = await postCommunicatorAddActionLog(data);
            if (res.success) {
                handleSubmit();
                showToast(`New Action Log Added Successfully`);
                // props.onClose(true);
            } else {
                showToast(`Action Log Failed To New Add`);
            }
        }
    };

    return (
        <DialogComp
            title={
                <DialogTitle style={{ padding: 0 }}>
                    <span>
                        Update Action Log
                        <Chip
                            style={{ marginLeft: 10 }}
                            avatar={
                                <Avatar>
                                    <AssignmentIcon />
                                </Avatar>
                            }
                            label="History"
                            onClick={handleHistory}
                        />
                    </span>
                </DialogTitle>
            }
            onClose={() => props.onClose(false)}
            fullWidth
            maxWidth="lg"
        >
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ fontSize: 17, textAlign: 'left', marginBottom: 10 }}>
                        WIP No:&nbsp; <b> {props.wip}</b> &nbsp;&nbsp; Reg. No:&nbsp; <b> {props.reg}</b>{' '}
                        {/* <span style={{ float: 'right' }}>
                            Key No:&nbsp; <b> 63216</b>{' '}
                        </span> */}
                    </Grid>
                    <Grid container item xs={6} spacing={2} style={{ marginLeft: 2, borderRight: '1px solid rgba(0,0,0,0.09)' }}>
                        <Grid item xs={12}>
                            <InputLabel error>
                                {' '}
                                Action Log Message <span style={{ fontSize: 10 }}>( @ {moment(state.logActionTime).format('LLL')} ) </span>
                            </InputLabel>
                            <br />
                            <Typography variant="body1" style={{ whiteSpace: 'pre-line', cursor: 'pointer', fontWeight: 'bold' }}>
                                {state.logText}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel shrink error>
                                {' '}
                                Result
                            </InputLabel>
                            <br />

                            <TextBox
                                name="logResultText"
                                value={state.logResultText}
                                onChange={handleInput}
                                id="outlined-multiline-static"
                                multiline
                                rows={2}
                                variant="outlined"
                            />
                            <FormHelperText error>{state.message}</FormHelperText>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel shrink style={{ paddingBottom: 4 }}>
                                {' '}
                                Result Completion Date & Time
                            </InputLabel>
                            <TextBox
                                type="datetime-local"
                                name="resultDateAndTime"
                                variant="outlined"
                                fullWidth
                                value={state.resultDateAndTime}
                                onChange={handleInput}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel shrink>
                                <input
                                    type="checkbox"
                                    style={{ cursor: 'pointer' }}
                                    name="isCustomerUpdate"
                                    onChange={handleCheck}
                                    checked={state.isCustomerUpdate}
                                />
                                Email Customer
                            </InputLabel>
                            <TextBox
                                placeholder="Email update to customer"
                                type="email"
                                name="isUpdateCustomerEmail"
                                variant="outlined"
                                fullWidth
                                value={state.isUpdateCustomerEmail}
                                onChange={handleInput}
                                error={state.isCustomerUpdate}
                            />
                            <FormHelperText error>{state.errors.isUpdateCustomerEmail}</FormHelperText>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel shrink>
                                <input type="checkbox" style={{ cursor: 'pointer' }} onChange={handleCheck} name="isUpdateSms" checked={state.isUpdateSms} />
                                Phone Number
                            </InputLabel>
                            <TextBox
                                placeholder="Phone Number"
                                type="tel"
                                name="isPhoneNo"
                                variant="outlined"
                                fullWidth
                                value={state.isPhoneNo}
                                onChange={handleInput}
                                error={state.isUpdateSms}
                            />
                            <FormHelperText error>{state.errors.isPhoneNo}</FormHelperText>
                        </Grid>

                        <Grid item xs={12}>
                            <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmit}>
                                Close Action Log
                            </SecondaryButton>
                        </Grid>
                    </Grid>

                    <Grid container item xs={6} spacing={2} style={{ marginLeft: 10 }}>
                        <Typography variant="h6" style={{ marginLeft: 10 }}>
                            Next Action Log
                        </Typography>
                        <Grid item xs={12}>
                            <InputLabel shrink error>
                                Log Message (
                                <input
                                    type="checkbox"
                                    style={{ cursor: 'pointer' }}
                                    onChange={handleCheck}
                                    name="logVisibleToCustomer"
                                    checked={state.logVisibleToCustomer}
                                />
                                Visible to customer?)
                            </InputLabel>
                            <TextBox
                                placeholder="Log Message"
                                name="newlogText"
                                value={state.newlogText}
                                multiline
                                rows={4}
                                variant="outlined"
                                onChange={handleInput}
                            />
                            <FormHelperText error> {state.newlogTextmessage}</FormHelperText>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel shrink error style={{ paddingBottom: 4 }}>
                                {' '}
                                Reminder Action Date & Time
                            </InputLabel>
                            <TextBox
                                type="datetime-local"
                                name="newlogActionTime"
                                value={state.newlogActionTime}
                                variant="outlined"
                                fullWidth
                                onChange={handleInput}
                            />
                            <FormHelperText error>{state.logActionTimeMessage}</FormHelperText>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel shrink>
                                <input
                                    type="checkbox"
                                    style={{ cursor: 'pointer' }}
                                    onChange={handleCheck}
                                    name="newisUpdateSms"
                                    checked={state.newisUpdateSms}
                                />
                                Phone Number
                            </InputLabel>
                            <TextBox
                                placeholder="Phone Number"
                                type="tel"
                                name="newisPhoneNo"
                                variant="outlined"
                                fullWidth
                                value={state.newisPhoneNo}
                                onChange={handleInput}
                                error={state.newisUpdateSms}
                            />
                            <FormHelperText error>{state.errors.newisPhoneNo}</FormHelperText>
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel shrink>
                                <input
                                    type="checkbox"
                                    style={{ cursor: 'pointer' }}
                                    onChange={handleCheck}
                                    name="logHighPriority"
                                    checked={state.logHighPriority}
                                />
                                High Priority
                            </InputLabel>
                            <TextBox
                                placeholder="Email action log to high priority email"
                                type="email"
                                name="newisHighPriorityEmail"
                                variant="outlined"
                                fullWidth
                                value={state.newisHighPriorityEmail}
                                onChange={handleInput}
                                style={{ paddingTop: 0 }}
                                error={state.logHighPriority}
                            />
                            <FormHelperText error>{state.errors.newisHighPriorityEmail}</FormHelperText>
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel shrink>
                                <input
                                    type="checkbox"
                                    style={{ cursor: 'pointer' }}
                                    name="newisCustomerUpdate"
                                    onChange={handleCheck}
                                    checked={state.newisCustomerUpdate}
                                />
                                Email Customer
                            </InputLabel>
                            <TextBox
                                placeholder="Email update to customer"
                                type="email"
                                name="newisUpdateCustomerEmail"
                                variant="outlined"
                                fullWidth
                                value={state.newisUpdateCustomerEmail}
                                onChange={handleInput}
                                error={state.newisCustomerUpdate}
                            />
                            <FormHelperText error>{state.errors.newisUpdateCustomerEmail}</FormHelperText>
                        </Grid>

                        <Grid item xs={12}>
                            <SecondaryButton className="Submitbtn" fullWidth onClick={handleNewSubmit}>
                                Close & Create New Action Log
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {show.history ? (
                <LogHistory
                    headerRecordID={props.headerRecordID}
                    reg={props.reg}
                    wip={props.wip}
                    onClose={() => {
                        setShow((st) => ({
                            ...st,
                            history: false
                        }));
                    }}
                />
            ) : null}
        </DialogComp>
    );
};

export default EditActionLog;
