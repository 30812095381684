import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import AppContext from '../../../App/AppContext';
import Typography from '@material-ui/core/Typography';
import { TextBox, SecondaryButton, DialogContents, DefaultButton } from '../../../Core/FormInput';
import parse from 'html-react-parser';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { getQCSheet, addQCSheet } from './../../../Core/Service/qualityControl-service';
import moment from 'moment';

const QualityControlSheet = (props) => {
    const { hideModal, hideTopNavAndMenu, showToast } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location?.search?.toLowerCase());
    const navigateToDashboard = urlParams?.get('navigate') === 'true';
    const fromStep = urlParams?.get('fromstep') === 'true';
    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const [state, setState] = useState({
        date: '',
        sign: null,
        imgsign: null,
        wipNumber: '',
        customer: '',
        registration: '',
        qcMessage: '',
        qcHeaderNotes: '',
        headerID: null,
        mileage: '',
        headerRecordID: '',
        qcItems: [],
        qcResultOptions: [],
        fields: {},
        errors: {},
        IssuerSignature: null
    });

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        if (!state.sign) {
            formIsValid = false;
            errors['sign'] = 'Signature cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const Id = props?.match?.params?.jobId || props.jobID;
    useEffect(async () => {
        let result = await getQCSheet(Id);
        const updatedUser = result.data;
        if (updatedUser) {
            setState((st) => ({
                ...st,
                date: moment(updatedUser.dateDueIn).format('DD-MM-YYYY'),
                headerRecordID: updatedUser.headerRecordID,
                registration: updatedUser.registration,
                wipNumber: updatedUser.wipNumber,
                customer: updatedUser.customerName,
                IssuerSignature: updatedUser.qcHeaderSignature,
                sign: updatedUser.qcHeaderSignature,
                imgsign: updatedUser.qcHeaderSignature,
                mileage: updatedUser.qcHeaderMileage,
                qcItems: updatedUser.qcItems,
                qcResultOptions: updatedUser.qcResultOptions,
                qcMessage: updatedUser.qcMessage || '',
                headerID: updatedUser.qcHeaderID,
                qcHeaderNotes: updatedUser.qcHeaderNotes
            }));
        }
    }, []);

    const handleSubmit = async () => {
        if (handleValidation()) {
            const results = state.qcItems.map((row) => ({
                QCResultOptionID: row.qcResultOptionID === null ? '3' : row.qcResultOptionID,
                QCResultCheckID: row.qcResultCheckID === null ? row.qcItemID : row.qcResultCheckID,
                QCResultNotes: row.qcResultNotes
            }));
            let res = await addQCSheet(Id, state.qcHeaderNotes, state.sign, state.mileage, state.headerID, results);
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
            }
            showToast(res.message);
            if (res.success && fromStep) {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }
        }
    };
    const handleClick = (ItemTitle, ItemID, OptionID) => {
        setState((st) => {
            const nst = {
                ...st,
                qcItems: st.qcItems.map((el) =>
                    el.qcItemID == ItemID
                        ? {
                              ...el,
                              qcResultOptionID: OptionID,
                              qcResultCheckID: ItemID
                          }
                        : el
                )
            };
            return nst;
        });
    };
    const NoteChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = {
                ...st,
                qcItems: st.qcItems.map((el) => (el.qcItemID == name ? { ...el, qcResultNotes: value } : el))
            };
            return nst;
        });
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            sign: null
        }));
    };

    const signChange = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            return { ...st, [name]: value, fields: fields };
        });
    };
    return (
        <>
            <DialogContents>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <FormLabel>WIP No</FormLabel>
                        <br></br>
                        <Typography variant="button" style={{ float: 'left' }}>
                            {state.wipNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormLabel component="legend">Registration Number</FormLabel>
                        <br></br>
                        <Typography variant="button" style={{ float: 'left' }}>
                            {state.registration}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormLabel>Date</FormLabel>
                        <br></br>
                        <Typography variant="button" style={{ float: 'left' }}>
                            {state.date}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormLabel component="legend">Customer</FormLabel>
                        <br></br>
                        <Typography variant="button" style={{ float: 'left' }}>
                            {state.customer}
                        </Typography>
                    </Grid>
                    {state.qcItems.map((m) => (
                        <Grid item xs={12} sm={12} style={{ padding: 0 }}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <FormLabel component="legend" className="btn_add setMargin" style={{ textAlign: 'left' }}>
                                            {m.qcItemTitle}
                                        </FormLabel>
                                    </Grid>
                                    {state.qcResultOptions.map((option) => (
                                        <Grid item xs={4} sm={1} style={{ margin: '15' }}>
                                            <DefaultButton
                                                className={
                                                    `${option.qcResultOptionID}` === `${m.qcResultOptionID}` && `${m.qcResultOptionID}` === '1'
                                                        ? 'btnActive'
                                                        : `${option.qcResultOptionID}` === `${m.qcResultOptionID}` && `${m.qcResultOptionID}` === '2'
                                                        ? 'btnActiveNo'
                                                        : `${option.qcResultOptionID}` === `${m.qcResultOptionID}` && `${m.qcResultOptionID}` === '3'
                                                        ? 'btnActiveNA'
                                                        : 'btninActive'
                                                }
                                                value={m.qcResultOptionID}
                                                onClick={() => handleClick(`${m.qcItemTitle}`, `${m.qcItemID}`, `${option.qcResultOptionID}`)}
                                            >
                                                {option.qcResultOptionDescription}
                                            </DefaultButton>
                                        </Grid>
                                    ))}
                                    <Grid item sm={5} xs={12}>
                                        <TextBox className="setMargin" label="Notes" name={m.qcItemID} value={m.qcResultNotes} onChange={NoteChange} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend">Please list any defects found during this quality control</FormLabel>
                        <TextBox name="qcHeaderNotes" value={state.qcHeaderNotes} onChange={fieldChange} multiline rows={3} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                            <Typography style={{ color: 'red' }}>{parse(state.qcMessage)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormLabel component="legend">Mileage</FormLabel>
                        <TextBox value={state.mileage} type="number" name="mileage" onChange={fieldChange} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography className="btn_add" style={{ float: 'right' }}>
                            Q.C carried out by:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CustomeSignPad
                            onChange={signChange}
                            content="Signature"
                            sign={state.IssuerSignature}
                            clearSig={clearSig}
                            name="sign"
                            hideBtn={false}
                        />
                        <span className="mandatoryfields">{state.errors['sign']}</span>
                    </Grid>
                </Grid>
                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                    Submit
                </SecondaryButton>
            </DialogContents>
        </>
    );
};
export default QualityControlSheet;
