import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';

let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};

const baseColumns = [
    { field: 'agedMeasureDescription', sortable: false, title: `Measures`, width: 180, filterable: false },
    {
        field: 'currentProvision',
        title: `Current Provision`,
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    {
        title: `Provision Required`,
        field: 'provisionRequired',
        sortable: true,
        width: 130,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    {
        field: 'provisionDifference',
        sortable: true,
        title: `Provision Difference`,
        width: 130,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    },
    { field: 'totalAmount', sortable: true, title: `Total`, width: 120, showColumnTotal: true, align: 'right', filterable: false },
    { field: 'currentMonthTotal', sortable: true, title: `Current`, width: 120, showColumnTotal: true, align: 'right', filterable: false, isCurrency: true },
    {
        field: 'oneMonthTotal',
        sortable: true,
        title: `1 Month`,
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        isCurrency: true
    },
    { field: 'twoMonthsTotal', sortable: true, title: `2 Month`, width: 120, showColumnTotal: true, align: 'right', filterable: false, isCurrency: true },
    { field: 'threeMonthsTotal', sortable: true, title: `3 Month`, width: 120, showColumnTotal: true, align: 'right', filterable: false, isCurrency: true },
    {
        field: 'fourMonthsAndOverTotal',
        sortable: true,
        title: '4+ Month',
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        isCurrency: true
    },
    {
        field: 'unallocatedItemsTotal',
        title: 'Unallocated',
        width: 120,
        showColumnTotal: true,
        align: 'right',
        filterable: false,
        sortable: false,
        isCurrency: true
    }
];
const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'Aged Measures', path: '/AgedMeasures' },
    { name: measuredesc, active: true }
];
const AgedMeasuresDashBoard = (props) => {
    const history = useHistory();

    const redirectToScreens = (value, record) => {
        history.push({
            pathname: `/AgedMeasures/${record.agedMeasureDescription.replace(/\s/g, '')}`
        });
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid title={value} onClick={() => redirectToScreens(value, record)} style={{ cursor: 'pointer' }}>
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const totalAmountWrapper = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid>
                {addCommas(record.currentMonthTotal + record.oneMonthTotal + record.twoMonthsTotal + record.threeMonthsTotal + record.fourMonthsAndOverTotal)}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let agedMeasureDescriptionCol = tempCols.find((element) => element.field === 'agedMeasureDescription');

        if (agedMeasureDescriptionCol) {
            agedMeasureDescriptionCol.renderer = editButton;
        }
        let totalAmount = tempCols.find((element) => element.field === 'totalAmount');

        if (totalAmount) {
            totalAmount.renderer = totalAmountWrapper;
        }

        return tempCols;
    }, [props.MeasureID]);

    const baseUrl = `AgedMeasures/GetAllBranchByAgedMeasures`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs(props.MeasureDesc)} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isShowTotal={true}
                        hidePagination={true}
                        hideFilterRow={true}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default AgedMeasuresDashBoard;
