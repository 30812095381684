import React, { useContext, useEffect, useState } from 'react';
import { getDashboardInfo } from '../Service/dashbordService';
import { Card, Grid, Typography, Avatar, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChartCommonComp } from '../Chart/barChart';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { PieChart, Pie, Sector, LabelList } from 'recharts';
import { CodeSharp, ContactsOutlined } from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import FilterListIcon from '@material-ui/icons/FilterList';
import _ from 'lodash';
import GijgoGrid from '../../Components/internal/dashboard';
import AppContext from '../../App/AppContext';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ReactDOM from 'react-dom';
import DashboardCharts from './dashboardCharts';
import GridFilterModal from '../../Components/internal/dashboard/FilterModal';
import AssignTech from '../../Components/internal/assign-technician/assignTechnModal';
import DialogComp from '../Modal/dialogModal';
import { getGridFilterList } from '../Service/workflow-service';
import Grow from '@material-ui/core/Grow';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#f5f5f5',
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px',
        margin: 3,
        borderRadius: 3
    }
}));

let config = {
    wipNo: {
        from: 'displayWIPNo',
        to: 'userGridColumnDisplayWIPNo'
    },
    registration: {
        from: 'displayVehicleReg',
        to: 'userGridColumnDisplayVehicleReg'
    },
    makeModel: {
        from: 'displayModel',
        to: 'userGridColumnDisplayModel'
    },
    customerName: {
        from: 'displayCustomer',
        to: 'userGridColumnDisplayCustomer'
    },
    dateDueIn: {
        from: 'displayDateDueIn',
        to: 'userGridColumnDisplayDateDueIn'
    },
    dateDueOut: {
        from: 'displayDateDueOut',
        to: 'userGridColumnDisplayDateDueOut'
    },
    returnReason: {
        from: 'displayReturnReason',
        to: 'userGridColumnDisplayReturnReason'
    },
    branchShortName: {
        from: 'displayBranch',
        to: 'userGridColumnDisplayBranch'
    },
    franchiseName: {
        from: 'displayFranchise',
        to: 'userGridColumnDisplayFranchise'
    },
    serviceText: {
        from: 'displayJobDescription',
        to: 'userGridColumnDisplayJobDescription'
    },
    accountNo: {
        from: 'displayAccount',
        to: 'userGridColumnDisplayAccount'
    },
    createdBy: {
        from: 'displayCreatedBy',
        to: 'userGridColumnDisplayCreatedBy'
    },
    assigned: {
        from: 'displayAssignedDate',
        to: 'userGridColumnDisplayAssignedDate'
    },
    taken: {
        from: 'displayTimeTaken',
        to: 'userGridColumnDisplayTimeTaken'
    },
    status: {
        from: 'displayStatus',
        to: 'UserGridColumnDisplayStatus'
    },
    assignedDays: {
        from: 'displayAssignedDays',
        to: 'UserGridColumnDisplayAssignedDays'
    },
    owningOperator: {
        from: 'displayOwningOperator',
        to: 'UserGridDisplayColumnOwningOperator'
    }
};

const isFalseValue = (val) => {
    if (val == false) {
        return false;
    } else {
        return true;
    }
};

const Dashbord = (props) => {
    const classes = useStyles();
    const { portalSettings, getUserPreference, isAppReaload } = useContext(AppContext);
    // const { t, i18n, langLabel } = Customi18();
    const [checked, setChecked] = useState(false);
    let history = useHistory();

    const [state, setState] = useState({
        getUrl: `WorkflowJobs/GetWorkflow`,
        workflowData: [],
        chartdata: [],
        headerRecord: [],
        tempHeaderRecord: [],
        rawHeaderData: [],
        returnReasonBarData: [],
        returnReasons: [],
        allHeaderDataKeys: [],
        allHeaderData: [],
        workFlowDataBarData: [],
        workFlowDatakeys: [],
        sumOfAllWorkflows: [],
        btnID: 'home',
        tempObj: {},
        showGrid: false,
        total: '',
        headervalbyBranch: [],
        returnReasonColors: [],
        selectListForRR: [],
        selectedBranch: 'Default',
        showLoader: true
    });

    const pullGridFilterList = async () => {
        let data = getUserPreference();
        if (data) {
            // i18n.changeLanguage(data.details[0].languageCode);
            setState((st) => ({
                ...st,
                appbarLocation: data.details[0].appbarLocation || 'T',
                languageCode: data.details[0].languageCode,
                languageData: data.languages,
                tempObj: data.details[0] || {},
                displayPagination: isFalseValue(data.details[0]?.displayPagination)
            }));
        }
    };

    const pullDashboardData = async (workflow, branchName) => {
        let res = await getDashboardInfo();
        if (res.success) {
            let headerRecord = [...res?.data.headerRecord];
            let returnReason = [...res?.data.returnReasons];
            let technicianJobs = [...res?.data.technicianJobs];
            let clockedHours = [...res?.data.clockedHours];

            if (clockedHours.length > 0) {
                let allClockedHours = [];

                clockedHours.map((ch) => {
                    let shouldAdd = true;

                    allClockedHours.map((ach) => {
                        if (ch.workflow == ach.workflow) {
                            ach.hoursTaken += ch.hoursTaken;
                            shouldAdd = false;
                        }
                    });

                    if (shouldAdd) {
                        allClockedHours.push({
                            branch: 'Default',
                            workflow: ch.workflow,
                            workflowID: ch.workflowID,
                            hoursTaken: ch.hoursTaken
                        });
                    }
                });

                clockedHours = [...clockedHours, ...allClockedHours].sort((a, b) => {
                    return b.hoursTaken - a.hoursTaken;
                });
            }

            if (headerRecord.length > 0) {
                // Headers WorkFlows Data...........
                var headerWorkflowData = headerRecord.reduce((acc, obj) => {
                    var index = acc.find((item) => item.workflow === obj.workflow);
                    if (index) {
                        index.total += obj.total;
                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);

                //workflow pieData............
                const workflowData = headerWorkflowData.map((m) => {
                    return { name: m.workflow, value: m.total, workflowID: m.workflowID };
                });
                let sumOfAllWorkflows = 0;
                let totalCount = [...workflowData].forEach(function (item) {
                    sumOfAllWorkflows += item.value;
                });

                // branch pieData
                var branchArr = headerRecord.reduce((acc, obj) => {
                    var index = acc.find((item) => item.branch === obj.branch);
                    if (index) {
                        index.total += obj.total;
                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);

                let chartdata = branchArr.map((m) => ({
                    name: m.branch,
                    value: m.total
                }));

                // ............sum OF By Time For ALL

                const sumOFByTimeForALL = headerRecord.reduce((acc, obj) => {
                    var index = acc.find((item) => item.branch === obj.branch);
                    if (index) {
                        index.lessThan24HoursOld += obj.lessThan24HoursOld;
                        index.between1And5DaysOld += obj.between1And5DaysOld;
                        index.between5And10DaysOld += obj.between5And10DaysOld;
                        index.between10And20DaysOld += obj.between10And20DaysOld;
                        index.between21And30DaysOld += obj.between21And30DaysOld;
                        index.over30DaysOld += obj.over30DaysOld;
                        index.total += obj.total;
                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);

                // ..........By branch and Time BarChart.............

                let headerByTimeBarchart = headerRecord.reduce((acc, obj) => {
                    var index = acc.find((item) => item.workflow === obj.workflow && item.branch === obj.branch);
                    if (index) {
                        index.lessThan24HoursOld += obj.lessThan24HoursOld;
                        index.between1And5DaysOld += obj.between1And5DaysOld;
                        index.between5And10DaysOld += obj.between5And10DaysOld;
                        index.between10And20DaysOld += obj.between10And20DaysOld;
                        index.between21And30DaysOld += obj.between21And30DaysOld;
                        index.over30DaysOld += obj.over30DaysOld;
                        index.total += obj.total;

                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);

                const chartDataForHeader = headerByTimeBarchart.map((p) => {
                    let setObject = {};
                    setObject.name = p.branch;
                    setObject['<24hr'] = p.lessThan24HoursOld;
                    setObject['1-5 days'] = p.between1And5DaysOld;
                    setObject['5-10 days'] = p.between5And10DaysOld;
                    setObject['10-20 days'] = p.between10And20DaysOld;
                    setObject['21-30 days'] = p.between21And30DaysOld;
                    setObject['>30 days'] = p.over30DaysOld;
                    setObject.workflow = p.workflow;
                    setObject.workflowID = p.workflowID;
                    return setObject;
                });

                let result = chartDataForHeader.map((m) => {
                    let newObj;
                    newObj = chartDataForHeader.filter((n) => {
                        if (n.name === m.name) {
                            return m;
                        }
                    });
                    newObj.branch = m.name;
                    return newObj;
                });

                let newResult = Object.values(result.reduce((acc, cur) => Object.assign(acc, { [cur.branch]: cur }), {}));

                let getAllHeaderKeys = newResult.map((m, i) => {
                    let keys = m.map((p, i) => Object.keys(p));
                    return keys;
                });

                setState((st) => ({
                    ...st,
                    workflowData: workflowData,
                    chartdata: chartdata,
                    headerRecord: headerWorkflowData,
                    clockedHours: clockedHours,
                    allHeaderDataKeys: getAllHeaderKeys.length > 0 ? getAllHeaderKeys[0][0] : [],
                    allHeaderData: newResult,
                    sumWorkflows: sumOfAllWorkflows,
                    sumOFByTimeForALL: sumOFByTimeForALL,
                    rawHeaderData: [...res?.data.headerRecord],
                    showLoader: false
                }));
            }

            if (returnReason) {
                // const selectList = returnReason.reduce((a, b) => {
                //     let index = a.find((item) => item.branch === b.branch && item.name === b.name);
                //     if (index) {
                //         return a;
                //     }
                //     a.push({ ...b });
                //     return a;
                // }, []);

                // // let selectListMerge = Object.values(selectList.reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}));

                // if (branchName) {
                //     let selectedFilterData = selectList.filter((n) => n.branch === branchName);
                //     setState((st) => ({
                //         ...st,
                //         selectListForRR: selectedFilterData
                //     }));
                // } else {
                //     setState((st) => ({
                //         ...st,
                //         selectListForRR: Object.values(selectList.reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}))
                //     }));
                // }

                // .............by returnReasonsdata.at..........
                if (workflow && branchName) {
                    let reduceReturnResonArray = returnReason.reduce((acc, obj) => {
                        var index = acc.find((item) => item.branch === obj.branch && item.returnReason === obj.returnReason);
                        if (index) {
                            index.total += obj.total;
                            return acc;
                        }
                        acc.push({ ...obj });
                        return acc;
                    }, []);

                    let RetrunResonArray = [...reduceReturnResonArray].map((m) => {
                        let newObj;
                        newObj = [...reduceReturnResonArray].filter((n) => {
                            if (n.branch === m.branch && n.name === m.name) {
                                return m;
                            }
                        });
                        newObj.name = m.branch;
                        newObj.workflow = m.name;
                        newObj.workflowID = m.workflowID;
                        return newObj;
                    });

                    let reduceArray = RetrunResonArray.filter((m) => m.name === branchName && m.workflow == workflow);

                    let returnReasonsdata = reduceArray.map((m) => {
                        let newObj = {};
                        newObj.name = m.name;
                        m.forEach((n) => {
                            newObj.workflow = n.name;
                            newObj[n.returnReason] = n.total;
                            newObj.workflowID = m.workflowID;
                        });

                        return newObj;
                    });
                    let groupbyRetunreson = Object.values(returnReasonsdata.reduce((acc, cur) => Object.assign(acc, { [cur.workflow]: cur }), {}));

                    let getkeys = returnReasonsdata.map((m, i) => {
                        let keys = Object.keys(m);
                        return keys;
                    });

                    // MERGE KEY ALL ARRAY........
                    let mergeAllarray = getkeys.flat(1);
                    // // // GET REMOVE SAME VALUES  IN ARRAY
                    let values = [...new Set(mergeAllarray)];

                    let RetrunResoncolor = [...res.data.returnReasons].map((m) => {
                        let newObj = {};
                        newObj.returnReason = m.returnReason;
                        newObj.color = m.returnReasonColourCode;
                        return newObj;
                    });

                    setState((st) => ({
                        ...st,
                        workFlowDataBarData: groupbyRetunreson,
                        workFlowDatakeys: values,
                        returnReasonColors: RetrunResoncolor
                    }));
                }

                // For add Branches...................
                else {
                    let RetrunResoncolor = [...res.data.returnReasons].map((m) => {
                        let newObj = {};
                        newObj.returnReason = m.returnReason;
                        newObj.color = m.returnReasonColourCode;
                        return newObj;
                    });
                    let workflowRetunReasonReduce = returnReason.reduce((acc, obj) => {
                        var index = acc.find((item) => item.name === obj.name && item.returnReason === obj.returnReason);
                        if (index) {
                            index.total += obj.total;
                            return acc;
                        }
                        acc.push({ ...obj });
                        return acc;
                    }, []);

                    let workflowArray = [...workflowRetunReasonReduce].map((m) => {
                        let newObj;
                        newObj = [...workflowRetunReasonReduce].filter((n) => {
                            if (n.name === m.name) {
                                return m;
                            }
                        });
                        newObj.name = m.name;
                        return newObj;
                    });

                    let workflowGroupReturnReason = Object.values(workflowArray.reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}));

                    let workflowRetunReasonData = workflowGroupReturnReason.map((m) => {
                        let newObj = {};
                        newObj.workflow = m.name;

                        m.forEach((n) => {
                            newObj[n.returnReason] = n.total;
                            newObj.workflowID = n.workflowID;
                        });

                        return newObj;
                    });

                    let workflowKeys = workflowRetunReasonData.map((m, i) => {
                        let keys = Object.keys(m);
                        return keys;
                    });

                    // MERGE KEY ALL ARRAY........
                    let mergeWorkFlowarray = workflowKeys.flat(1);
                    // GET REMOVE SAME VALUES  IN ARRAY
                    let workFlowvalues = [...new Set(mergeWorkFlowarray)];
                    setState((st) => ({
                        ...st,
                        workFlowDataBarData: workflowRetunReasonData,
                        workFlowDatakeys: workFlowvalues,
                        returnReasonColors: RetrunResoncolor,
                        showLoader: false
                    }));
                }
            }
            if (technicianJobs) {
                let result = technicianJobs.map((m) => {
                    let newObj;
                    newObj = technicianJobs.filter((n) => {
                        if (n.branch === m.branch) {
                            return m;
                        }
                    });
                    newObj.branch = m.branch;
                    return newObj;
                });
                let newResult = Object.values(result.reduce((acc, cur) => Object.assign(acc, { [cur.branch]: cur }), {}));

                setState((st) => ({
                    ...st,
                    technicianJobsData: newResult,
                    showLoader: false
                }));
            }
        }
    };

    useEffect(() => {
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        var workflow = tempData?.workflowReturnReason;
        var branchName = btTimeData?.branchShortName;
        pullDashboardData(workflow, branchName);
    }, [state.selectedBranch, state.selectedWorkFlow]);

    useEffect(() => {
        if (props.location.query) {
            setState((st) => ({
                ...st,
                returnReasonID: props.location.query.returnReasonID,
                filterText: props.location.query.returnReason,
                btnSelectedId: props.location.query?.workFlowId,
                hasQueryValues: true
            }));
        }
    }, []);

    const pullHeaderWorkFlowData = (params) => {
        if (params !== 'Default') {
            if (state.rawHeaderData.length > 0) {
                let indexObj = [...state.rawHeaderData].filter((inx) => inx.branch === params);

                let WorkflowTotal = indexObj.reduce((a, b) => {
                    var index = a.find((x) => x.workflow === b.workflow);
                    if (index) {
                        index.total += b.total;
                        return a;
                    }
                    a.push({ ...b });
                    return a;
                }, []);

                let overallTotal = indexObj.reduce((a, b) => {
                    var index = a.find((x) => x.branch === b.branch);
                    if (index) {
                        index.total += b.total;
                        return a;
                    }
                    a.push({ ...b });
                    return a;
                }, []);

                setState((st) => ({
                    ...st,
                    tempHeaderRecord: WorkflowTotal,
                    sumOfAllWorkflows: overallTotal[0]?.total
                }));
            }
        } else {
            setState((st) => ({
                ...st,
                tempHeaderRecord: state.headerRecord,
                sumOfAllWorkflows: state.sumWorkflows
            }));
        }
    };

    useEffect(() => {
        if (state.selectedBranch) {
            pullHeaderWorkFlowData(state.selectedBranch);
        }
    }, [state.rawHeaderData, state.selectedBranch]);

    useEffect(() => {
        pullGridFilterList();
    }, [isAppReaload]);

    const handleBtnClcik = (val) => {
        props.history.push(`/Job/${val.headerRecordID}/${val.workflowID}`);
    };

    const getbranchByOnchange = (val, params) => {
        setState((st) => ({
            ...st,
            selectedBranch: val,
            selectedWorkFlow: params
        }));
    };

    const handleAssignmentBtnClick = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            showTechAssign: true
        }));
    };

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container>
                <Grid style={{ marginLeft: '13px' }}>
                    <IconButton size="small" onClick={() => handleBtnClcik(val)}>
                        <ArrowForwardIosIcon
                            style={{
                                color: val.returnReasonTextColourCode
                            }}
                            fontSize="small"
                        />
                    </IconButton>
                </Grid>
                <Grid>
                    {val.technicians && (
                        <IconButton title={`${val.technicians}`} size="small" onClick={() => state.currentWorkflowId == 8 && handleAssignmentBtnClick(val)}>
                            <AssignmentIndIcon
                                style={{
                                    color: val.returnReasonTextColourCode
                                }}
                                fontSize="small"
                            />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const baseColumns = [
        {
            title: 'Action',
            field: '',
            renderer: editButton,
            width: 90,
            filterable: false
        },
        { field: 'wipNo', sortable: true, title: `${portalSettings.portalSettingGridWIPNoLabel || 'WIP No'}`, width: 70 },
        { field: 'registration', sortable: true, title: `${portalSettings.portalSettingGridVehicleRegLabel || 'Vehicle Reg'}`, width: 90 },
        { field: 'makeModel', sortable: true, title: `${portalSettings.portalSettingGridModelLabel || 'Model'}`, width: 200 },
        { field: 'customerName', sortable: true, title: `${portalSettings.portalSettingGridCustomerNameLabel || 'Customer Name'}`, width: 200 },
        {
            field: 'dateDueIn',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueInLabel || 'Date Due In'}`,
            // type: 'date',
            type: 'dateTime',
            // flex: 0.6
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueIn != null) {
                    return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'dateDueOut',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueOutLabel || 'Date Due Out'}`,
            // type: 'date',
            type: 'dateTime',
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueOut != null) {
                    return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        { field: 'returnReason', sortable: true, title: `${portalSettings.portalSettingGridReturnReasonLabel || 'Return Reason'}`, width: 190 },
        // { field: 'chassisNumber', sortable: true,title: 'Chassis Number',  },
        { field: 'branchShortName', sortable: true, title: `${portalSettings.portalSettingGridBranchLabel || 'Branch'}`, width: 120 },
        { field: 'franchiseName', sortable: true, title: `${portalSettings.portalSettingGridFranchiseLabel || 'Franchise'}`, width: 160 },
        { field: 'serviceText', sortable: true, title: `${portalSettings.portalSettingGridJobDescriptionLabel || 'Job Description'}`, width: 170 },
        { field: 'accountNo', sortable: true, title: `${portalSettings.portalSettingGridAccountLabel || 'Account'}`, width: 80 },
        { field: 'createdBy', sortable: true, title: `${portalSettings.portalSettingGridCreatedByLabel || 'Created By'}`, width: 120 },
        {
            field: 'assigned',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDateLabel || 'Assigned Date'}`,
            width: 120,
            renderer: function (value, record) {
                if (record.assigned != null) {
                    return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'taken',
            sortable: true,
            title: `${portalSettings.portalSettingGridTimeTakenLabel || 'Time Taken'}`,
            width: 100
        },
        {
            field: 'status',
            sortable: true,
            title: `${portalSettings.portalSettingGridStatusLabel || 'Status'}`,
            width: 100
        },
        {
            field: 'assignedDays',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDaysLabel || 'Assigned Days'}`,
            width: 100
        },
        {
            field: 'owningOperator',
            sortable: true,
            title: `${portalSettings.portalSettingGridOwningOperatorLabel || 'Owning Operator'}`,
            width: 100
        }
    ].map((c) => {
        let obj = {};
        if (c.title !== 'Action') {
            obj.events = {
                click: function (e) {
                    props.history.push(`/Job/${e.data.record.headerRecordID}/${e.data.record.workflowID}`);
                }
            };
            if (Object.keys(state.tempObj).length > 0) {
                obj.hidden = !state.tempObj[config[c.field]?.from];
            } else {
                obj.hidden = false;
            }
        }
        return {
            ...c,
            ...obj,
            headerCssClass: 'gridHeader'
        };
    });

    const buttonClicked = async (btn, fromPage) => {
        // let tempData = {};
        let tempData = JSON.parse(localStorage.getItem('FiltterParams'));
        if (fromPage) {
            localStorage.setItem('FiltterParams', JSON.stringify({ returnReason: btn.returnReason, branchShortName: btn.branch, branchTime: btn.time }));
        } else {
            localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: tempData.branchShortName }));
        }
        localStorage.setItem('currentWorkflowId', btn.workflowID);
        history.push(`/dashgrid`);
    };

    const handleOpen = () => {
        let tempArr = [...baseColumns].map((q) => {
            return {
                field: q.field,
                title: q.title,
                hidden: !q.hidden,
                fieldName: config[q.field]?.to
            };
        });

        //.filter((p) => (p.fieldName !== undefined ? p : null));
        tempArr.push({
            field: 'pagination',
            title: 'Pagination',
            hidden: state.displayPagination,
            fieldName: 'UserGridColumnDisplayPagination'
        });

        setState((st) => ({ ...st, showFilter: true, filterList: tempArr }));
    };

    const closeDialog = (res) => {
        setState((st) => ({ ...st, showFilter: false, showTechAssign: false }));
        res && pullGridFilterList();
    };

    const closeTechAssignDialog = (res) => {
        setState((st) => ({ ...st, showTechAssign: false, isReload: res ? new Date() : null }));
    };

    return (
        <div style={{ padding: 10, background: '' }}>
            {state.showLoader ? (
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            height: 'calc(100vh - 300px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                </div>
            ) : (
                <Grid container spacing={1}>
                    <Avatar
                        style={{
                            background: '#BA4546',
                            color: 'white',
                            height: 35,
                            width: 35,
                            position: 'absolute',
                            right: 12,
                            bottom: 12,
                            border: '6px solid #daa3a6',
                            cursor: 'pointer',
                            zIndex: 100
                        }}
                        onClick={handleOpen}
                    >
                        <FilterListIcon />
                    </Avatar>
                    {state.tempHeaderRecord.map((m, i) => {
                        return (
                            <Grid item style={{ padding: `0px 5px 0px ${i === 0 ? '10px' : '0px'}` }}>
                                <Card
                                    className={classes.root}
                                    key={i}
                                    style={{
                                        background: state.btnID === m.workflowID ? '#BA4546' : 'white',
                                        color: state.btnID === m.workflowID ? 'white' : 'black',
                                        padding: 0
                                    }}
                                    onClick={() => buttonClicked(m)}
                                >
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            cursor: 'pointer',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            // color: 'rgb(255, 71, 87)',
                                            overflow: 'hidden',
                                            padding: '5px 20px'
                                        }}
                                    >
                                        <span style={{ fontSize: 14, marginBottom: 6 }}> {m?.workflow}</span>
                                        <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}> {m?.total}</div>
                                    </div>
                                </Card>
                            </Grid>
                        );
                    })}
                    <Grid item style={{ padding: '0px 5px 0px 0px' }}>
                        <Card
                            className={classes.root}
                            style={{
                                background: state.total === 'total' ? '#BA4546' : 'white',
                                color: state.total === 'total' ? 'white' : 'black',
                                padding: 0
                            }}
                            onClick={() => buttonClicked({ workflowID: 'All' })}
                        >
                            <div
                                style={{
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    paddingBottom: 10,
                                    padding: '5px 20px'
                                }}
                            >
                                <span style={{ fontSize: 14, marginBottom: 6 }}> Total</span>
                                <div style={{ fontSize: 30, fontWeight: 600, fontFamily: `"Public Sans", sans-serif` }}>{state.sumOfAllWorkflows}</div>
                            </div>
                        </Card>
                    </Grid>
                    <DashboardCharts state={state} onChartClickHandler={buttonClicked} getbranchByOnchange={getbranchByOnchange} />
                </Grid>
            )}
            {state.showFilter ? (
                <DialogComp title="My Preferences" maxWidth="sm" onClose={() => closeDialog(false)}>
                    <GridFilterModal
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        languageData={state.languageData}
                    />
                </DialogComp>
            ) : null}
            {state.showTechAssign ? (
                <DialogComp title="Assigned Technician" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <AssignTech jobId={state.jobId} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default Dashbord;
