import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { TextBox } from '../../../../../Core/FormInput';
import { ExpBaseURL } from '../../../../../Core/Service/http-calls';
import { AppStorage } from '../../../../../Core/Service/storage-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import GetWIPHeader from '../../GetWIPDetails';
import DialogComp from '../../../../../Core/Controls/Dialog/DialogComp';
import { getAgedMeasuresViewDocument } from '../../../../../Core/Service/ReportScreens-service';
import ViewPdfModal from '../../../../../Core/Modal/PdfViweModal';
import ReactDOM from 'react-dom';

const baseColumns = [
    { field: 'bn', title: `Branch`, width: 180 },
    { field: 'sold', title: `Sold Hours`, width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'taken', title: `Taken Hours`, width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'eff', title: `% Efficiency`, width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'sale', title: `Sale Value`, width: 150, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'cost', title: `Cost Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'profit', title: `Profit`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'marginPerc', title: 'Margin %', width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator },
    { field: 'recRate', title: 'Rec Rate %', width: 120, align: 'right', filterable: false, sortable: false, renderer: formatters.ToFixedSeparator }
];

var opColumns = [
    { title: 'Operator', field: 'opName', width: 90, sortable: true },
    { title: 'Sold', field: 'sold', width: 70, sortable: true, align: 'right' },
    { title: 'Taken', field: 'taken', width: 70, sortable: true, align: 'right' },
    { title: '% Eff.', field: 'eff', width: 66, sortable: true, align: 'right' },
    { title: 'Sale Value', field: 'sale', width: 80, sortable: true, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { title: 'Cost Value', field: 'cost', width: 80, sortable: true, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { title: 'Profit', field: 'profit', width: 80, sortable: true, align: 'right' },
    { title: 'Margin %', field: 'marginPerc', width: 60, sortable: true, align: 'right' },
    { title: 'Rec Rate %', field: 'recRate', width: 60, sortable: true, align: 'right' }
];

const wipColumns = [
    { title: 'WIP', field: 'wipNumber', width: 70, sortable: true },
    { title: 'Invoice No', field: 'invoiceNumber', width: 70, sortable: true },
    { title: 'Customer', field: 'customersName', width: 130, sortable: true },
    { title: 'Sold', field: 'sold', width: 70, sortable: true, align: 'right' },
    { title: 'Taken', field: 'taken', width: 70, sortable: true, align: 'right' },
    { title: '% Eff.', field: 'eff', width: 70, sortable: true, align: 'right' },
    { title: 'Sale Value', field: 'sale', width: 60, sortable: true, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { title: 'Cost Value', field: 'sost', width: 70, sortable: true, renderer: formatters.CurrencyThousandSeparator, align: 'right' },
    { title: 'Profit', field: 'profit', width: 70, sortable: true, align: 'right' },
    { title: 'Margin %', field: 'marginPerc', width: 70, sortable: true, align: 'right' },
    { title: 'Rec Rate %', field: 'recRate', width: 70, sortable: true, align: 'right' }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Services', active: true },
    { name: 'Invoiced Hours', active: true }
];
var mainGrid, opGrid, wipsGrid;
var $ = window.$;

const InvoicedHours = (props) => {
    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate
    });
    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    let token = AppStorage.getToken();

    const mainGridOnSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        mainGrid.render(obj);
    };

    const opGridOnSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        opGrid.render(obj);
    };
    const onSuccessWipFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        wipsGrid.render(obj);
    };
    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showWIPDetails: true, wipNumber: record.wipNumber, company: record.company }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const documentHandler = async (record) => {
        let res = await getAgedMeasuresViewDocument(record.invoiceFileName);
        if (res) {
            setState((st) => ({ ...st, showPdfModal: true, pdfFileData: res }));
        } else {
            console.error(res.message);
        }
    };

    const docEditButton = (value, record) => {
        const spn = document.createElement('span');
        let isFile = record.invoiceFileName;
        const lnk = (
            <span style={{ cursor: isFile ? 'pointer' : 'text' }} onClick={isFile ? () => documentHandler(record) : ''}>
                {value}
            </span>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const NewWipcolumns = useMemo(() => {
        const tempCols = [...wipColumns];
        let wipNumberCol = tempCols.find((element) => element.field === 'wipNumber');
        let ViewDocCol = tempCols.find((element) => element.field === 'invoiceNumber');
        if (ViewDocCol) {
            ViewDocCol.renderer = docEditButton;
        }
        if (wipNumberCol) {
            wipNumberCol.renderer = editButton({});
        }
        return tempCols;
    });
    const pullInvoiceHours = async () => {
        window.$(`#tb_InvoicedHours`).grid('destroy', true, true);

        mainGrid = $('#tb_InvoicedHours').grid({
            primaryKey: 'branchID',

            dataSource: {
                url: `${ExpBaseURL}/api/services/DailyInvoicedHours?StartDate=${state.startDate}&EndDate=${state.endDate}`,
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: mainGridOnSuccessFunc
            },
            detailTemplate: '<div><table/></div>',
            columns: baseColumns,
            pager: { limit: 15 }
        });
        mainGrid.on('dataBound', function (e, records, totalRecords) {
            var sumsold = 0,
                sumtaken = 0,
                sumeff = 0,
                sumsale = 0,
                sumcost = 0,
                sumprofit = 0,
                summarginPerc = 0,
                sumrecRate = 0,
                $tfoot;
            $.each(records, function () {
                sumsold += parseFloat(this.sold);
                sumtaken += parseFloat(this.taken);
                sumeff += parseFloat(this.eff);
                sumsale += parseFloat(this.sale);
                sumcost += parseFloat(this.cost);
                sumprofit += parseFloat(this.profit);
                summarginPerc += parseFloat(this.marginPerc);
                sumrecRate += parseFloat(this.recRate);
            });
            $tfoot = mainGrid.children('tfoot');
            $tfoot.children('tr[data-role="TotalAmount"]').remove();
            $tfoot.children('tr[data-role="pager"]').remove();
            $tfoot.prepend(
                '<tr data-role="TotalAmount"><th></th><th>Total</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumsold) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumtaken) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumeff) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumsale) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumcost) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumprofit) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(summarginPerc) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumrecRate) +
                    '</th></tr>'
            );
        });
        mainGrid.on('detailExpand', function (e, $detailWrapper, branchID) {
            opGrid = $detailWrapper.find('table').grid({
                params: { branchID: branchID },
                primaryKey: 'opno',

                dataSource: {
                    url: `${ExpBaseURL}/api/services/DailyInvoicedHours?StartDate=${state.startDate}&EndDate=${state.endDate}`,
                    headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                    success: opGridOnSuccessFunc
                },
                columns: opColumns,
                pager: { limit: 10 },
                detailTemplate: '<div><table/></div>'
            });

            opGrid.on('detailExpand', function (e, $detailWrapper, opno) {
                wipsGrid = $detailWrapper.find('table').grid({
                    params: { branchID: branchID, opno: opno },
                    primaryKey: 'id',

                    dataSource: {
                        url: `${ExpBaseURL}/api/services/DailyInvoicedHours?StartDate=${state.startDate}&EndDate=${state.endDate}`,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host
                        },
                        success: onSuccessWipFunc
                    },
                    columns: NewWipcolumns,
                    pager: { limit: 10 }
                });
            });

            opGrid.on('detailCollapse', function (e, $detailWrapper, opno) {
                $detailWrapper.find('table').grid('destroy', true, true);
            });
        });
        mainGrid.on('detailCollapse', function (e, $detailWrapper, id) {
            $detailWrapper.find('table').grid('destroy', true, true);
        });
        mainGrid.reload();
    };
    useEffect(() => {
        pullInvoiceHours();
    }, [state.startDate, state.endDate]);
    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            {state.showWIPDetails && (
                <DialogComp title="WIP Details" onClose={() => setState((st) => ({ ...st, showWIPDetails: false }))} maxWidth="xl" fullWidth>
                    <GetWIPHeader wipNumber={state.wipNumber} company={state.company} />
                </DialogComp>
            )}
            {state.showPdfModal ? (
                <DialogComp title="View PDF" maxWidth="md" onClose={() => setState((st) => ({ ...st, showPdfModal: false }))} fullWidth>
                    <ViewPdfModal pdfFileData={state.pdfFileData} />
                </DialogComp>
            ) : null}
            <Grid item container spacing={1} xs={12} style={{ padding: '0px 10px' }}>
                <Grid item container spacing={1} xs={12} style={{ textAlign: 'left' }}>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="report-screen-gijgo">
                    <Grid item xs={12}>
                        <table id={`tb_InvoicedHours`}></table>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default InvoicedHours;
