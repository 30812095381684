import React from 'react';
import { moment } from 'moment';
import { Grid, FormLabel, Breadcrumbs } from '@material-ui/core';
import { TextBox } from './../../../../../Core/FormInput';
import { Link } from 'react-router-dom';
import NestedGijgoGrid from './../../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import BreadCrumbs from './../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import CommonGijgoGrid from './../../../../../Core/Controls/GijgoGrid/index';
import ReportGijgoGrid from '../../GijgoForReportsScreen';

var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let addCommas = (val) => {
    return val ? `£${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
};

const baseColumns = [
    { title: 'Stock No', field: 'sn', width: 80, sortable: true },
    { title: 'Status', field: 's', width: 80, sortable: true },
    // { title: 'Reg Number', field: 'rn', width: 90, sortable: true },
    {
        title: 'Progress',
        field: 'pd',
        width: 80,
        sortable: true
    },
    { title: 'Model', field: 'mc', width: 90, sortable: true },
    {
        title: 'Vehicle Description',
        field: 'vd',
        width: 250,
        sortable: true,

        cssClass: 'text-center'
    },
    {
        title: 'Comm Number',
        field: 'cn',
        width: 110,
        sortable: true
    },

    {
        title: 'Cons Date',
        field: 'cd',
        width: 90,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Build Date',
        field: 'bd',
        width: 90,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Days',
        field: 'd',
        width: 90,
        sortable: true
    },
    {
        title: 'Sold By',
        field: 'bn',
        width: 100,
        sortable: true
    },
    {
        title: 'Notes',
        field: 'n',
        width: 150,
        sortable: true
    },
    {
        title: 'Latest Loc',
        field: 'rsc',
        width: 90,
        sortable: true
        // renderer: function (value, record) {
        //     return addCommas(value);
        // }
    },
    {
        title: 'Vehicle Group',
        field: 'mac',
        width: 100,
        sortable: true
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'New Vehicle Orders', active: true }
];

const VehicleNewOrder = (props) => {
    const baseUrl = `Sales/NewVehicleOrders`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container>
                <Grid container>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={baseColumns}
                        getUrl={baseUrl}
                        displayPagination={true}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default VehicleNewOrder;
