import React, { useEffect, useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AppSelect, PrimaryButton, TextBox, SecondaryCheckbox } from '../../../Core/FormInput';
import { getByWorkflowIdAndHeaderId, processsReturnReasonForJob } from '../../../Core/Service/returnReason-service';
import MenuItem from '@material-ui/core/MenuItem';
import { find } from 'lodash';

const ReturnToWorkflow = (props) => {
    const [state, setState] = useState({
        loading: true,
        selectedReason: undefined,
        returnReasons: [],
        opNotes: '',
        technicianList: [],
        selectedTechnician: '',
        errors: {},
        nextWorkflowName: null
    });

    const handleClose = () => {
        props.onClose && props.onClose(false);
    };

    const setNotes = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, opNotes: vl }));
    };

    const setReturnReason = (e) => {
        const vl = e.target.value;
        let data = state.returnReasons.find((d) => d.returnReasonID === vl);
        setState((st) => ({ ...st, selectedReason: vl, nextWorkflowName: data?.nextWorkflowName }));
    };

    const setTechnician = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, selectedTechnician: vl }));
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (state.technicianList?.length > 0 && !state.selectedTechnician) {
            formIsValid = false;
            errors['selectedTechnician'] = 'Please select technician';
        }

        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const processReturn = async () => {
        if (handleValidation()) {
            const res = await processsReturnReasonForJob(props.jobId, state.selectedReason, state.opNotes, state.selectedTechnician, state.resetSignature);
            props.showToast && props.showToast(res.message);
            if (res.success) {
                props.onReturnProcessed && props.onReturnProcessed(state.opNotes);
                props.onClose(true);
            }
        }
    };

    useEffect(() => {
        getByWorkflowIdAndHeaderId(props.workflowId, props.jobId)
            .then((res) => {
                if (res.success) {
                    const ops = res.data.returnReasons.map((rr) => ({ value: rr.returnReasonID, text: rr.returnReasonDescription, ...rr }));
                    setState((st) => ({ ...st, returnReasons: ops }));
                } else {
                    props.showToast && props.showToast(res.message);
                }
            })
            .finally(() => {
                setState((st) => ({ ...st, loading: false }));
            });
    }, []);
    useEffect(() => {
        if (state.selectedReason) {
            const Technician = state.returnReasons?.filter((m) => m.value == state.selectedReason);
            let Tech = [];
            if (Technician[0]?.technicians != '') {
                Tech = JSON.parse(Technician[0]?.technicians);
            }
            setState((st) => ({ ...st, technicianList: Tech, selectedTechnician: '' }));
        }
    }, [state.selectedReason]);

    const handleCheck = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="md" className="dialog-custom return-reason-modal">
            <DialogTitle>
                Return Reason
                <div className="title-header-actions">
                    <span className="close-icon" onClick={handleClose}>
                        &times;
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <label>Return Reason:</label>
                <br />
                <TextBox select name="selectedReason" value={state.selectedReason} variant="outlined" onChange={setReturnReason} autoFocus={true}>
                    {state.returnReasons?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.text}
                        </MenuItem>
                    ))}
                </TextBox>
                {state.technicianList?.length > 0 && (
                    <>
                        <label>Technician:</label>
                        <br />
                        <TextBox select name="selectedTechnician" value={state.selectedTechnician} variant="outlined" onChange={setTechnician} autoFocus={true}>
                            {state.technicianList?.map((option) => (
                                <MenuItem key={option.JobID} value={option.JobID}>
                                    {option.Technician}
                                </MenuItem>
                            ))}
                        </TextBox>
                        <span className="mandatoryfields">{state.errors['selectedTechnician']}</span>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox checked={state.resetSignature} onChange={handleCheck} name="resetSignature" value={state.resetSignature} />
                            }
                            label="Reset Signature"
                        />
                    </>
                )}
                <TextBox rows={5} multiline label="New Note" required={false} onChange={setNotes} value={state.opNotes} />
                {state.nextWorkflowName && <label>Return Job to : {state.nextWorkflowName} </label>}
                <br />
                <PrimaryButton fullWidth disabled={!state.selectedReason} onClick={processReturn}>
                    Submit
                </PrimaryButton>
            </DialogContent>
        </Dialog>
    );
};

export default ReturnToWorkflow;
