import React, { useEffect, useState, useMemo, useContext } from 'react';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IconButton, Tooltip } from '@material-ui/core';
import { BasicButtonGroup } from '../../../Core/ButtonGroup';
import { getWorkflows } from '../../../Core/Service/workflow-service';
import AppContext from '../../../App/AppContext';

import moment from 'moment';
import './dashboard.scss';

import { makeStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

import Grid from '@material-ui/core/Grid';
import ReactDOM from 'react-dom';
import GijgoGrid from './';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogComp from '../../../Core/Modal/dialogModal';
import GridFilterModal from './FilterModal';
import { getGridFilterList, postGridFilterList } from '../../../Core/Service/workflow-service';
import AssignTech from '../assign-technician/assignTechnModal.jsx';
import { Customi18 } from '../../../Core/Controls/i18react/customei18';
import _ from 'lodash';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LogHistory from '../Communicator/OpenActionLogs/logHistory';
import { AppStorage } from '../../../Core/Service/storage-service';
import JobPriorityModal from './jobPriorityModal';
import LowPriorityIcon from '@material-ui/icons/LowPriority';

const useStyles = (props) =>
    makeStyles((thems) => {
        return {
            ...props
        };
    });

let config = {
    wipNo: {
        from: 'displayWIPNo',
        to: 'userGridColumnDisplayWIPNo'
    },
    registration: {
        from: 'displayVehicleReg',
        to: 'userGridColumnDisplayVehicleReg'
    },
    makeModel: {
        from: 'displayModel',
        to: 'userGridColumnDisplayModel'
    },
    customerName: {
        from: 'displayCustomer',
        to: 'userGridColumnDisplayCustomer'
    },
    dateDueIn: {
        from: 'displayDateDueIn',
        to: 'userGridColumnDisplayDateDueIn'
    },
    dateDueOut: {
        from: 'displayDateDueOut',
        to: 'userGridColumnDisplayDateDueOut'
    },
    returnReason: {
        from: 'displayReturnReason',
        to: 'userGridColumnDisplayReturnReason'
    },
    branchShortName: {
        from: 'displayBranch',
        to: 'userGridColumnDisplayBranch'
    },
    franchiseName: {
        from: 'displayFranchise',
        to: 'userGridColumnDisplayFranchise'
    },
    serviceText: {
        from: 'displayJobDescription',
        to: 'userGridColumnDisplayJobDescription'
    },
    accountNo: {
        from: 'displayAccount',
        to: 'userGridColumnDisplayAccount'
    },
    createdBy: {
        from: 'displayCreatedBy',
        to: 'userGridColumnDisplayCreatedBy'
    },
    assigned: {
        from: 'displayAssignedDate',
        to: 'userGridColumnDisplayAssignedDate'
    },
    taken: {
        from: 'displayTimeTaken',
        to: 'userGridColumnDisplayTimeTaken'
    },
    status: {
        from: 'displayStatus',
        to: 'UserGridColumnDisplayStatus'
    },
    assignedDays: {
        from: 'displayAssignedDays',
        to: 'UserGridColumnDisplayAssignedDays'
    },
    owningOperator: {
        from: 'displayOwningOperator',
        to: 'UserGridDisplayColumnOwningOperator'
    }
};

const isFalseValue = (val) => {
    if (val == false) {
        return false;
    } else {
        return true;
    }
};

let Dashboard = (props) => {
    const [state, setState] = useState({
        workflowButtons: [],
        rows: [],
        filteredRows: [],
        totalRec: 0,
        filterText: '',
        btnSelectedId: props.location.query?.workFlowId || localStorage.getItem('currentWorkflowId'),
        currentWorkflowId: props.location.query?.workFlowId || localStorage.getItem('currentWorkflowId'),
        returnReasonID: props.location.query?.returnReasonID || '',
        hasQueryValues: props.location.query?.returnReasonID ? true : false,
        tempObj: {},
        filterList: [],
        displayPagination: true,
        showTechAssign: false,
        isReload: null,
        timeID: JSON.parse(localStorage.getItem('FiltterParams'))?.branchTime || '',
        logHistory: false,
        displayJobPriorityButton: false
    });

    // const { t, i18n, langLabel } = Customi18();
    const [loading, setLoading] = React.useState(false);
    const [WindowWidths, WindowHeights] = useWindowSize();
    const { portalSettings, getUserPreference, isAppReaload } = useContext(AppContext);
    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed() && portalSettings.portalSettingCommunicatorEnabled;

    const pullGridFilterList = async () => {
        // let timeID = JSON.parse(localStorage.getItem('FiltterParams'));
        let data = getUserPreference();
        if (data) {
            setState((st) => ({
                ...st,
                appbarLocation: data.details[0].appbarLocation || 'T',
                languageCode: data.details[0].languageCode,
                languageData: data.languages,
                tempObj: data.details[0] || {},
                displayPagination: isFalseValue(data.details[0]?.displayPagination)
                // timeID: timeID?.branchTime
            }));
        }
    };

    // useEffect(() => {
    //     if (props.location.query) {
    //         setState((st) => ({
    //             ...st,
    //             returnReasonID: props.location.query.returnReasonID,
    //             filterText: props.location.query.returnReason,
    //             btnSelectedId: props.location.query?.workFlowId,
    //             hasQueryValues: true
    //         }));
    //     }
    // }, []);

    useEffect(async () => {
        setLoading(true);
        let res = await getWorkflows();
        if (res.success) {
            const buttons = res.data.map((w) => ({
                id: w.id,
                label: w.workflowName,
                displayJobPriorityButton: w.displayJobPriorityButton == 'Y' ? true : false
            }));
            setState((st) => ({ ...st, workflowButtons: buttons }));
        }
    }, []);

    useEffect(() => {
        if (isAppReaload) {
            pullGridFilterList();
        }
    }, [isAppReaload]);

    var colorCodeObj = {};
    state.filteredRows.forEach((c) => {
        colorCodeObj[[c.id]] = {
            background: c.returnReasonColourCode,
            color: c.returnReasonTextColourCode
        };
    });
    const classes = useStyles(colorCodeObj)();

    const handleBtnClcik = (val) => {
        props.history.push(`/Job/${val.headerRecordID}/${val.workflowID}`);
    };

    const handleAssignmentBtnClick = (val) => {
        // props.history.push(`/assign-technician/${val.headerRecordID}`);

        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            showTechAssign: true
        }));
    };

    const handleShowJobPriorityBtnClick = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            workFlowData: val,
            showJobPriority: true
        }));
    };

    const handleLogHistory = (val) => {
        setState((st) => ({
            ...st,
            logHistory: true,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNo
        }));
    };

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <Grid>
                    <IconButton size="small" onClick={() => handleBtnClcik(val)}>
                        <ArrowForwardIosIcon
                            style={{
                                color: val.returnReasonTextColourCode
                            }}
                            fontSize="small"
                        />
                    </IconButton>
                </Grid>
                {isCommunicatorAllowed && (
                    <Grid style={{ marginLeft: '13px 5px' }}>
                        <Tooltip title="Log History">
                            <IconButton size="small" onClick={() => handleLogHistory(val)}>
                                <AssignmentIcon
                                    style={{
                                        color: val.returnReasonTextColourCode
                                    }}
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}

                <Grid>
                    <Tooltip title="Set Priority">
                        <IconButton size="small" onClick={state.displayJobPriorityButton ? () => handleShowJobPriorityBtnClick(val) : () => false}>
                            {val.priorityNumber == 999999 && state.displayJobPriorityButton ? (
                                <LowPriorityIcon
                                    style={{
                                        color: val.priorityNumber < 999999 ? 'green' : val.returnReasonTextColourCode
                                    }}
                                    fontSize="small"
                                />
                            ) : (
                                <>{val.priorityNumber < 999999 ? val.priorityNumber : ''}</>
                            )}
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid>
                    {val.technicians && (
                        // <Tooltip title={val.technicians} aria-label="add" disableFocusListener>
                        <IconButton title={`${val.technicians}`} size="small" onClick={() => state.currentWorkflowId == 8 && handleAssignmentBtnClick(val)}>
                            <AssignmentIndIcon
                                style={{
                                    color: val.returnReasonTextColourCode
                                }}
                                fontSize="small"
                            />
                        </IconButton>
                        // </Tooltip>
                    )}
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const baseColumns = [
        {
            title: 'Action',
            field: '',
            renderer: editButton,
            width: 95,
            filterable: false
        },
        { field: 'wipNo', sortable: true, title: `${portalSettings.portalSettingGridWIPNoLabel || 'WIP No'}`, width: 70 },
        { field: 'registration', sortable: true, title: `${portalSettings.portalSettingGridVehicleRegLabel || 'Vehicle Reg'}`, width: 90 },
        { field: 'makeModel', sortable: true, title: `${portalSettings.portalSettingGridModelLabel || 'Model'}`, width: 200 },
        { field: 'customerName', sortable: true, title: `${portalSettings.portalSettingGridCustomerNameLabel || 'Customer Name'}`, width: 200 },
        {
            field: 'dateDueIn',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueInLabel || 'Due Date In'}`,
            // type: 'date',
            type: 'dateTime',
            // flex: 0.6
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueIn != null) {
                    return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'dateDueOut',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueOutLabel || 'Date Due Out'}`,
            // type: 'date',
            type: 'dateTime',
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueOut != null) {
                    return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        { field: 'returnReason', sortable: true, title: `${portalSettings.portalSettingGridReturnReasonLabel || 'Return Reason'}`, width: 190 },
        // { field: 'chassisNumber', sortable: true,title: 'Chassis Number',  },
        { field: 'branchShortName', sortable: true, title: `${portalSettings.portalSettingGridBranchLabel || 'Branch'}`, width: 120 },
        { field: 'franchiseName', sortable: true, title: `${portalSettings.portalSettingGridFranchiseLabel || 'Franchise'}`, width: 160 },
        { field: 'serviceText', sortable: true, title: `${portalSettings.portalSettingGridJobDescriptionLabel || 'Job Description'}`, width: 170 },
        { field: 'accountNo', sortable: true, title: `${portalSettings.portalSettingGridAccountLabel || 'Account'}`, width: 80 },
        { field: 'createdBy', sortable: true, title: `${portalSettings.portalSettingGridCreatedByLabel || 'Created By'}`, width: 120 },
        {
            field: 'assigned',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDateLabel || 'Assigned Date'}`,
            width: 120,
            renderer: function (value, record) {
                if (record.assigned != null) {
                    return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'taken',
            sortable: true,
            title: `${portalSettings.portalSettingGridTimeTakenLabel || 'Time Taken'}`,
            width: 100
        },
        {
            field: 'status',
            sortable: true,
            title: `${portalSettings.portalSettingGridStatusLabel || 'Status'}`,
            width: 100
        },
        {
            field: 'assignedDays',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDaysLabel || 'Assigned Days'}`,
            width: 100
        },
        {
            field: 'owningOperator',
            sortable: true,
            title: `${portalSettings.portalSettingGridOwningOperatorLabel || 'Owning Operator'}`,
            width: 100
        }
    ];

    if (state.currentWorkflowId === 'All') {
        baseColumns.push({
            field: 'wn',
            sortable: true,
            isShow: true,
            title: `Workflow Name`,
            width: 160
        });
    }

    let Column = baseColumns.map((c) => {
        let obj = {};
        if (c.title !== 'Action') {
            obj.events = {
                click: function (e) {
                    props.history.push(`/Job/${e.data.record.headerRecordID}/${e.data.record.workflowID}`);
                }
            };
            if (Object.keys(state.tempObj).length > 0 && !c.isShow) {
                obj.hidden = !state.tempObj[config[c.field]?.from];
            } else {
                obj.hidden = false;
            }
        }
        return {
            ...c,
            ...obj,
            headerCssClass: 'gridHeader'
        };
    });

    const buttonClicked = async (btn) => {
        localStorage.setItem('currentWorkflowId', btn.id);
        setState((st) => {
            const newSt = { ...st };
            if (!newSt.hasQueryValues) {
                newSt.returnReasonID = '';
            } else {
                newSt.hasQueryValues = false;
            }
            newSt.currentWorkflowId = btn.id;
            newSt.currentWorkflowLabel = btn.label;
            newSt.displayJobPriorityButton = btn.displayJobPriorityButton || false;
            return newSt;
        });
    };

    const handleOpen = () => {
        let tempArr = [...Column]
            .map((q) => {
                return {
                    field: q.field,
                    title: q.title,
                    hidden: !q.hidden,
                    fieldName: config[q.field]?.to,
                    isShow: q.isShow ? q.isShow : false
                };
            })
            .filter((p) => (!p.isShow ? p : null));
        tempArr.push({
            field: 'pagination',
            title: 'Pagination',
            hidden: state.displayPagination,
            fieldName: 'UserGridColumnDisplayPagination'
        });

        setState((st) => ({ ...st, showFilter: true, filterList: tempArr }));
    };

    const closeDialog = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showFilter = false;
            newSt.showTechAssign = false;
            if (res) newSt.isReload = new Date();
            return newSt;
        });
        res && pullGridFilterList();
    };

    const closeTechAssignDialog = (res) => {
        setState((st) => ({ ...st, showTechAssign: false, showJobPriority: false, isReload: res ? new Date() : null }));
    };

    // let getUrl = `WorkflowJobs/GetWorkflow/${state.currentWorkflowId === 'All' ? '' : state.currentWorkflowId}?${
    //     state.returnReasonID ? `ReturnReasonID=${state.returnReasonID}` : ''
    // }${state.timeID ? `&Time=${state.timeID}` : ''}`;

    useEffect(() => {
        let tempUrl = `WorkflowJobs/GetWorkflow/${state.currentWorkflowId === 'All' ? '' : state.currentWorkflowId}?${
            state.returnReasonID ? `ReturnReasonID=${state.returnReasonID}` : ''
        }${state.timeID ? `&Time=${state.timeID}` : ''}`;
        setState((st) => {
            const newSt = { ...st };
            newSt.getUrl = tempUrl;
            newSt.isReload = new Date();
            return newSt;
        });
    }, [state.currentWorkflowId, state.returnReasonID, state.timeID, state.displayJobPriorityButton]);

    return (
        <div className="filter-Container">
            <BasicButtonGroup
                margin={WindowWidths < 750 ? 6 : 10}
                buttons={state.workflowButtons}
                onClick={buttonClicked}
                btnSelectedId={state.btnSelectedId || null}
                className="left-align-buttons"
                allGrid={true}
            />
            <div className="filter-div">
                <IconButton aria-label="filter" onClick={handleOpen}>
                    <FilterListIcon />
                </IconButton>
            </div>
            <div>
                {state.currentWorkflowId && !_.isEmpty(state.tempObj) && (
                    <GijgoGrid
                        columns={Column}
                        getUrl={state.getUrl}
                        currentWorkflowLabel={state.currentWorkflowLabel}
                        currentWorkflowId={state.currentWorkflowId}
                        filterList={state.tempObj}
                        displayPagination={state.displayPagination}
                        isReload={state.isReload}
                    />
                )}
            </div>
            {state.showFilter ? (
                <DialogComp title="My Preferences" maxWidth="sm" onClose={() => closeDialog(false)}>
                    <GridFilterModal
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        languageData={state.languageData}
                    />
                </DialogComp>
            ) : null}
            {state.showTechAssign ? (
                <DialogComp title="Assigned Technician" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <AssignTech jobId={state.jobId} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
            {state.showJobPriority ? (
                <DialogComp title="Job Priority" maxWidth="xs" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <JobPriorityModal jobId={state.jobId} workFlowData={state.workFlowData} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
            {state.logHistory ? (
                <LogHistory
                    headerRecordID={state.headerRecordID}
                    reg={state.reg}
                    wip={state.wip}
                    onClose={() => {
                        setState((st) => ({
                            ...st,
                            logHistory: false
                        }));
                    }}
                />
            ) : null}
        </div>
    );
};
export default Dashboard;
