import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { TextBox } from '../../../../Core/FormInput';
import { ExpBaseURL } from '../../../../Core/Service/http-calls';
import { AppStorage } from '../../../../Core/Service/storage-service';
import formatters from '../../../../Core/Grid/inputFormatter';
import GetWIPHeader from '../GetWIPDetails';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';

const baseColumns = [
    { field: 'bn', title: `Branch`, width: 180 },
    {
        field: 'grossProfit',
        title: `Gross Profit `,
        width: 120,
        align: 'right',
        filterable: false,
        sortable: false,
        renderer: formatters.CurrencyThousandSeparator
    },
    { field: 'grossProfitPercent', title: `Gross Profit % `, width: 120, align: 'right', filterable: false, sortable: false },
    { field: 'saleValue', title: `Sale Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'costValue', title: `Cost Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    {
        field: 'retailPrice',
        title: 'Retail Price',
        width: 120,
        align: 'right',
        renderer: formatters.CurrencyThousandSeparator,
        filterable: false,
        sortable: false
    },
    {
        field: 'surchargeValue',
        title: 'Surcharge Value',
        width: 120,
        align: 'right',
        renderer: formatters.CurrencyThousandSeparator,
        filterable: false,
        sortable: false
    }
];

var opColumns = [
    { title: 'Operator', field: 'opName', width: 90, sortable: true, align: 'left' },
    {
        field: 'grossProfit',
        title: `Gross Profit `,
        width: 120,
        align: 'right',
        filterable: false,
        sortable: false,
        renderer: formatters.CurrencyThousandSeparator
    },
    { field: 'grossProfitPercent', title: `Gross Profit % `, width: 120, align: 'right', filterable: false, sortable: false },
    { field: 'saleValue', title: `Sale Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    { field: 'costValue', title: `Cost Value`, width: 120, align: 'right', renderer: formatters.CurrencyThousandSeparator, filterable: false, sortable: false },
    {
        field: 'retailPrice',
        title: 'Retail Price',
        width: 120,
        align: 'right',
        renderer: formatters.CurrencyThousandSeparator,
        filterable: false,
        sortable: false
    },
    {
        field: 'surchargeValue',
        title: 'Surcharge Value',
        width: 120,
        align: 'right',
        renderer: formatters.CurrencyThousandSeparator,
        filterable: false,
        sortable: false
    }
];

const wipColumns = [
    { field: 'wn', title: 'WIP', width: 75 },
    { field: 'inv', title: 'Invoice No', width: 90 },
    { field: 'ind', title: 'Date', width: 90, renderer: formatters.MonthShortFormatter },
    { field: 'an', title: `Account No`, width: 60 },
    { field: 'cn', title: `Customer`, width: 80 },
    { field: 'rp', title: `Retail Price`, width: 60, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'sv', title: `Sale`, width: 80, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'cv', title: `Cost`, width: 80, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'sur', title: `Surcharge`, width: 80, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'gp', title: `Gross Profit`, width: 100, align: 'right', renderer: formatters.CurrencyThousandSeparator },
    { field: 'gpp', title: `Profit %`, width: 80 }
];

const crumbs = (desc) => [
    { name: 'Home', path: '/' },
    { name: 'Parts', active: true },
    { name: desc, active: true }
];
var mainGrid, opGrid, wipsGrid;
var $ = window.$;

const PartSales = (props) => {
    let startDate = moment().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');

    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
        showWIPDetails: false,
        wipNumber: '',
        company: ''
    });
    const fieldChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    let token = AppStorage.getToken();

    const mainGridOnSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        mainGrid.render(obj);
    };

    const opGridOnSuccessFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        opGrid.render(obj);
    };
    const onSuccessWipFunc = function (response) {
        var obj = {
            records: response.data.list,
            total: response.data.total
        };
        wipsGrid.render(obj);
    };

    const pullPartsSalesList = async () => {
        window.$(`#tb_PartsSales`).grid('destroy', true, true);

        mainGrid = $('#tb_PartsSales').grid({
            primaryKey: 'branchID',

            dataSource: {
                url: `${ExpBaseURL}/api/Parts/PartsSales?dc=${props.code || ''}&StartDate=${state.startDate}&EndDate=${state.endDate}`,
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: mainGridOnSuccessFunc
            },
            detailTemplate: '<div><table/></div>',
            columns: baseColumns,
            pager: { limit: 15 }
        });
        mainGrid.on('dataBound', function (e, records, totalRecords) {
            var sumgrossProfit = 0,
                sumgrossProfitPercent = 0,
                sumsaleValue = 0,
                sumcostValue = 0,
                sumretailPrice = 0,
                sumsurchargeValue = 0,
                $tfoot;
            $.each(records, function () {
                sumgrossProfit += parseFloat(this.grossProfit);
                sumgrossProfitPercent += parseFloat(this.grossProfitPercent);
                sumsaleValue += parseFloat(this.saleValue);
                sumcostValue += parseFloat(this.costValue);
                sumretailPrice += parseFloat(this.retailPrice);
                sumsurchargeValue += parseFloat(this.surchargeValue);
            });
            $tfoot = mainGrid.children('tfoot');
            $tfoot.children('tr[data-role="TotalAmount"]').remove();
            $tfoot.children('tr[data-role="pager"]').remove();
            $tfoot.prepend(
                '<tr data-role="TotalAmount"><th></th><th>Total</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumgrossProfit) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.ToFixedSeparator(sumgrossProfitPercent) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumsaleValue) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumcostValue) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumretailPrice) +
                    '</th><th style="text-align:right;font-weight:bold;">' +
                    formatters.CurrencyThousandSeparator(sumsurchargeValue) +
                    '</th></tr>'
            );
        });
        mainGrid.on('detailExpand', function (e, $detailWrapper, branchID) {
            opGrid = $detailWrapper.find('table').grid({
                params: { BranchID: branchID },
                primaryKey: 'opno',

                dataSource: {
                    url: `${ExpBaseURL}/api/Parts/PartsSales?dc=${props.code || ''}&StartDate=${state.startDate}&EndDate=${state.endDate}`,
                    headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                    success: opGridOnSuccessFunc
                },
                columns: opColumns,
                pager: { limit: 10 },
                detailTemplate: '<div><table/></div>'
            });

            opGrid.on('detailExpand', function (e, $detailWrapper, opno) {
                wipsGrid = $detailWrapper.find('table').grid({
                    params: { BranchID: branchID, opno: opno },
                    primaryKey: 'id',

                    dataSource: {
                        url: `${ExpBaseURL}/api/Parts/PartsSales?dc=${props.code || ''}&StartDate=${state.startDate}&EndDate=${state.endDate}`,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host
                        },
                        success: onSuccessWipFunc
                    },
                    columns: WipRendererNColumns
                });
            });

            opGrid.on('detailCollapse', function (e, $detailWrapper, opno) {
                $detailWrapper.find('table').grid('destroy', true, true);
            });
        });

        mainGrid.on('detailCollapse', function (e, $detailWrapper, id) {
            $detailWrapper.find('table').grid('destroy', true, true);
        });
        mainGrid.reload();
    };
    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid onClick={() => setState((st) => ({ ...st, showWIPDetails: true, wipNumber: record.wn, company: record.co }))} style={{ cursor: 'pointer' }}>
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const WipRendererNColumns = useMemo(() => {
        const tempCols = [...wipColumns];
        let wipNumberCol = tempCols.find((element) => element.field === 'wn');
        if (wipNumberCol) {
            wipNumberCol.renderer = editButton({});
        }
        return tempCols;
    });
    useEffect(() => {
        pullPartsSalesList();
    }, [state.startDate, state.endDate]);
    return (
        <div>
            <BreadCrumbs crumbs={crumbs(props.desc)} />
            {state.showWIPDetails && (
                <DialogComp title="WIP Details" onClose={() => setState((st) => ({ ...st, showWIPDetails: false }))} maxWidth="xl" fullWidth>
                    <GetWIPHeader wipNumber={state.wipNumber} company={state.company} />
                </DialogComp>
            )}
            <Grid item container spacing={1} xs={12} style={{ padding: '0px 10px' }}>
                <Grid item container spacing={1} xs={12} style={{ textAlign: 'left' }}>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormLabel component="legend">Start Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                        <FormLabel component="legend">End Date</FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="report-screen-gijgo">
                    <Grid item xs={12}>
                        <table id={`tb_PartsSales`}></table>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default PartSales;
