import React, { useEffect, useState, useContext, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { EvenSpaceButtonGroup } from '../../../Core/ButtonGroup';
import './TechnicianApp_screen.css';
import moment from 'moment';
import { getTechnicianJobs } from '../../../Core/Service/technician-service';
import { IconButton, Tooltip } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { find } from 'lodash';
import { getWorkflows } from '../../../Core/Service/workflow-service';
import Grid from '@material-ui/core/Grid';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import TechnicianGijgoGrid from './Grid';
import ReactDOM from 'react-dom';
import GridFilterModal from '../../internal/dashboard/FilterModal';
import { getGridFilterList, postGridFilterList } from '../../../Core/Service/workflow-service';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogComp from '../../../Core/Modal/dialogModal';
import GridInfo from './GridInfo';
import { getAllowedWorkflow } from '../../../Core/Service/workflow-service';

let currentTechnicianId;

const useStyles = (props) =>
    makeStyles((thems) => {
        return {
            ...props
        };
    });

const isFalseValue = (val) => {
    if (val == false) {
        return false;
    } else {
        return true;
    }
};

const technicianJobBucket = [
    { id: 1, label: 'Jobs Assigned To You' },
    { id: 3, label: 'Jobs Assigned To Others' }
    // { id: 2, label: "All other Jobs" },
];

let config = {
    wipNo: {
        from: 'displayWIPNo',
        to: 'userGridColumnDisplayWIPNo'
    },
    registration: {
        from: 'displayVehicleReg',
        to: 'userGridColumnDisplayVehicleReg'
    },
    makeModel: {
        from: 'displayModel',
        to: 'userGridColumnDisplayModel'
    },
    customerName: {
        from: 'displayCustomer',
        to: 'userGridColumnDisplayCustomer'
    },
    dateDueIn: {
        from: 'displayDateDueIn',
        to: 'userGridColumnDisplayDateDueIn'
    },
    dateDueOut: {
        from: 'displayDateDueOut',
        to: 'userGridColumnDisplayDateDueOut'
    },
    returnReason: {
        from: 'displayReturnReason',
        to: 'userGridColumnDisplayReturnReason'
    },
    branchShortName: {
        from: 'displayBranch',
        to: 'userGridColumnDisplayBranch'
    },
    franchiseName: {
        from: 'displayFranchise',
        to: 'userGridColumnDisplayFranchise'
    },
    serviceText: {
        from: 'displayJobDescription',
        to: 'userGridColumnDisplayJobDescription'
    },
    accountNo: {
        from: 'displayAccount',
        to: 'userGridColumnDisplayAccount'
    },
    createdBy: {
        from: 'displayCreatedBy',
        to: 'userGridColumnDisplayCreatedBy'
    },
    chassisNumber: {
        from: 'displayChassisNumber',
        to: 'UserGridColumnDisplayChassisNumber'
    },
    userName: {
        from: 'displayIsTechnician',
        to: 'UserGridColumnDisplayIsTechnician'
    },
    assigned: {
        from: 'displayAssignedDate',
        to: 'userGridColumnDisplayAssignedDate'
    },
    taken: {
        from: 'displayTimeTaken',
        to: 'userGridColumnDisplayTimeTaken'
    },
    status: {
        from: 'displayStatus',
        to: 'UserGridColumnDisplayStatus'
    },
    assignedDays: {
        from: 'displayAssignedDays',
        to: 'UserGridColumnDisplayAssignedDays'
    },
    owningOperator: {
        from: 'displayOwningOperator',
        to: 'UserGridDisplayColumnOwningOperator'
    }
};

const TechnicianApp = (props) => {
    const [state, setState] = useState({
        technicianWorkflowId: undefined,
        rows: [],
        totalRec: 0,
        id: null,
        btnid: null,
        currentTechnicianId: null,
        tempObj: {},
        filterList: [],
        displayPagination: true,
        filterReturnReasone: [],
        technicianJobBucket: []
    });
    const { portalSettings, getUserPreference, isAppReaload } = useContext(AppContext);

    let checkCurrentId = state.currentTechnicianId == 1 || state.currentTechnicianId == 3;
    let getURL = checkCurrentId ? `TechnicianJobs/${state.currentTechnicianId}` : `WorkflowJobs/GetWorkflow/${state.currentTechnicianId}`;

    const pullAndUpdateState = async () => {
        getWorkflows().then((res) => {
            if (res.success) {
                const wf = find(res.data, (w) => w.isTechnicianWorkflow);
                if (wf) {
                    setState((st) => ({
                        ...st,
                        technicianWorkflowId: wf.id
                    }));
                }
            }
        });
    };

    const pullGridFilterList = async () => {
        let data = getUserPreference();
        if (data) {
            setState((st) => ({
                ...st,
                appbarLocation: data.details[0].appbarLocation || 'T',
                languageCode: data.details[0].languageCode,
                languageData: data.languages,
                tempObj: data.details[0] || {},
                displayPagination: isFalseValue(data.details[0]?.displayPagination)
            }));
        }
    };

    useEffect(async () => {
        pullAndUpdateState();
        let res = await getAllowedWorkflow();
        let data = res?.data?.list?.table;
        setState((st) => ({
            ...st,
            technicianJobBucket: [{ id: 1, label: 'Jobs Assigned To You' }, { id: 3, label: 'Jobs Assigned To Others' }, ...data]
        }));
    }, []);

    const handleBtnClcik = (m) => {
        props.history.push(`/Job/${m.headerRecordID}/${checkCurrentId ? state.technicianWorkflowId : state.currentTechnicianId}/${m.id}/${m.ownJob}`);
    };

    useEffect(() => {
        if (isAppReaload) {
            pullGridFilterList();
        }
    }, [isAppReaload]);

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <Grid>
                    {state.technicianWorkflowId && (
                        <IconButton size="small" onClick={() => handleBtnClcik(val)}>
                            <ArrowForwardIosIcon fontSize="small" />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column_new = [
        {
            field: '',
            title: 'Action',
            sortable: false,
            filterable: false,
            renderer: editButton,
            width: 80
        },
        {
            field: 'wipNo',
            title: `${portalSettings.portalSettingGridWIPNoLabel || 'WIP No'}`,
            sortable: true,
            width: 70
        },
        {
            field: 'registration',
            title: `${portalSettings.portalSettingGridVehicleRegLabel || 'Vehicle Reg'}`,
            sortable: true,
            width: 90
        },
        {
            field: 'makeModel',
            title: `${portalSettings.portalSettingGridModelLabel || 'Model'}`,
            sortable: true,
            width: 200
        },
        {
            field: 'customerName',
            title: `${portalSettings.portalSettingGridCustomerNameLabel || 'Customer Name'}`,
            sortable: true,
            width: 200
        },
        {
            field: 'dateDueIn',
            title: `${portalSettings.portalSettingGridDateDueInLabel || 'Date Due In'}`,
            sortable: true,
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueIn != null) {
                    return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'dateDueOut',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueOutLabel || 'Date Due Out'}`,
            // type: 'date',
            type: 'dateTime',
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueOut != null) {
                    return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'returnReason',
            title: `${portalSettings.portalSettingGridReturnReasonLabel || 'Return Reason'}`,
            sortable: true,
            width: 190
        },
        {
            field: 'chassisNumber',
            title: `${portalSettings.portalSettingGridChassisNumberLabel || 'Chassis Number'}`,
            sortable: true,
            width: 200
        },
        {
            field: 'branchShortName',
            title: `${portalSettings.portalSettingGridBranchLabel || 'Branch'}`,
            sortable: true,
            width: 120
        },
        { field: 'franchiseName', sortable: true, title: `${portalSettings.portalSettingGridFranchiseLabel || 'Franchise'}`, width: 160 },
        { field: 'serviceText', sortable: true, title: `${portalSettings.portalSettingGridJobDescriptionLabel || 'Job Description'}`, width: 170 },
        { field: 'accountNo', sortable: true, title: `${portalSettings.portalSettingGridAccountLabel || 'Account'}`, width: 80 },
        {
            field: 'userName',
            title: 'Technician',
            sortable: true,
            width: 180
        },
        {
            field: 'assigned',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDateLabel || 'Assigned Date'}`,
            width: 120,
            renderer: function (value, record) {
                if (record.assigned != null) {
                    return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'taken',
            sortable: true,
            title: `${portalSettings.portalSettingGridTimeTakenLabel || 'Time Taken'}`,
            width: 100
        },
        {
            field: 'status',
            sortable: true,
            title: `${portalSettings.portalSettingGridStatusLabel || 'Status'}`,
            width: 100
        },
        {
            field: 'assignedDays',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDaysLabel || 'Assigned Days'}`,
            width: 100
        },
        {
            field: 'owningOperator',
            sortable: true,
            title: `${portalSettings.portalSettingGridOwningOperatorLabel || 'Owning Operator'}`,
            width: 100
        }
    ].map((c) => {
        let obj = {};
        if (c.title !== 'Action') {
            obj.events = {
                click: function (e) {
                    props.history.push(
                        `/Job/${e.data.record.headerRecordID}/${checkCurrentId ? state.technicianWorkflowId : state.currentTechnicianId}/${e.data.record.id}/${
                            e.data.record.ownJob
                        }`
                    );
                }
            };
            if (Object.keys(state.tempObj).length > 0) {
                obj.hidden = !state.tempObj[config[c.field].from];
            } else {
                obj.hidden = false;
            }
        }
        return {
            ...c,
            ...obj,
            headerCssClass: 'gridHeader'
        };
    });

    if (state.currentTechnicianId == 1) {
        let isTechnicianIndex = column_new.findIndex((q) => q.field == 'userName');
        column_new.splice(isTechnicianIndex, 1);
    }

    const buttonClicked = async (btn) => {
        setState((st) => ({
            ...st,
            currentTechnicianId: btn.id,
            currentWorkflowLabel: btn.label
        }));
    };

    const closeDialog = (res) => {
        setState((st) => ({ ...st, showFilter: false }));

        res && pullGridFilterList();
    };

    const handleOpen = () => {
        let tempArr = [...column_new].map((q) => {
            return {
                field: q.field,
                title: q.title,
                hidden: !q.hidden,
                fieldName: config[q.field]?.to
            };
        });
        //  .filter((p) => (p.fieldName !== undefined ? p : null));
        tempArr.push({
            field: 'pagination',
            title: 'Pagination',
            hidden: state.displayPagination,
            fieldName: 'UserGridColumnDisplayPagination'
        });
        setState((st) => ({ ...st, showFilter: true, filterList: tempArr }));
    };

    const getfilterReturnReasone = (returnReasonelist) => {
        const uniqueReturnReasonelist = [...new Map(returnReasonelist.map((item) => [item['returnReason'], item])).values()];
        setState((st) => ({ ...st, filterReturnReasone: uniqueReturnReasonelist }));
    };
    return (
        <div className="filter-Container">
            <GridInfo filterReturnReasone={state.filterReturnReasone} />
            <EvenSpaceButtonGroup buttons={state.technicianJobBucket} onClick={buttonClicked} />

            <div className="filter-div">
                <IconButton aria-label="filter" onClick={handleOpen}>
                    <FilterListIcon />
                </IconButton>
            </div>
            {state.currentTechnicianId && state.technicianWorkflowId && (
                <div>
                    <TechnicianGijgoGrid
                        columns={column_new}
                        //  getUrl={`TechnicianJobs/${state.currentTechnicianId}`}
                        getUrl={getURL}
                        currentWorkflowLabel={state.currentWorkflowLabel}
                        filterList={state.tempObj}
                        displayPagination={state.displayPagination}
                        getfilterReturnReasone={getfilterReturnReasone}
                    />
                </div>
            )}
            {state.showFilter ? (
                <DialogComp title="My Preferences" maxWidth="sm" onClose={() => closeDialog(false)}>
                    <GridFilterModal
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        languageData={state.languageData}
                        handleCancel={closeDialog}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default TechnicianApp;
