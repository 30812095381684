import React from 'react';
import ReactDOM from 'react-dom';
import './App/index.css';
import App from './App/App.jsx';
import reportWebVitals from './App/reportWebVitals';
import './App/i18n';

const DevApp = () => {
    return (
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
};

const ProdApp = () => {
    return <App />;
};

ReactDOM.render(process.env.NODE_ENV === 'production' ? <ProdApp /> : <DevApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
