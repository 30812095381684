import React, { useMemo, useState } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel, IconButton } from '@material-ui/core';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import formatters from '../../../../../Core/Grid/inputFormatter';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReactDOM from 'react-dom';
import GetWIPHeader from '../../GetWIPDetails';
import DialogComp from '../../../../../Core/Controls/Dialog/DialogComp';
import { SquareButton } from '../../../../../Core/FormInput/AppButton';
import EditIcon from '@material-ui/icons/Edit';
import ReportingWipLogsModal from '../../ReportingWipLogsModal';
import MotHistoryScreen from '../../MotHistoryScreen';

const baseColumns = [
    { field: 'action', sortable: false, title: `Action`, width: 60, filterable: false },
    { field: 'wn', title: `WIP`, width: 60 },
    { field: 'an', title: `Account`, width: 60 },
    { field: 'companyName', title: `Company`, width: 80 },
    { field: 'cn', title: `Customer`, width: 80 },
    { field: 'rn', title: `Reg No`, width: 80 },
    { field: 'vd', title: `Details`, width: 100 },
    { field: 'po', title: `Parts`, width: 80, isCurrency: true },
    { field: 'lh', title: `Hours`, width: 60 },
    { field: 'lo', title: `Labour`, width: 80, isCurrency: true },
    { field: 'so', title: `Sub`, width: 80, isCurrency: true },
    {
        field: 'dr',
        title: `Raised`,
        width: 75,
        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        field: 'dd',
        title: `Due In`,
        width: 75,
        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    { field: 'am', title: `Months`, width: 60 },
    { field: 'ad', title: `Days`, width: 50 },
    { field: 'fn', title: `Franchise`, width: 80 },
    { field: 'opn', title: `Operator`, width: 80 },
    { field: 'bn', title: `Branch`, width: 80 },
    { field: 'dn', title: `Department`, width: 80 }
    // {
    //     field: 'provisionRequired',
    //     title: `Current Provision`,
    //     width: 120,
    //     showColumnTotal: true,
    //     align: 'right',
    //     filterable: false,
    //     sortable: false,
    //     isCurrency: true
    // },
];
const crumbs = () => [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Aged Measures WorkIn Progress', active: true }
];

const getCrumbs = (ParamMeasureID, ParamMeasuredesc, ParamBranchName, ParamMonth, ParamUnallocated) => {
    let temp = {};
    let final = [
        { name: 'Home', path: '/' },
        { name: 'Sales', active: true }
    ];
    if (ParamMeasureID == 1) {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/WorkInProgress' };
    } else if (ParamMeasureID == 17) {
        temp = { name: `${ParamMeasuredesc}`, path: '/AgedMeasures/WorkInProgressbyDepartement' };
    } else {
        temp = { name: `Aged Measures WorkIn Progress`, active: true };
    }
    final = [...final, { ...temp }];
    if (ParamBranchName || ParamMonth) {
        temp = {
            name: `${ParamBranchName} ${ParamMonth > 0 ? `(${ParamMonth} ${ParamMonth == 1 ? 'Month' : 'Months'})` : ''} ${
                ParamUnallocated > 0 ? `(Unallocated)` : ''
            }`,
            active: true
        };
        final = [...final, { ...temp }];
    }

    return final;
};

const AgedMeasuresWorkingProgress = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamMeasureID = params.get('MeasureID') || '';
    let ParamMeasuredesc = params.get('measuredesc') || '';
    let ParamMonth = params.get('month') || '';
    let ParamBranchName = params.get('bn') || '';
    let ParamBranchId = params.get('branchId') || '';
    let ParamUnallocated = params.get('unallocated') || '';
    let ParamDepartmentDescription = params.get('DepartmentDes') || '';
    let ParamAccCode = params.get('accCode') || '';
    let ParamCashAccountTypeID = params.get('cashAccountTypeID') || '';
    let ParamIsGroupingById = params.get('isGroupingById') || '';
    const [state, setState] = useState({
        showWIPDetails: false,
        wipNumber: '',
        company: '',
        showMotHistory: false
    });

    const reportingWIPLogsHandler = (record) => {
        setState((st) => ({
            ...st,
            showNotesModal: true,
            hid: record.hid,
            wipNo: record.wn
        }));
    };

    const editButton = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container alignItems="center">
                <SquareButton
                    style={{
                        backgroundColor: record.rhid ? '#183B68' : '#868e96',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => reportingWIPLogsHandler(record)}
                >
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const WIPButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showWIPDetails: true, wipNumber: record?.wn, company: record?.company }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const regNumberRendrer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showMotHistory: true, regNo: record?.rn, FranchiseCode: record?.franchiseCode }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');
        let wipNumberCol = tempCols.find((element) => element.field === 'wn');
        let regNumberCol = tempCols.find((element) => element.field === 'rn');
        if (wipNumberCol) {
            wipNumberCol.renderer = WIPButton({});
        }
        if (regNumberCol) {
            regNumberCol.renderer = regNumberRendrer({});
        }
        if (actionCol) {
            actionCol.renderer = editButton;
        }
        return tempCols;
    }, []);

    const closeNotesHandler = (res) => {
        setState((st) => {
            let newSt = { ...st };
            if (res) {
                newSt.isReload = new Date();
            } else {
                newSt.showNotesModal = false;
            }
            return newSt;
        });
    };

    const baseUrl = `Sales/GetAllAgedMeasuresWorkInProgress?BranchShortName=${ParamBranchId}&mont=${ParamMonth}&DepartmentDescription=${ParamDepartmentDescription}`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={getCrumbs(ParamMeasureID, ParamMeasuredesc, ParamBranchName, ParamMonth, ParamUnallocated)} />
            <Grid item container spacing={1} xs={12}>
                {state.showWIPDetails && (
                    <DialogComp title="WIP Details" onClose={() => setState((st) => ({ ...st, showWIPDetails: false }))} maxWidth="xl" fullWidth>
                        <GetWIPHeader wipNumber={state.wipNumber} company={state.company} />
                    </DialogComp>
                )}
                {state.showMotHistory && (
                    <DialogComp
                        title="MOT History"
                        onClose={() => setState((st) => ({ ...st, showMotHistory: false }))}
                        maxWidth="md"
                        fullWidth
                        overflow={'auto'}
                    >
                        <MotHistoryScreen regNo={state.regNo} FranchiseCode={state.FranchiseCode} hideSeachText={true} />
                    </DialogComp>
                )}
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        getUrl={baseUrl}
                        isReload={state.isReload}
                    />
                </Grid>
            </Grid>
            {state.showNotesModal && <ReportingWipLogsModal onClose={closeNotesHandler} hid={state.hid} wipNo={state.wipNo} />}
        </div>
    );
};

export default AgedMeasuresWorkingProgress;
