import { useContext, useEffect, useState } from 'react';
import { NativeSelect } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import AppContext from '../../App/AppContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appSelectBtnGrp: {
        marginRight: 4,
        '& button': {
            textTransform: 'unset',
            wordWrap: 'break-word',
            '&.Mui-selected': {
                color: (props) => `${props.foregroundColour || 'white'}`,
                backgroundColor: (props) => `${props.backgroundColour || 'rgb(83, 175, 80)'}`
            },
            '&.Mui-selected:hover': {
                color: (props) => `${props.foregroundColour || 'white'}`,
                backgroundColor: (props) => `${props.backgroundColour || 'rgb(83, 175, 80)'}`
            },
            '&.MuiToggleButton-sizeSmall': {
                padding: '4px'
            },
            '&.MuiToggleButton-root:hover': {
                backgroundColor: 'lightgray'
            }
        }
    }
}));

const AppSelect = (props) => {
    const { portalSettings } = useContext(AppContext);

    const classes = useStyles(props.options.find((m) => m[props.valuePropertyName] === +props.value));

    const ConvertOps = (ops) => {
        return ops.map((op) => {
            return {
                value: `${op[props.valuePropertyName || 'value']}`,
                text: `${op[props.textPropertyName || 'text']}`
            };
        });
    };

    const [state, setState] = useState({
        value: `${props.value}`,
        options: ConvertOps(props.options) || []
    });

    useEffect(() => {
        setState((st) => ({ ...st, value: props.value }));
    }, [props.value]);

    useEffect(() => {
        props.options && setState((st) => ({ ...st, options: ConvertOps(props.options) }));
    }, [props.options]);

    const handleChange = (e) => {
        const val = e.target.value;
        setState((st) => ({ ...st, value: val }));
        props.onChange && props.onChange({ [props.name]: val });
    };

    const clickBtnGrup = (e, val) => {
        if (!props.disabled) {
            setState((st) => ({ ...st, value: val }));
            props.onChange && props.onChange({ [props.name]: val });
        }
    };

    if (portalSettings.workflowStepOptionsAsButtonGroup) {
        return (
            <ToggleButtonGroup className={classes.appSelectBtnGrp} size="small" value={state.value} onChange={clickBtnGrup} exclusive>
                {state.options.map((op, i) => (
                    <ToggleButton key={i} value={op.value}>
                        {op.text}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );
    } else {
        return (
            <NativeSelect
                name={props.name}
                disabled={props.disabled}
                className={`app-select${props.className ? ` ${props.className}` : ''}`}
                value={state.value}
                onChange={handleChange}
            >
                <option aria-label="None" value="" />
                {state.options.map((op, i) => (
                    <option key={i} value={op.value}>
                        {op.text}
                    </option>
                ))}
            </NativeSelect>
        );
    }
};

export default AppSelect;
