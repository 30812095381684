import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
// import { ExpBaseURL } from '../../Basic/HttpService';
// import { AppStorage } from '../../Basic/storage-service';
import './gijgoIndex.scss';
// import FileUploaderButton from '../GenericFileUploader'; //'../GenericFileUploader';
// import { isValidConfig } from '../GenericFileUploader/FileUploaderConfig';
import ReactDOM from 'react-dom';
// import { useLoginInfo } from '../../Hooks/useLoginInfo';
// import { useToast } from '../../Hooks/useToast';
// import { AddButton, EditButton, DataGrid, BreadCrumbs, formatters } from '../';
// import { CustomChip, ChipWithoutLable } from '../Inputs/DatePicker';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { AppStorage } from '../../Service/storage-service';
import { ExpBaseURL } from '../../../Core/Service/http-calls';

var grid1;
var $ = window.$;

const CommonGijgoGrid = (props) => {
    let isAction = props.columns.find((c) => c?.key === 'action');

    const useStyles = makeStyles((theme) => ({
        gijgoGrid: {
            '& .gj-grid-md th:first-of-type': {
                // backgroundColor: 'red',
                padding: (props) => {
                    return props.isAction ? '12px 5px 12px 5px' : '12px 15px 12px 10px';
                }
            }
        }
    }));

    const classes = useStyles({ isAction: isAction });
    let token = AppStorage.getToken();
    const [state, setState] = useState({
        recordId: props.recordId,
        fileCount: `${props.fileCount || 0}`,
        configName: props.fileUploaderConfigName,
        columns: []
    });
    const [gridReload, setGridReload] = useState(new Date());

    const gridFileCountRefresh = () => {
        setGridReload((st) => ({ ...st, gridReload: new Date() }));
    };

    useEffect(() => {
        let finalColumns = [...props.columns].map((c) => ({ ...c, headerCssClass: 'gridHeader' }));
        setState((st) => ({ ...st, columns: finalColumns }));
    }, [props.columns, gridReload]);

    useEffect(async () => {
        if (state.columns.length > 0) {
            pullArchivedJobsAndUpdateState();
        }
    }, [state.columns, props.isReload, props.getUrl]);

    const pullArchivedJobsAndUpdateState = async () => {
        window.$(`#${'key1'}`).grid('destroy', true, true);

        const onSuccessFunc = function (response) {
            grid1.render(props.dataConverter(response));
        };

        grid1 = window.$(`#${'key1'}`).grid({
            dataSource: {
                url: `${ExpBaseURL}/api/${props.getUrl}`,
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: onSuccessFunc
            },
            ...(props.minWidth !== null && { minWidth: 1200 }),
            // fixedHeader: true,
            headerFilter: {
                type: 'onchange'
            },

            columns: [...state.columns],
            pager: {
                limit: 20
            }
        });

        // grid1.on('dataBound', function (e, records, totalRecords) {
        //     const gridActionSpn = document.createElement('span');

        //     ReactDOM.render(gridActionGrid, gridActionSpn);
        //     var gridActionSpnRef = gridActionSpn;

        //     GijgoActionBtn(grid1, 'action', gridActionSpnRef);
        // });
        grid1.reload();
    };

    const GijgoActionBtn = (grid, propertyName, dropdown) => {
        var id = $(grid).attr('id');
        var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
        var filterRow = $(rowXPath);
        if ($(filterRow).length == 1) {
            var colXPath = 'th [data-field="' + `action` + '"]';
            var filterInput = $(filterRow).find(colXPath);
            if ($(filterInput).length) {
                $(filterInput).replaceWith(dropdown);
            }
        }
    };
    const downloadCSVWrapper = () => {
        grid1?.downloadCSV(`${props.downloadBtnName}-${moment().format('DD-MMM-YYYY HH:mm')}.csv`);
    };
    return (
        <Grid
            container
            // style={{ position: 'relative' }}
        >
            <Grid
                container
                item
                xs={12}
                sm={12}
                style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}
                className={`common-gijgo-grid-container ${classes.gijgoGrid}`}
            >
                {props.downloadBtnName && (
                    <Grid
                        item
                        // style={{ top: '23px', left: '18px', zIndex: 99, position: 'absolute' }}
                        style={{ bottom: '3px', left: '16px', zIndex: 99, position: 'absolute' }}
                    >
                        <Tooltip title="Download CSV" arrow>
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} style={{ color: '#3f51b5' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}

                <table id={`${'key1'}`}></table>
            </Grid>
        </Grid>
    );
};

export default CommonGijgoGrid;
