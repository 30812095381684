import { Container, Grid, Avatar } from '@material-ui/core';
import React from 'react';
import SpeedIcon from '@material-ui/icons/Speed';
import { useHistory } from 'react-router-dom';

const ReportingDashboard = () => {
    const history = useHistory();

    const RedirectToDash = (val) => {
        history.push({
            pathname: val
        });
    };
    return (
        <Container>
            <Grid container style={{ width: '40%', marginLeft: 'auto', marginRight: 'auto', marginTop: 50 }} spacing={5}>
                <Grid item xs={12} sm={12} md={6}>
                    <div
                        style={{
                            height: 200,
                            width: 200,
                            background: '#95a5a6' /* For browsers that do not support gradients */,
                            backgroundImage: 'conic-gradient( #5e7273, #748687,#95a5a6)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            position: 'relative',
                            right: 0
                        }}
                        onClick={() => RedirectToDash('/AgedMeasures')}
                    >
                        <div style={{ color: 'white' }}>
                            <SpeedIcon style={{ fontSize: 60 }} />
                            <div style={{ fontSize: 18, fontWeight: 500, fontFamily: `"Public Sans", sans-serif` }}>Aged Measures</div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div
                        style={{
                            height: 200,
                            width: 200,
                            background: '#9c8061' /* For browsers that do not support gradients */,
                            backgroundImage: 'conic-gradient( #9c8061,#82684a,#6e5538)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => RedirectToDash('/service/dashboard')}
                    >
                        <div style={{ color: 'white' }}>
                            <SpeedIcon style={{ fontSize: 60 }} />
                            <div style={{ fontSize: 18, fontWeight: 500, fontFamily: `"Public Sans", sans-serif` }}>Service Dashboard</div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div
                        style={{
                            height: 200,
                            width: 200,
                            background: '#2ecc71' /* For browsers that do not support gradients */,
                            backgroundImage: 'conic-gradient( #0d6e36,#1ba656,#2ecc71)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => RedirectToDash('/sales/dashboard')}
                    >
                        <div style={{ color: 'white' }}>
                            <SpeedIcon style={{ fontSize: 60 }} />
                            <div style={{ fontSize: 18, fontWeight: 500, fontFamily: `"Public Sans", sans-serif` }}>Sales Dashboard v1 </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div
                        style={{
                            height: 200,
                            width: 200,
                            background: '#2ecc71' /* For browsers that do not support gradients */,
                            backgroundImage: 'conic-gradient( #2ecc71,#1ba656,#0d6e36)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => RedirectToDash('/sales/dashboardtwo')}
                    >
                        <div style={{ color: 'white' }}>
                            <SpeedIcon style={{ fontSize: 60 }} />
                            <div style={{ fontSize: 18, fontWeight: 500, fontFamily: `"Public Sans", sans-serif` }}>Sales Dashboard V2</div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ReportingDashboard;
