import React from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Multiselect = (props) => {
    return (
        <Autocomplete
            {...props}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                </React.Fragment>
            )}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};
export default Multiselect;

export const MultiselectWithoutCheckbox = (props) => {
    return (
        <Autocomplete
            {...props}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => <React.Fragment>{option.name}</React.Fragment>}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};

export const SingelSelect = (props) => {
    return (
        <Autocomplete
            {...props}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => <React.Fragment>{option.name}</React.Fragment>}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField margin="dense" fullWidth label={props.label} {...params} variant="outlined" placeholder={props.placeholder} />}
        />
    );
};
