import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';
import HomeIcon from '@material-ui/icons/Home';
import ArchiveIcon from '@material-ui/icons/Archive';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AirplayIcon from '@material-ui/icons/Airplay';
import Dashboard from './../../../../Components/internal/dashboard/dashboard';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ForumIcon from '@material-ui/icons/Forum';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PieChartIcon from '@material-ui/icons/PieChart';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroIcon from '@material-ui/icons/Euro';
import DescriptionIcon from '@material-ui/icons/Description';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import SpeedIcon from '@material-ui/icons/Speed';
import WebIcon from '@material-ui/icons/Web';
import BuildIcon from '@material-ui/icons/Build';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import SendIcon from '@material-ui/icons/Send';
import SmsIcon from '@material-ui/icons/Sms';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import WorkIcon from '@material-ui/icons/Work';
import BookIcon from '@material-ui/icons/Book';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PaymentIcon from '@material-ui/icons/Payment';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
export default [
    // {
    //     label: 'Home',
    //     path: '/',
    //     exact: true,
    //     component: Components.Dashbord,
    //     authType: TypeOfAuth.Auth,
    //     hidden: true,
    //     icon: <HomeIcon />
    // },
    {
        label: 'eWIP',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <LoyaltyIcon />,
        children: [
            {
                label: 'Dashboard',
                path: '/eWIPDashboard',
                exact: true,
                component: Components.Dashbord,
                authType: TypeOfAuth.Auth,
                icon: <HomeIcon />
            },
            {
                label: 'Archived Jobs',
                path: '/archivedjobs',
                exact: true,
                component: Components.ArchivedJobs,
                authType: TypeOfAuth.Auth,
                icon: <ArchiveIcon />
            },
            {
                label: 'Videos',
                path: '/Lists/Videos',
                exact: true,
                component: Components.Videos,
                authType: TypeOfAuth.Auth,
                icon: <VideoLibraryIcon />
            },
            {
                label: 'Dashboard',
                exact: true,
                icon: <DashboardIcon />,
                authType: TypeOfAuth.Auth,
                dontFlatListChildren: true,
                isDynamicChildren: 'eWipDashboard',
                children: [
                    // {
                    //     label: 'Service Advisor',
                    //     path: '/dashboard/sa',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="2" />;
                    //     }
                    // },
                    // {
                    //     label: 'Awaiting Arrival',
                    //     path: '/dashboard/aa',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="3" />;
                    //     }
                    // },
                    // {
                    //     label: 'Workshop Control',
                    //     path: '/dashboard/wc',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="4" />;
                    //     }
                    // },
                    // {
                    //     label: 'Parts Advisor',
                    //     path: '/dashboard/pa',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="5" />;
                    //     }
                    // },
                    // {
                    //     label: 'Technician App',
                    //     path: '/dashboard/ta',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="8" />;
                    //     }
                    // },
                    // {
                    //     label: 'Back Office',
                    //     path: '/dashboard/bo',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="6" />;
                    //     }
                    // }
                ]
            },
            {
                label: 'Workflow Job',
                hidden: true,
                exact: true,

                path: '/job/:jobId/:workflowId',
                component: Components.workflowJobDetails,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'QC Jobs',
                // hidden: true,
                icon: <AirplayIcon />,
                exact: true,
                path: '/qc_jobs',
                component: Components.QCJobsDashboard,
                authType: TypeOfAuth.Auth
            }
        ]
    },
    {
        label: 'Dashboard Detail',
        path: '/dashgrid',
        exact: true,
        component: Components.dashboard,
        authType: TypeOfAuth.Auth,
        hidden: true
    },

    {
        label: 'Communicator',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ForumIcon />,
        children: [
            {
                label: 'Dashboard',
                path: '/communicator_dash',
                exact: true,
                component: Components.CustCommunicatorDash,
                authType: TypeOfAuth.Auth,
                icon: <HomeIcon />
            },
            {
                label: 'Open Action Log',
                path: '/Openactionlog',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: Components.OpenActionLog,
                icon: <BookIcon />
            },
            {
                label: 'Site Vehicles',
                path: '/SiteVehicles',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.SiteVehicles status="S" />,
                icon: <EmojiTransportationIcon />
            },
            {
                label: 'VOR Vehicles',
                path: '/VORVehicles',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.SiteVehicles status="VOR" />,
                icon: <DepartureBoardIcon />
            },
            {
                label: 'Workshop Vehicles',
                path: '/WorkshopVehicles',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.SiteVehicles status="WORKSHOP" />,
                icon: <WorkIcon />
            },
            {
                label: 'Jobs Approved',
                path: '/JobsApproved',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.SiteVehicles status="A" />,
                icon: <WorkIcon />
            },
            {
                label: 'Bookings',
                path: '/bookings',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: Components.BookingsScreen,
                icon: <BookmarksIcon />
            }
        ]
    },
    {
        label: 'Reports',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <BubbleChartIcon />,
        children: [
            {
                label: 'Dashboard',
                exact: true,
                path: '/ReportsDashboard',
                component: Components.ReportingDashboard,
                authType: TypeOfAuth.Auth,
                icon: <SpeedIcon />
            },
            {
                label: 'Budget Dashboard',
                exact: true,
                path: '/budget',
                component: Components.BudgetScreen,
                authType: TypeOfAuth.Auth,
                icon: <SpeedIcon />
            },
            {
                label: 'Profit & Loss',
                exact: true,
                path: '/profitloss',
                component: Components.ProfitLoss,
                authType: TypeOfAuth.Auth,
                icon: <EuroIcon />
            },
            {
                label: 'Aged Measures',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DragIndicatorIcon />,
                children: [
                    {
                        label: 'Dashboard',
                        exact: true,
                        path: '/AgedMeasures',
                        component: () => <Components.AgedMeasuresDashBoard MeasureDesc="Dashboard" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Account Queries',
                        exact: true,
                        path: '/AgedMeasures/AccountQueries',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={6} MeasureDesc="Account Queries" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Account Queries By Department',
                        exact: true,
                        path: '/AgedMeasures/AccountQueriesByDepartment',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={21} MeasureDesc="Account Queries By Department" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Aged Debt - Branch',
                        exact: true,
                        path: '/AgedMeasures/AgedDebt-Branch',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={7} MeasureDesc="Aged Debt - Branch" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Aged Debt - Company',
                        exact: true,
                        path: '/AgedMeasures/AgedDebt-Company',
                        component: () => <Components.AgedDebt MeasureDesc="Aged Debt - Company" MeasureID={8} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Cash',
                        exact: true,
                        path: '/AgedMeasures/Cash',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={5} MeasureDesc="Cash" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Cash By Department',
                        exact: true,
                        path: '/AgedMeasures/CashByDepartment',
                        component: () => <Components.CashByDepartment MeasureDesc="Cash By Department" MeasureID={20} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Dirty Units',
                        exact: true,
                        path: '/AgedMeasures/DirtyUnits',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={2} MeasureDesc="Dirty Units" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Fuso Stock',
                        exact: true,
                        path: '/AgedMeasures/NewFusoStock',
                        component: () => <Components.NewStocks MeasureDesc="New Fuso Stock" MeasureID={11} FC="C" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Maxus Vans',
                        exact: true,
                        path: '/AgedMeasures/NewMaxusVans',
                        component: () => <Components.NewStocks MeasureDesc="New Maxus Vans" MeasureID={18} FC="A" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Truck Stock',
                        exact: true,
                        path: '/AgedMeasures/NewTruckStock',
                        component: () => <Components.NewStocks MeasureDesc="New Truck Stock" MeasureID={10} FC="H" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Van Stock',
                        exact: true,
                        path: '/AgedMeasures/NewVanStock',
                        component: () => <Components.NewStocks MeasureDesc="New Van Stock" MeasureID={12} FC="V" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'R&M',
                        exact: true,
                        path: '/AgedMeasures/R&M',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={3} MeasureDesc="R&M" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Fuso Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedFusoStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Fuso Stock" MeasureID={14} FC="C" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Maxus Vans',
                        exact: true,
                        path: '/AgedMeasures/UsedMaxusVans',
                        component: () => <Components.UsedStocks MeasureDesc="Used Maxus Vans" MeasureID={19} FC="A" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Non-franchise Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedNon-franchiseStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Non-franchise Stock" MeasureID={16} FC="Z" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Truck Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedTruckStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Truck Stock" MeasureID={13} FC="H" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Van Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedVanStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Van Stock" MeasureID={15} FC="V" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Vehicle Debt',
                        exact: true,
                        path: '/AgedMeasures/VehicleDebt',
                        component: () => <Components.AgedDebt MeasureDesc="Vehicle Debt" MeasureID={9} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Warranty',
                        exact: true,
                        path: '/AgedMeasures/Warranty',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={4} MeasureDesc="Warranty" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Work In Progress',
                        exact: true,
                        path: '/AgedMeasures/WorkInProgress',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={1} MeasureDesc="Work In Progress" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Work In Progress by Departement',
                        exact: true,
                        path: '/AgedMeasures/WorkInProgressbyDepartement',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={17} MeasureDesc="Work In Progress by Departement" />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'DOC',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DescriptionIcon />,
                children: [
                    {
                        label: 'Weekly Flash Report',
                        exact: true,
                        path: '/Docs/WeeklyFlashReport',
                        component: () => <Components.WeeklyFlashReport MeasureDesc="Weekly Flash Report" />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Sales',
                children: true,
                authType: TypeOfAuth.Auth,
                icon: <EmojiEventsIcon />,
                children: [
                    {
                        label: 'Sales Dashboard',
                        exact: true,
                        path: '/sales/dashboard',
                        component: Components.SalesDashboard,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Sales Dashboard V2',
                        exact: true,
                        path: '/sales/dashboardtwo',
                        component: Components.SalesDashboardTwo,
                        authType: TypeOfAuth.Auth
                    },

                    {
                        label: 'New Vehicle Profit Report',
                        exact: true,
                        path: '/sales/NewVehicleProfitReport',
                        component: Components.NewVehicleProfitReport,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Vehicle Profit Report',
                        exact: true,
                        path: '/sales/UsedVehicleProfitReport',
                        component: Components.UsedVehicleProfitReport,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Account Queries by Salesexec',
                        exact: true,
                        path: '/sales/AccountQueriesbySalesexec',
                        component: Components.AccountQueriesbySalesexec,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Aged Measures Work - Progress',
                        exact: true,
                        path: '/sales/AgedMeasuresWorkingProgress',
                        component: Components.AgedMeasuresWorkingProgress,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Sales Work In Progress',
                        exact: true,
                        path: '/sales/SalesWorkInProgress',
                        component: Components.SalesWorkinProgress,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Registration Reports',
                        exact: true,
                        path: '/sales/RegistrationReports',
                        component: Components.RegistrationReports,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Vehicle Stock (Excluding WIP)',
                        exact: true,
                        path: '/sales/NewVehicleStockExcludingWIP',
                        component: Components.VehicleStocksExWip,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Vehicle Order',
                        exact: true,
                        path: '/sales/VehicleNewOrder',
                        component: Components.VehicleNewOrder,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Outstanding Invoices',
                        exact: true,
                        path: '/sales/OutstandingInvoices',
                        component: Components.OutstandingInvoices,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Sales Contingency Debt',
                        exact: true,
                        path: '/sales/SalesContingencyDebt',
                        component: Components.SalesContingencyDebt,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Vehicle Stock',
                        exact: true,
                        path: '/sales/UsedVehicleStock',
                        component: Components.UsedVehicleStock,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Vehicle Aged Debt By Salesexec',
                        exact: true,
                        path: '/sales/VehicleAgedDebtBySalesexec',
                        component: Components.VehicleAgedDebtBySalesexec,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Services',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <WebIcon />,
                children: [
                    {
                        label: 'Dashboard',
                        exact: true,
                        path: '/service/dashboard',
                        component: Components.ServiceDashboard,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Consumables',
                        exact: true,
                        path: '/Services/Consumables',
                        component: () => <Components.Consumables type="C" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Idle Time Analysis',
                        exact: true,
                        path: '/Services/IdleTimeAnalysis',
                        component: () => <Components.IdleTimeAnalysis />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Invoiced Hours',
                        exact: true,
                        path: '/Services/InvoicedHours',
                        component: Components.InvoicedHours,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Mobilovan',
                        exact: true,
                        path: '/Services/Mobilovan',
                        component: () => <Components.Consumables type="M" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'NPW Rectification',
                        exact: true,
                        path: '/Services/NPWRectification',
                        component: () => <Components.Consumables type="R" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Parts Warranty Write Off',
                        exact: true,
                        path: '/Services/PartsWarrantyWriteOff',
                        component: () => <Components.Consumables type="P" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Service Warranty Write Off',
                        exact: true,
                        path: '/Services/ServiceWarrantyWriteOff',
                        component: () => <Components.Consumables type="S" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Technician Efficiency',
                        exact: true,
                        path: '/Services/Efficiency',
                        component: () => <Components.TechnicianEfficiency />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Parts',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <BuildIcon />,
                children: [
                    {
                        label: 'Part Sales - Cash Sales (P)',
                        exact: true,
                        path: '/parts/PartsSales/P',
                        component: () => <Components.PartSales code="P" desc="Part Sales - Cash Sales (P)" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Part Sales - Trade Center (B)',
                        exact: true,
                        path: '/parts/PartsSales/B',
                        component: () => <Components.PartSales code="B" desc="Part Sales - Trade Center (B)" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Part Sales - Workshop Center (W)',
                        exact: true,
                        path: '/parts/PartsSales/w',
                        component: () => <Components.PartSales code="W" desc="Part Sales - Workshop Center (w)" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Part Sales - Total',
                        exact: true,
                        path: '/parts/PartsSales/A',
                        component: () => <Components.PartSales code="A" desc="Part Sales - Total" />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            }
        ]
    },
    {
        label: 'Invoices',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ReceiptIcon />,
        children: [
            {
                label: 'Dashboard',
                exact: true,
                path: '/invoice/dashboard',
                component: () => <Components.InvoicesDashboard />,
                authType: TypeOfAuth.Auth,
                icon: <HomeIcon />
            },
            {
                label: 'Customers',
                exact: true,
                path: '/invoice/Customers',
                component: () => <Components.InvoiceCustomersListScreen />,
                authType: TypeOfAuth.Auth,
                icon: <AccountBoxIcon />
            },
            {
                label: 'All Invoices',
                exact: true,
                path: '/invoice/allInvoices',
                component: () => <Components.InvoiceScreens />,
                authType: TypeOfAuth.Auth,
                icon: <FileCopyIcon />
            },
            {
                label: 'Invoices Outstanding',
                exact: true,
                path: '/invoice/Invoice_Outstanding',
                component: () => <Components.InvoiceScreens status={1} />,
                authType: TypeOfAuth.Auth,
                icon: <ReceiptIcon />
            },
            {
                label: 'Invoices Paid',
                exact: true,
                path: '/invoice/Invoice_Paid',
                component: () => <Components.InvoiceScreens status={3} />,
                authType: TypeOfAuth.Auth,
                icon: <PaymentIcon />
            },
            {
                label: 'Invoices Queried',
                exact: true,
                path: '/invoice/Invoice_Queried',
                component: () => <Components.InvoiceScreens status={2} />,
                authType: TypeOfAuth.Auth,
                icon: <RemoveFromQueueIcon />
            },
            {
                label: 'Messages Sent',
                exact: true,
                path: '/invoice/messagesSent',
                component: () => <Components.InvoiceScreens status={-2} />,
                authType: TypeOfAuth.Auth,
                icon: <RateReviewIcon />
            },
            {
                label: 'Messages Received',
                exact: true,
                path: '/invoice/messagesReceived',
                component: () => <Components.InvoiceScreens status={-1} />,
                authType: TypeOfAuth.Auth,
                icon: <SpeakerNotesIcon />
            }
        ]
    },
    {
        label: 'MOT HISTORY',
        // hidden: true,
        icon: <DriveEtaIcon />,
        exact: true,
        path: '/MotHistoryAndRoadTax',
        component: Components.MotHistoryScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'GetAgedMeasuresDirtyUnits',
        exact: true,
        path: '/AgedMeasures/GetAgedMeasuresDirtyUnits',
        component: () => <Components.GetAgedMeasuresDirtyUnits />,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'ViewDebtDetailsSalesexec',
        exact: true,
        path: '/sales/ViewDebtDetailsSalesexec',
        component: () => <Components.ViewDebtDetailsSalesexec />,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'GetAgedMeasuresRanMWarrantyCashGen',
        exact: true,
        path: '/AgedMeasures/GetAgedMeasuresRanMWarrantyCashGen',
        component: () => <Components.GetAgedMeasuresRanMWarrantyCashGen />,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'GetUsedAndNewVehicleDebt',
        exact: true,
        path: '/AgedMeasures/GetUsedAndNewVehicleDebt',
        component: () => <Components.GetUsedAndNewVehicleDebt />,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'EnquirieList',
        exact: true,
        path: '/AgedMeasures/EnquirieList',
        component: Components.EnquirieList,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Tech Dashboard Detail',
        path: '/techDashgrid',
        exact: true,
        component: Components.TechDashboardGrid,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Logout',
        path: '/logout',
        hidden: true,
        component: Components.logout,
        authType: TypeOfAuth.Auth
    }
];
