import React, { useContext, useEffect, useState } from 'react';
import {
    ButtonGroup,
    Grid,
    IconButton,
    LinearProgress,
    Tooltip,
    Badge,
    Card,
    CardHeader,
    CardContent,
    Typography,
    TextField,
    FormLabel,
    CircularProgress,
    Chip,
    Avatar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { TextBox } from '../../../../Core/FormInput';
import moment from 'moment';
import { getCalendarBookings } from '../../../../Core/Service/communicator-service';
import { getBranchAndFranchise } from '../../../../Core/Service/branch-screen-service';
import DialogComp from '../../../../Core/Modal/dialogModal';
import BookingDetailmodal from './bookingDetail';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import BookingDetailsList from './BookingDetailsList';
import formatters from '../../../../Core/Grid/inputFormatter';
import AppContext from '../../../../App/AppContext';
import ListAltIcon from '@material-ui/icons/ListAlt';
import UpdateIcon from '@material-ui/icons/Update';
import SendMessageModal from '../../../customer/Communicator/sendMessageModal';
import AddEditSiteVehicle from '../SiteVehicles/addEditSiteVehicle';
import BuildIcon from '@material-ui/icons/Build';

const useStyles = makeStyles({
    yearInput: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '4.1px',
            paddingBottom: '4.1px',
            color: 'white'
        }
    },
    root: {
        minWidth: 275,
        height: '165px',
        border: ' 1px solid black'
        // height: '100%'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    cardContent: (props) => {
        let cardContentHeight = props.fromTab ? Math.max(120, (window.innerHeight - 170) / 4 - 60) : Math.max(120, (window.innerHeight - 80) / 4 - 60);
        return {
            height: cardContentHeight,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.3em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#1976d2',
                opacity: 5,
                outline: '1px solid slategrey'
            }
        };
    },
    cardRow: {
        padding: '3px',
        borderBottom: ' 1px dotted black',
        cursor: 'pointer',
        '&:nth-of-type(odd)': {
            background: '#E8E8E8'
        }
    },
    cardHeader: {
        height: '10px',
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`,
        border: (props) => `1px solid ${props.portalMenuBarBackgroundColour}`,
        color: 'white',
        textAlign: 'left',
        padding: '10px !important'
    },
    loaderContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        zIndex: 99,
        background: 'grey',
        opacity: '0.6'
    }
});

const years = () => {
    const years = [];
    const d = new Date();
    let startYear = d.getFullYear() - 10;
    let endYear = d.getFullYear() + 10;

    while (endYear - startYear >= 0) {
        years.push({ description: `${startYear}`, id: `${startYear}` });
        startYear = startYear + 1;
    }
    return years;
};

const monthsList = formatters.constantMonthList();

const BookingsScreen = (props) => {
    const { portalSettings, showTopNavAndMenu, loggedIn, isTopNav, userRoles } = useContext(AppContext);
    const classes = useStyles({ ...props, ...portalSettings });
    const isCustomer = userRoles.includes('customer');
    const isInternal = userRoles.includes('internal');

    const [state, setState] = useState({
        filterList: [],
        branchList: [],
        branchCode: {},
        monthCode: monthsList[`${new Date().getMonth()}`],
        yearCode: { description: `${new Date().getFullYear()}`, id: `${new Date().getFullYear()}` },
        currentMonthDays: [],
        customerList: [],
        customerCode: {},
        filterData: []
    });

    useEffect(async () => {
        const res = await getBranchAndFranchise();

        if (res.success) {
            setState((st) => {
                let newSt = { ...st };
                newSt.branchList = res.data.branches.map((b) => {
                    return { description: b.branchName, id: b.branchID };
                });
                newSt.branchCode = { description: res.data.branches[0].branchName, id: res.data.branches[0].branchID };
                return newSt;
            });
        }
    }, []);

    useEffect(async () => {
        setState((st) => ({
            ...st,
            showLoader: true
        }));
        let allData = [];
        let customerListUnique = [];

        if (state.branchCode.id) {
            const res = await getCalendarBookings(state.branchCode.id, state.monthCode.id, state.yearCode.id);
            if (res.success) {
                allData = [...res.data.list];
            }
            customerListUnique = [...new Set(res.data.list.map((item) => item.customerName))].map((w) => {
                return { description: w, id: w };
            });
        }
        setState((st) => {
            let newSt = { ...st };

            newSt.customerList = customerListUnique;
            newSt.allData = allData;
            return newSt;
        });
    }, [state.yearCode, state.monthCode, state.branchCode]);

    useEffect(() => {
        let currentMonthDays = Array.from(Array(moment(`${state.yearCode.id}-${state.monthCode.id}`, 'YYYY-MM').daysInMonth()), (_, i) => {
            return { dayNo: i + 1, events: [] };
        });

        if (state.allData) {
            let tempCurrentMonthDays = [...currentMonthDays];
            if (state.customerCode?.id) {
                state.allData.forEach((p) => {
                    let index = tempCurrentMonthDays.findIndex((o) => o.dayNo == p.bookingDay);
                    if (index >= 0 && state.customerCode.id == p.customerName) {
                        tempCurrentMonthDays[index].events.push(p);
                    }
                });
            } else {
                state.allData.forEach((p) => {
                    let index = tempCurrentMonthDays.findIndex((o) => o.dayNo == p.bookingDay);
                    if (index >= 0) {
                        tempCurrentMonthDays[index].events.push(p);
                    }
                });
            }
            setState((st) => {
                let newSt = { ...st };
                newSt.currentMonthDays = currentMonthDays;
                newSt.filterData = [...tempCurrentMonthDays];
                newSt.showLoader = false;
                return newSt;
            });
        }
    }, [state.allData, state.customerCode]);

    const handleAutoChange = (fieldName) => (e, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt[fieldName] = val;
            return newSt;
        });
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const showDetailModalHandler = (val) => {
        setState((st) => {
            return { ...st, showDetailModal: true, selectedBookingData: val };
        });
    };

    const showDetailListModalHandler = (list, date) => {
        setState((st) => {
            return { ...st, showDetailListModal: true, selectedBookingList: list, selectedListDate: date };
        });
    };

    const closeDetailModalHandler = (val) => {
        setState((st) => {
            return { ...st, showDetailModal: false, showDetailListModal: false };
        });
    };

    const handleClose = (res) => {
        let newSt = {};
        newSt.showSendMessageModal = false;
        newSt.siteVehicle = false;
        if (res) {
            newSt.isReload = new Date();
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    };

    const handleAddActionLog = (val) => {
        setState((st) => ({
            ...st,
            [val]: true,
            headerRecordID: state.selectedBookingData.headerRecordID,
            reg: state.selectedBookingData.registration,
            wip: state.selectedBookingData.wipNumber
        }));
    };

    const BookingDetailHeaderComp = () => {
        return (
            <Grid container>
                <Grid item>
                    <span>
                        Booking Details
                        {isInternal && (
                            <>
                                <Chip
                                    style={{ marginLeft: 10 }}
                                    avatar={
                                        <Avatar>
                                            <BuildIcon />
                                        </Avatar>
                                    }
                                    label="Update Status"
                                    onClick={() => handleAddActionLog('siteVehicle')}
                                />
                                <Chip
                                    style={{ marginLeft: 10 }}
                                    avatar={
                                        <Avatar>
                                            <UpdateIcon />
                                        </Avatar>
                                    }
                                    label="Add Action Log"
                                    onClick={() => handleAddActionLog('showSendMessageModal')}
                                />
                            </>
                        )}
                    </span>
                </Grid>
            </Grid>
        );
    };

    return (
        <div style={{ padding: '10px', position: 'relative' }}>
            <Grid container>
                <Grid container item spacing={2} style={{ marginBottom: '1px' }}>
                    <Grid item xs={3}>
                        <FormLabel>Branch</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={state.branchList}
                            name="branch"
                            value={state.branchCode}
                            onChange={handleAutoChange('branchCode')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormLabel>Select Month</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={monthsList}
                            name="Month"
                            value={state.monthCode}
                            onChange={handleAutoChange('monthCode')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormLabel>Select Year</FormLabel>
                        <Autocomplete
                            disableClearable
                            autoSelect
                            options={years()}
                            name="Years"
                            value={state.yearCode}
                            onChange={handleAutoChange('yearCode')}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                        />
                    </Grid>
                    {!isCustomer && (
                        <Grid item xs={3}>
                            <FormLabel>Select Customer</FormLabel>
                            <Autocomplete
                                autoSelect
                                options={state.customerList}
                                name="Years"
                                value={state.customerCode}
                                onChange={handleAutoChange('customerCode')}
                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                renderInput={(params) => <TextField margin="dense" fullWidth {...params} variant="outlined" />}
                            />
                        </Grid>
                    )}
                </Grid>
                {state.showLoader && (
                    <Grid item sm={12} container alignItems="center" justify="center" className={classes.loaderContainer}>
                        <CircularProgress />
                    </Grid>
                )}
                <Grid container item spacing={2}>
                    {state.filterData.map((o, i) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card className={classes.root}>
                                    <CardHeader
                                        action={
                                            <>
                                                {o.events.length !== 0 && (
                                                    <IconButton style={{ padding: '0px', color: 'white' }} disabled={o.events.length !== 0 ? false : true}>
                                                        <AspectRatioIcon
                                                            onClick={() =>
                                                                showDetailListModalHandler(
                                                                    o.events,
                                                                    `${i + 1} ${state.monthCode.description} ${state.yearCode.description}`
                                                                )
                                                            }
                                                        />
                                                    </IconButton>
                                                )}
                                            </>
                                        }
                                        titleTypographyProps={{ variant: 'body1' }}
                                        title={`${i + 1} ${state.monthCode.description} ${state.yearCode.description}`}
                                        className={classes.cardHeader}
                                    ></CardHeader>
                                    <CardContent style={{ padding: '0px 0px 0px 0px' }} className={classes.cardContent}>
                                        {o.events.length > 0 ? (
                                            o.events.map((k) => {
                                                return (
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        alignItems="center"
                                                        className={classes.cardRow}
                                                        onClick={() => showDetailModalHandler(k)}
                                                    >
                                                        {/* <Grid item xs={1}>
                                                            ⓘ
                                                        </Grid> */}

                                                        <Grid container item xs={12} style={{ textAlign: 'left', fontSize: ' 12px' }}>
                                                            <Grid item xs={12}>
                                                                {/* <Typography variant="body2"> */}
                                                                {k.wipNumber} - {k.registration} - {k.customerName}
                                                                {/* </Typography> */}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    height: '100%'
                                                }}
                                            >
                                                <div style={{ marginLeft: 'auto', marginRight: 'auto', color: '#D0D0D0', cursor: 'default' }}>No bookings</div>
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            {state.showDetailModal ? (
                <DialogComp title={BookingDetailHeaderComp()} maxWidth="md" onClose={closeDetailModalHandler} fullWidth>
                    <BookingDetailmodal selectedBookingData={state.selectedBookingData} />
                </DialogComp>
            ) : null}
            {state.showDetailListModal ? (
                <DialogComp title={`Bookings on ${state.selectedListDate}`} maxWidth="xl" onClose={closeDetailModalHandler} fullWidth>
                    <BookingDetailsList selectedBookingList={state.selectedBookingList} />
                </DialogComp>
            ) : null}
            {state.showSendMessageModal ? (
                <DialogComp title={`Send Message (WIP : ${state.wip})`} onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                    <SendMessageModal onClose={handleClose} headerRecordID={state.headerRecordID} reg={state.reg} wip={state.wip} />
                </DialogComp>
            ) : null}
            {state.siteVehicle ? (
                <DialogComp title="Vehicle Status" onClose={() => handleClose(false)} fullWidth maxWidth="lg">
                    <AddEditSiteVehicle onClose={handleClose} headerRecordID={state.headerRecordID} reg={state.reg} wip={state.wip} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default BookingsScreen;
