import { get, post } from './http-calls';
import { memoize } from 'lodash';
import ProfitLoss from './../../Components/internal/Budget/profitLoss';

export const getReportsDropdowns = async () => {
    let res = await get('Reports/ScreenDropdown', { useAuthToken: true });
    return res;
};

export const getRportDashByID = async (start, end, fid, bid) => {
    let FranchiseCode = `FranchiseCode=${fid.join('&FranchiseCode=')}`;
    let BranchID = bid.length > 0 ? `BranchID=${bid.join('&BranchID=')}` : '';
    let res = await get(`Reports/GetDashBoard?StartDate=${start}&EndDate=${end || ''}&${FranchiseCode}&${BranchID}`, {
        useAuthToken: true
    });
    return res;
};

export const getProfitLossbyID = async (start, end, fid, bid) => {
    let FranchiseCode = `FranchiseCode=${fid.join('&FranchiseCode=')}`;
    let BranchID = bid.length > 0 ? `BranchID=${bid.join('&BranchID=')}` : '';
    let res = await get(`Reports/GetProfitLoss?StartDate=${start}&EndDate=${end || ''}&${FranchiseCode}&${BranchID}`, { useAuthToken: true });

    return res;
};

export const GetWIPHeaderByWIPNumber = async (wp, cn) => {
    let res = await get(`AgedMeasures/GetWIPHeader?WIPNumber=${wp}&Company=${cn}`, { useAuthToken: true });
    return res;
};
