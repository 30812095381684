import React from 'react';
import { Grid, Avatar, Typography, Paper, CircularProgress } from '@material-ui/core';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import { DashboardTemplets, SalesBarChart, SalesLineChart, SalesTable, SalePieChart } from './dashboardTemplates';
import ReactSpeedometer from 'react-d3-speedometer';
import { getsalesDashBoard, getUsedVehicle, getNewVehicle, getVehicleContingency, getOrderVehicle } from './../../../../../Core/Service/salesDasboard-service';
import { useEffect } from 'react';
import { useState } from 'react';
// import { CircularProgress } from '@material-ui/core/CircularProgress';

const SalesDashboard = () => {
    const [state, setState] = useState({
        usedVehicleFrenchise: [],
        usedVehicleBranch: [],
        usedVehicleModelCode: [],
        newVehicleStatus: [],
        newVehicleModel: [],
        newVehicleModelCode: [],
        contBranch: [],
        contDepartment: [],
        contFranchise: [],
        orderYear: [],
        orderMonth: [],
        orderCount: [],
        vehicleType_Sold: [],
        salePerson_Sold: [],
        UsedLoader: true,
        NewOrderLoader: true,
        NewLoader: true,
        ContiLoader: true
    });

    const pullOrderData = async () => {
        let res = await getOrderVehicle();
        if (res.success) {
            setState((st) => ({
                ...st,
                orderYear: res.data?.table.map((m) => ({
                    name: m.orderYear,
                    val: m.sold
                })),
                orderMonth: res.data?.table1.map((m) => ({
                    name: m.orderMonthName,
                    val: m.sold
                })),
                orderCount: res.data?.table3.map((m) => ({
                    name: m.modelDescription,
                    total: m.vehicleCount
                })),
                vehicleType_Sold: res.data?.table2.map((m) => ({
                    name: m.modelAnalysisCode,
                    value: m.vehicleCount
                })),
                salePerson_Sold: res.data?.table4.map((m) => ({
                    name: m.salesperson,
                    value: m.totalSold
                })),
                progress: 100,
                NewOrderLoader: false
            }));
        }
    };

    const pullUsedVehicleData = async () => {
        let usedVehicleRes = await getUsedVehicle();

        if (usedVehicleRes.success) {
            setState((st) => ({
                ...st,
                usedVehicleFrenchise: usedVehicleRes.data?.table.map((m) => ({
                    name: m.franchiseName,
                    total: m.numberOfVehicles,
                    value: m.totalValue
                })),
                usedVehicleBranch: usedVehicleRes.data?.table1.map((m) => ({
                    name: m.branchShortName,
                    total: m.numberOfVehicles,
                    value: m.totalValue
                })),
                usedVehicleModelCode: usedVehicleRes.data?.table2.map((m) => ({
                    name: m.modelCode,
                    total: m.numberOfVehicles,
                    totalvalue: m.totalValue
                })),
                totalUsedVehicleCount: usedVehicleRes.data?.table3[0].totalVehicleCount,
                progress: 25,
                UsedLoader: false
            }));
        }
    };

    const pullNewVehicleData = async () => {
        let newVehicleRes = await getNewVehicle();
        if (newVehicleRes.success) {
            setState((st) => ({
                ...st,
                newVehicleStatus: newVehicleRes.data?.table.map((m) => ({
                    name: m.statusDescription,
                    total: m.vehicleCount
                })),
                newVehicleModel: newVehicleRes.data?.table1.map((m) => ({
                    name: m.modelAnalysisCode,
                    value: m.vehicleCount
                })),
                newVehicleModelCode: newVehicleRes.data?.table2.map((m) => ({
                    name: m.modelDescription,
                    total: m.vehicleCount
                })),
                totalNewVehicleCount: newVehicleRes.data?.table3[0].totalVehicleCount,
                progress: 50,
                NewLoader: false
            }));
        }
    };

    const pullcontingencyData = async () => {
        let res = await getVehicleContingency();
        if (res.success) {
            setState((st) => ({
                ...st,
                contBranch: res.data?.table.map((m) => ({
                    name: m.branchShortName,
                    total: m.total
                })),
                contDepartment: res.data?.table1.map((m) => ({
                    name: m.departmentDescription,
                    total: m.total
                })),
                contFranchise: res.data?.table2.map((m) => ({
                    name: m.franchiseName,
                    total: m.total
                })),

                contTotalCount: `£${res.data?.table3[0].total}`,
                progress: 75,
                ContiLoader: false
            }));
        }
    };

    useEffect(async () => {
        pullUsedVehicleData();
        pullNewVehicleData();
        pullcontingencyData();
        pullOrderData();
    }, []);

    return (
        <div>
            <Grid container style={{ padding: 20 }} spacing={0}>
                <Grid item xs={12}>
                    <Grid container spacing={3} justifyContent="space-between">
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <DashboardTemplets val={state.totalNewVehicleCount || 0} title="New Vehicles (Stock)" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <DashboardTemplets val={0} title="Vehicles Ordered" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <DashboardTemplets val={state.totalUsedVehicleCount || 0} title="Used Vehicles" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <DashboardTemplets currency={true} val={state.contTotalCount || '£0.00'} title="Sales Contingency Debt" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{ border: '2px solid #d3d3d3 ', marginTop: 10 }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" spacing={4} style={{ padding: 20 }}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" style={{ marginBottom: 30 }}>
                                    New Vehicle Order
                                </Typography>
                            </Grid>
                            {state.NewOrderLoader ? (
                                <div style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            height: 'calc(100vh - 600px)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                New Vehicle Order Year
                                            </Typography>
                                            <SalesLineChart data={state.orderYear} color="#fd7f6f" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                New Vehicle Order Month
                                            </Typography>
                                            <SalesLineChart data={state.orderMonth} color="#0d88e6" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                New Vehicle Order Count
                                            </Typography>
                                            <SalesBarChart data={state.orderCount} color="#5ad45a" />
                                        </div>
                                        {/* <DashboardTemplets /> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <SalesTable t1={true} rows={state.vehicleType_Sold} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <SalesTable rows={state.salePerson_Sold} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <ReactSpeedometer
                                            maxValue={100}
                                            width={340}
                                            needleHeightRatio={0.7}
                                            value={0}
                                            needleColor="black"
                                            ringWidth={20}
                                            segments={4}
                                            height={200}
                                            segmentColors={['#ff0000', '#ff0000', '#FFBF00', '#00FF00']}
                                            currentValueText={`${0}% Overall Total`}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{ border: '2px solid #d3d3d3 ', marginTop: 10 }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" spacing={4} style={{ padding: 20 }}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" style={{ marginBottom: 30 }}>
                                    Used Vehicle
                                </Typography>
                            </Grid>
                            {state.UsedLoader ? (
                                <div style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            height: 'calc(100vh - 600px)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                Vehicle by Franchise
                                            </Typography>
                                            <SalesBarChart data={state.usedVehicleFrenchise} color="#ffb55a" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350, position: 'relative' }}>
                                            <Typography variant="caption" gutterBottom>
                                                {/* NEW VEHICLE ORDERS MONTH */}
                                            </Typography>
                                            <SalePieChart data={state.usedVehicleFrenchise} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                Vehicle by Branch
                                            </Typography>
                                            <SalesBarChart data={state.usedVehicleBranch} />
                                        </div>
                                        {/* <DashboardTemplets /> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350, position: 'relative' }}>
                                            <Typography variant="caption" gutterBottom>
                                                {/* NEW VEHICLE ORDERS MONTH */}
                                            </Typography>
                                            <SalePieChart data={state.usedVehicleBranch} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                Vehicle by model codes
                                            </Typography>
                                            <SalesBarChart data={state.usedVehicleModelCode} color="#0010d9" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                Total model by model codes
                                            </Typography>
                                            <SalesBarChart data={state.usedVehicleModelCode} totalVal={true} color="#fd7f6f" />
                                        </div>
                                        {/* <DashboardTemplets /> */}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{ border: '2px solid #d3d3d3 ', marginTop: 10 }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" spacing={4} style={{ padding: 20 }}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" style={{ marginBottom: 30 }}>
                                    New Vehicle
                                </Typography>
                            </Grid>
                            {state.NewLoader ? (
                                <div style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            height: 'calc(100vh - 600px)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                Vehicles count by status
                                            </Typography>
                                            <SalesBarChart data={state.newVehicleStatus} color="#ffb400" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350, position: 'relative' }}>
                                            <Typography variant="caption" gutterBottom>
                                                {/* NEW VEHICLE ORDERS MONTH */}
                                            </Typography>
                                            <SalePieChart data={state.newVehicleModel} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                Vehicle count by model
                                            </Typography>
                                            <SalesBarChart data={state.newVehicleModelCode} color="#54bebe" />
                                        </div>
                                        {/* <DashboardTemplets /> */}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{ border: '2px solid #d3d3d3 ', marginTop: 10 }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" spacing={4} style={{ padding: 20 }}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" style={{ marginBottom: 30 }}>
                                    Sales Contingency Debt
                                </Typography>
                            </Grid>
                            {state.ContiLoader ? (
                                <div style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            height: 'calc(100vh - 600px)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                Sales branch total
                                            </Typography>
                                            <SalesBarChart data={state.contBranch} color="#50e991" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                Sales Departments total
                                            </Typography>
                                            <SalesBarChart data={state.contDepartment} color="#e6d800" />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <div style={{ height: 350 }}>
                                            <Typography variant="caption" gutterBottom>
                                                Sales Franchise total
                                            </Typography>
                                            <SalesBarChart data={state.contFranchise} color="#ffa300" />
                                        </div>
                                        {/* <DashboardTemplets /> */}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SalesDashboard;
